import { StateService } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { combineLatest } from "rxjs";
import { AccountType, IAccount } from "@gtmhub/core";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { DomainService } from "@webapp/accounts/services/domain/domain.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";

@Component({
  selector: "account-marketing-info-screen",
  templateUrl: "./account-marketing-info-screen.component.html",
  styleUrls: ["./account-marketing-info-screen.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountMarketingInfoScreenComponent implements OnInit {
  public isAccountMarketingInfoFormVisible: boolean;

  constructor(
    private domainService: DomainService,
    private featureTogglesFacade: FeatureTogglesFacade,
    private accountResolverService: AccountResolverService,
    private profileService: UserProfileService,
    private state: StateService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.AccountMarketingInfo),
      this.accountResolverService.shouldShowAccountDetailsForm(),
    ]).subscribe(([featureEnabled, shouldShowForm]) => {
      const account = this.accountResolverService.getAccountData();
      const profile = this.profileService.getProfile();

      this.isAccountMarketingInfoFormVisible = (featureEnabled || profile.email.includes("@gtmhub.online")) && account.type !== AccountType.DemoAccount && shouldShowForm;
      this.cdr.markForCheck();

      if (!this.isAccountMarketingInfoFormVisible) {
        this.state.go("gtmhub.home", null, { location: "replace" });
      }
    });
  }

  public onAccountUpdated(account: IAccount): void {
    const domain = this.domainService.constructGtmhubDomain(account.domain);

    window.open(`${location.protocol}//${domain}/`, "_self");
  }
}
