<ng-container *ngIf="isRelaxedMode; else dropdownTemplate">
  <ui-tabset [(uiSelectedIndex)]="activeTabIndex">
    <ng-container *ngFor="let tab of tabs">
      <ui-tab *ngIf="!tab.isVisible$ || (tab.isVisible$ | async)" [uiTitle]="tabTitleTemplate" (uiClick)="actionHandler(tab)" />
      <ng-template #tabTitleTemplate>
        <i *ngIf="tab.iconType" [uiType]="tab.iconType" ui-icon uiSize="sm"></i>
        <span [attr.data-test-id]="tab.dataTestId ?? null">{{ tab.key | localize }}</span>
      </ng-template>
    </ng-container>
  </ui-tabset>
</ng-container>

<ng-template #dropdownTemplate>
  <gh-dropdown class="tabs-dropdown" [menuItems]="tabs" [hasCustomButtonContent]="true" (selectedItem)="dropdownSelectedChange($event)" placement="bottomLeft" iconName="menu-horizontal">
    <button ui-button uiTrigger="click" uiType="text" gh-dropdown-custom-button-content>
      <i *ngIf="activeTab?.iconType" [uiType]="activeTab?.iconType" ui-icon uiSize="sm"></i>
      <span>{{ activeTab?.key | localize }}</span>
      <i uiType="chevron-down-small" ui-icon uiSize="sm"></i>
    </button>
  </gh-dropdown>
</ng-template>
