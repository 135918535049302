import { FavoritesItemGrouped, IFavoritesItemsGroupedResponse } from "@gtmhub/favorites/models";
import { IGoal } from "@gtmhub/goals/models";
import { ITeam } from "@gtmhub/teams";
import { getRandomColor } from "@gtmhub/util/color";
import { UXCustomizationType } from "@gtmhub/uxcustomization/models";
import { IViewHistoryItemsGroupedResponse, ViewHistoryItemGrouped } from "@gtmhub/view-history";
import { IWhiteboard } from "@gtmhub/whiteboards";
import { ICollection } from "@webapp/core/core.models";
import { Employee } from "@webapp/employees";
import { Insightboard } from "@webapp/insightboards/models";
import { KpiGroup, KpiUiGroup } from "@webapp/kpis/models/kpi-group.models";
import { Kpi } from "@webapp/kpis/models/kpis.models";
import { List } from "@webapp/lists/models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { Session } from "@webapp/sessions/models/sessions.model";
import { ShareableFilter } from "@webapp/shared/unified-filters/models/shareable-filters.models";
import { Task } from "@webapp/tasks/models/tasks.models";
import { INavItem, NavItemsType } from "../models/nav-items-list.models";

export type SubNavItemsModel = Employee | ITeam | Insightboard | Session | IWhiteboard | Task | List | KpiUiGroup | Metric | ShareableFilter | Kpi;

interface IMergableCollection {
  collection: IEntityNavItem[];
  transformer(item: IEntityNavItem): INavItem;
  type: UXCustomizationType;
}

interface IMergeItem {
  navItem: INavItem;
  type: UXCustomizationType;
  sortProp: string;
}

interface IEntityNavItem {
  id?: string;
  metricId?: string;
  title?: string;
  name?: string;
  names?: string;
  color?: string;
  icon?: string;
  lastVisited?: string;
  picture?: string;
  avatar?: string;
  uxcItem?: ViewHistoryItemGrouped | FavoritesItemGrouped;
}

type NavItemDecorator = (navItem: INavItem, entity: ViewHistoryItemGrouped | FavoritesItemGrouped) => INavItem;
interface IResponseTranfosmersOptions {
  orderPropName: string;
  order: PropOrder;
  decorator: NavItemDecorator;
}

enum PropOrder {
  ORDER_ASC = "ASC",
  ORDER_DESC = "DESC",
}

const sortBySortProp = (items: IMergeItem[], order: PropOrder): void => {
  const lhsBigger = order === PropOrder.ORDER_ASC ? 1 : -1;
  const rhsBigger = order === PropOrder.ORDER_ASC ? -1 : 1;
  items.sort((lhs, rhs) => {
    return lhs.sortProp === rhs.sortProp ? 0 : lhs.sortProp > rhs.sortProp ? lhsBigger : rhsBigger;
  });
};

const mergeAndSortViewHistoryNavItems = (collections: IMergableCollection[], order?: PropOrder, sortPropName?: string): INavItem[] => {
  const mergedItems: IMergeItem[] = collections.reduce((previous: IMergeItem[], current: IMergableCollection) => {
    return [
      ...previous,
      ...current.collection.map((item: IEntityNavItem) => {
        const navItem = current.transformer(item);
        return {
          navItem,
          type: current.type,
          sortProp: navItem[sortPropName],
        };
      }),
    ];
  }, []);
  if (sortPropName && order) {
    sortBySortProp(mergedItems, order);
  }
  return mergedItems.map((item) => item.navItem);
};

const groupedReponseToNavItems = (
  collection: IViewHistoryItemsGroupedResponse | IFavoritesItemsGroupedResponse,
  type: NavItemsType,
  options: IResponseTranfosmersOptions
): ICollection<INavItem> => {
  const navItemsCollection: ICollection<INavItem> = {
    items: [],
    totalCount: collection.totalCount,
  };

  switch (type) {
    case "sessions":
      {
        const collectionsToMerge: IMergableCollection[] = [
          {
            collection: (collection.sessionItems || []).map((item) => {
              return { ...item.session, lastVisited: item.lastVisited, order: item.order, uxcItem: item };
            }),
            transformer: (item): INavItem => entityNavItemToNavItem(item, "session", item.uxcItem, options.decorator),
            type: "session",
          },
          {
            collection: (collection.filterItems || []).map((item) => {
              return { ...item.filter, lastVisited: item.lastVisited, order: item.order, uxcItem: item };
            }),
            transformer: (item): INavItem => entityNavItemToNavItem(item, "filter", item.uxcItem, options.decorator),
            type: "filter",
          },
        ];
        navItemsCollection.items = mergeAndSortViewHistoryNavItems(collectionsToMerge, options.order, options.orderPropName);
      }
      break;
    case "okrs":
      navItemsCollection.items = collection.goalItems
        ? collection.goalItems.map((goalItem) => entityNavItemToNavItem(goalItem.goal, "goal", goalItem, options.decorator))
        : [];
      break;
    case "metrics":
      navItemsCollection.items = collection.metricItems
        ? collection.metricItems.map((metricItem) => entityNavItemToNavItem(metricItem.metric, "metric", metricItem, options.decorator))
        : [];
      break;
    case "lists":
      navItemsCollection.items = collection.listItems
        ? collection.listItems.map((listItem) => entityNavItemToNavItem(listItem.list, "list", listItem, options.decorator))
        : [];
      break;
    case "insightboards":
      navItemsCollection.items = collection.dashboardItems
        ? collection.dashboardItems.map((insightItem) => entityNavItemToNavItem(insightItem.dashboard, "dashboard", insightItem, options.decorator))
        : [];
      break;
    case "whiteboards":
      navItemsCollection.items = collection.whiteboardItems
        ? collection.whiteboardItems.map((whiteboardItem) => entityNavItemToNavItem(whiteboardItem.whiteboard, "whiteboard", whiteboardItem, options.decorator))
        : [];
      break;
    case "tasks":
      navItemsCollection.items = collection.taskItems
        ? collection.taskItems.map((taskItem) => entityNavItemToNavItem(taskItem.task, "task", taskItem, options.decorator))
        : [];
      break;
    case "kpis":
      navItemsCollection.items = collection.kpiGroupsItems
        ? collection.kpiGroupsItems.map((kpiGroupItem) => entityNavItemToNavItem(kpiGroupItem.kpiGroup, "kpigroup", kpiGroupItem, options.decorator))
        : [];
      break;
    case "people":
      {
        const collectionsToMerge: IMergableCollection[] = [
          {
            collection: (collection.userItems || []).map((item) => {
              return { ...item.user, lastVisited: item.lastVisited, order: item.order, uxcItem: item };
            }),
            transformer: (item): INavItem => entityNavItemToNavItem(item, "user", item.uxcItem, options.decorator),
            type: "user",
          },
          {
            collection: (collection.teamItems || []).map((item) => {
              return { ...item.team, lastVisited: item.lastVisited, order: item.order, uxcItem: item };
            }),
            transformer: (item): INavItem => entityNavItemToNavItem(item, "team", item.uxcItem, options.decorator),
            type: "team",
          },
        ];
        navItemsCollection.items = mergeAndSortViewHistoryNavItems(collectionsToMerge, options.order, options.orderPropName);
      }
      break;
  }
  return navItemsCollection;
};

export const viewHistoryGroupedReponseToNavItems = (collection: IViewHistoryItemsGroupedResponse, type: NavItemsType): ICollection<INavItem> => {
  const options = {
    orderPropName: "lastVisited",
    order: PropOrder.ORDER_DESC,
    decorator: decorateNavItemWithViewHistory,
  };
  return groupedReponseToNavItems(collection, type, options);
};

export const favoritesGroupedReponseToNavItems = (collection: IFavoritesItemsGroupedResponse, type: NavItemsType): ICollection<INavItem> => {
  const options = {
    orderPropName: "order",
    order: PropOrder.ORDER_ASC,
    decorator: decorateNavItemWithFavorite,
  };
  return groupedReponseToNavItems(collection, type, options);
};

const entityNavItemToNavItem = (
  navItemEntity: IEntityNavItem,
  type: UXCustomizationType,
  uxcEntity?: ViewHistoryItemGrouped | FavoritesItemGrouped,
  decorator?: NavItemDecorator
): INavItem => {
  const baseNavItem: INavItem = {
    id: navItemEntity.id || navItemEntity.metricId,
    uxcType: type,
    color: navItemEntity.color || getRandomColor(),
    title: navItemEntity.title || navItemEntity.name || navItemEntity.names,
    icon: navItemEntity.icon || navItemEntity.picture || navItemEntity.avatar,
  };
  return decorator ? decorator(baseNavItem, uxcEntity) : baseNavItem;
};

const decorateNavItemWithViewHistory = (navItem: INavItem, viewHistoryItem: ViewHistoryItemGrouped): INavItem => {
  return {
    ...navItem,
    lastVisited: viewHistoryItem.lastVisited,
  };
};

const decorateNavItemWithFavorite = (navItem: INavItem, favoriteItem: FavoritesItemGrouped): INavItem => {
  return {
    ...navItem,
    order: favoriteItem.order,
    favoriteId: favoriteItem.id,
  };
};

const basicBuild = (id: string, title: string, type: UXCustomizationType): INavItem => {
  return {
    id: id,
    title: title,
    uxcType: type,
  };
};

export const navItemsFactory = {
  buildWhiteboardNavItem: (whiteboard: { id: string; name: string }): INavItem => {
    return basicBuild(whiteboard.id, whiteboard.name, "whiteboard");
  },
  buildTaskNavItem: (task: Task): INavItem => {
    return basicBuild(task.id, task.name, "task");
  },
  buildInsightboardNavItem: (insightboard: Pick<Insightboard, "id" | "name">): INavItem => {
    return basicBuild(insightboard.id, insightboard.name, "dashboard");
  },
  buildKpiGroupNavItem: (kpiGroup: KpiGroup): INavItem => {
    return basicBuild(kpiGroup.id, kpiGroup.name, "kpigroup");
  },
  buildTeamNavItem: (team: ITeam): INavItem => {
    return {
      ...basicBuild(team.id, team.name, "team"),
      icon: team.picture || team.avatar,
      color: team.color,
    };
  },
  buildEmployeeNavItem: (employee: Employee): INavItem => {
    return {
      ...basicBuild(employee.id, `${employee.firstName} ${employee.lastName}`, "user"),
      icon: employee.picture,
    };
  },
  buildGoalNavItem: (goal: IGoal): INavItem => {
    return {
      ...basicBuild(goal.id, `${goal.name}`, "goal"),
    };
  },
  buildMetricNavItem: (metric: Metric): INavItem => {
    return {
      ...basicBuild(metric.id, metric.name, "metric"),
    };
  },
  buildListNavItem: (list: Partial<List>): INavItem => {
    return {
      ...basicBuild(list.id, list.title, "list"),
      color: list.color,
    };
  },
  buildSessionNavItem: (session: Session): INavItem => {
    return {
      ...basicBuild(session.id, session.title, "session"),
      color: session.color,
    };
  },
  buildFilterNavItem: (filter: ShareableFilter): INavItem => {
    return {
      ...basicBuild(filter.id, filter.name, "filter"),
    };
  },
  withRandomColor: (navItem: INavItem): INavItem => {
    return {
      ...navItem,
      color: getRandomColor(),
    };
  },
};

export const buildNavItemFromModel = (uxcType: UXCustomizationType, item: SubNavItemsModel): INavItem | null => {
  let result = null;
  switch (uxcType) {
    case "user":
      result = navItemsFactory.withRandomColor(navItemsFactory.buildEmployeeNavItem(item));
      break;
    case "team":
      result = navItemsFactory.buildTeamNavItem(item as ITeam);
      break;
    case "goal":
      result = navItemsFactory.buildGoalNavItem(item as IGoal);
      break;
    case "metric":
      result = navItemsFactory.buildMetricNavItem(item as Metric);
      break;
    case "dashboard":
      result = navItemsFactory.withRandomColor(navItemsFactory.buildInsightboardNavItem(item as Insightboard));
      break;
    case "session":
      result = navItemsFactory.buildSessionNavItem(item as Session);
      break;
    case "whiteboard":
      result = navItemsFactory.withRandomColor(navItemsFactory.buildWhiteboardNavItem(item as IWhiteboard));
      break;
    case "task":
      result = navItemsFactory.withRandomColor(navItemsFactory.buildTaskNavItem(item as Task));
      break;
    case "list":
      result = navItemsFactory.buildListNavItem(item as List);
      break;
    case "kpigroup":
      result = navItemsFactory.withRandomColor(navItemsFactory.buildKpiGroupNavItem(item as KpiUiGroup));
      break;
    case "filter":
      result = navItemsFactory.buildFilterNavItem(item as ShareableFilter);
      break;
  }

  if (result !== null) {
    // we do not want undefined values to be present in the resulting objects
    Object.keys(result).forEach((key) => {
      if (result[key] === undefined) {
        delete result[key];
      }
    });
  }

  return result;
};

export const getNavItemsType = (type: UXCustomizationType): NavItemsType | undefined => {
  let result = undefined;
  switch (type) {
    case "session":
    case "filter":
      result = "sessions";
      break;
    case "goal":
      result = "okrs";
      break;
    case "metric":
      result = "metrics";
      break;
    case "kpi":
    case "kpigroup":
      result = "kpis";
      break;
    case "task":
      result = "tasks";
      break;
    case "whiteboard":
      result = "whiteboards";
      break;
    case "list":
      result = "lists";
      break;
    case "dashboard":
      result = "insightboards";
      break;
    case "user":
    case "team":
      result = "people";
      break;
  }

  return result;
};

type NavItemsUXCTypesMapBuilderOptions = {
  shouldDisplayOkrViews: boolean;
};

export type NavItemsUXCTypesMap = { [key in NavItemsType]: UXCustomizationType[] };

export const navItemsUXCTypesMapBuilder = (options?: NavItemsUXCTypesMapBuilderOptions): NavItemsUXCTypesMap => {
  const navItemsUXCTypesMap: NavItemsUXCTypesMap = {
    okrs: ["goal"],
    metrics: ["metric"],
    sessions: options?.shouldDisplayOkrViews ? ["session", "filter"] : ["session"],
    whiteboards: ["whiteboard"],
    kpis: ["kpigroup"],
    tasks: ["task"],
    insightboards: ["dashboard"],
    lists: ["list"],
    people: ["user", "team"],
  };

  return navItemsUXCTypesMap;
};
