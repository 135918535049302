import { Injectable } from "@angular/core";
import { Observable, filter, take } from "rxjs";
import { FilterChangeModel, FiltersChangeAction, FiltersEventType } from "@gtmhub/hub/events";
import { IHubFilter } from "@gtmhub/hub/models/hub";
import { HubService } from "@gtmhub/hub/services/hub";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { CustomViewsCache } from "./custom-views.cache";

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface CustomViews extends FilterChangeModel {}

@Injectable()
export class CustomViewsRepository {
  constructor(
    private broadcastService: BroadcastService,
    private hubService: HubService,
    private cache: CustomViewsCache
  ) {
    this.broadcastService.on(FiltersEventType.FILTER_CHANGED).subscribe((changeDefinition: { filter: FilterChangeModel; action: FiltersChangeAction }) => {
      switch (changeDefinition.action) {
        case "delete":
          this.cache
            .get$()
            .pipe(take(1))
            .subscribe((views) => {
              this.cache.set(views.filter((filter) => filter.id !== changeDefinition.filter.id));
            });

          break;
        case "create":
          this.cache
            .get$()
            .pipe(take(1))
            .subscribe((views) => {
              this.cache.set([changeDefinition.filter, ...views]);
            });

          break;
        case "update":
          this.cache
            .get$()
            .pipe(take(1))
            .subscribe((views) => {
              this.cache.set(
                views.map((view) => {
                  return view.id === changeDefinition.filter.id ? changeDefinition.filter : view;
                })
              );
            });

          break;
      }
    });

    this.hubService.getFilters("hub").then((filters: IHubFilter[]) => {
      this.cache.set(
        filters.map((filter) => {
          return {
            id: filter.id,
            name: filter.name,
          };
        })
      );
    });
  }

  public get$(): Observable<CustomViews[]> {
    return this.cache.get$().pipe(filter((views) => !!views));
  }
}
