import { ChangeDetectionStrategy, Component, Inject, OnInit, ViewChild } from "@angular/core";
import { firstValueFrom, take } from "rxjs";
import { AssigneeActions, IAssigneesStoreState } from "@gtmhub/assignees";
import { IGoalMethodologySettingsUI } from "@gtmhub/goals/components/facades/okr-methodology.facade";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { IReduxTeam, ITeamsStoreState, TeamsActions } from "@gtmhub/teams";
import { IdMap } from "@gtmhub/util";
import { ISelectorAssignee, TeamAssignee, UserAssignee } from "@webapp/assignees/models/assignee.models";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { Session } from "@webapp/sessions/models/sessions.model";
import { PeopleSelectorRequestInternal } from "@webapp/shared/components/people-selector/models/models";
import { FormGroupComponent } from "@webapp/shared/form/form-group.component";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

export type OkrWorkflowEditReviewersData = {
  goalId: string;
  isPrivate: boolean;
  ownerIds: string[];
  session: Session;
  methodologySettings: IGoalMethodologySettingsUI;
  selectedUsers: string[];
};

type EditReviewersForm = {
  selectReviewers: string[];
};

@Component({
  selector: "okr-workflow-edit-reviewers",
  templateUrl: "./okr-workflow-edit-reviewers.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OkrWorkflowEditReviewersModalComponent implements OnInit {
  public session: Session;
  public ownerIds: string[];
  public goalId: string;
  public isPrivate: boolean;
  public peopleSelectorRequest: PeopleSelectorRequestInternal;

  @ViewChild(FormGroupComponent, { static: false })
  public editReviewersFormGroup: { value: EditReviewersForm };

  private methodologySettings: IGoalMethodologySettingsUI;
  private ownerEnum = { user: "user", team: "team" };
  private teamsIdMap: IdMap<IReduxTeam>;
  public usersSelection = {
    invalid: [],
  };
  private allUsersHaveUpdatePermission = false;
  private assigneesIdMap: Record<string, UserAssignee | TeamAssignee>;

  constructor(
    @Inject(UI_MODAL_DATA) public modalData: OkrWorkflowEditReviewersData,
    public goalFacade: GoalsFacade,
    public permissionsFacade: PermissionsFacade,
    public assigneeActions: AssigneeActions,
    public teamsActions: TeamsActions
  ) {
    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    const { reduxStore } = reduxStoreContainer;
    const reduxObserver = new ReduxStoreObserver(reduxStore);
    reduxStore.dispatch(this.assigneeActions.getAssignees());
    reduxStore.dispatch(this.teamsActions.fetchTeamsIfMissing());

    reduxObserver.whenFetched$<ITeamsStoreState & IAssigneesStoreState>("teams", "assignees").subscribe((state) => {
      this.teamsIdMap = state.teams.map;
      this.assigneesIdMap = state.assignees.map;

      this.permissionsFacade
        .getUsersWithAllowedActionPerItem$({ targetType: "goal", targetId: this.goalId, action: "update" })
        .pipe(take(1))
        .subscribe((users) => {
          this.allUsersHaveUpdatePermission = users.allUsersHaveTheAction;
          this.initialize(users.userIds);
        });
    });
  }

  private initialize(userIds: string[]): void {
    if (!this.allUsersHaveUpdatePermission) {
      const invalidUsers = Object.keys(this.assigneesIdMap).filter((userId) => !userIds.includes(userId));
      this.usersSelection.invalid.push(...invalidUsers);
    }

    this.processSessionApprovalList();
    this.processSelfApproveOfOkrs();
    this.buildPeopleSelectorRequest();
  }

  private processSessionApprovalList = (): void => {
    if (!this.session.workflow?.approvalSettings) {
      return;
    }

    if (this.session.workflow.approvalSettings.everyoneCanApprove) {
      return;
    }

    const invalidUsers = Object.keys(this.assigneesIdMap).filter((userId) => this.isUserInvalid(userId));

    this.usersSelection.invalid.push(...invalidUsers);
  };

  private isUserInvalid = (userId: string): boolean => {
    if (this.usersSelection.invalid.includes(userId)) {
      return false;
    }

    const teamsAllowedToApprove = this.session.workflow.approvalSettings?.teamsAllowedToApprove;
    let userIsPartOfTeam = false;
    if (teamsAllowedToApprove && teamsAllowedToApprove.length > 0) {
      teamsAllowedToApprove.forEach((teamId) => {
        const team = this.teamsIdMap[teamId];
        if (team.manager === userId || team.members.includes(userId)) {
          userIsPartOfTeam = true;
        }
      });
    }

    return !this.session.workflow.approvalSettings?.usersAllowedToApprove.includes(userId) && !userIsPartOfTeam;
  };

  public buildPeopleSelectorRequest = (): void => {
    this.peopleSelectorRequest = {
      ...new PeopleSelectorRequestInternal(),
      ...(this.methodologySettings.goalsPerOwner.sessionId ? { goalsLimitSetting: this.methodologySettings.goalsPerOwner } : {}),
      shouldDisplayAssignee: (assignee: ISelectorAssignee): boolean => {
        return !this.usersSelection.invalid.includes(assignee.id);
      },
      type: "user",
      hideViewOnlyUsers: true,
      limit: 10,
      permissionSetting: {
        enabled: true,
        permissions: this.isPrivate ? ["ManageGoals", "AccessPrivateGoals"] : ["ManageGoals"],
      },
    };
  };

  public sendForApproval = async (): Promise<void> => {
    try {
      await firstValueFrom(this.goalFacade.sendForApproval$(this.goalId, this.editReviewersFormGroup.value.selectReviewers as []));
    } catch (error) {
      if (typeof error.error === "string") {
        throw { title: error.error };
      } else {
        throw error;
      }
    }
  };

  private processSelfApproveOfOkrs = (): void => {
    if (this.isSelfApproveDisabled()) {
      this.ownerIds.forEach((ownerId) => {
        const ownerType = this.getOwnerType(ownerId);

        if (ownerType === this.ownerEnum.team) {
          const team = this.teamsIdMap[ownerId];
          team.members?.forEach((teamMember) => {
            if (!this.isUserManagerOfTeam(teamMember, team.manager) && !this.usersSelection.invalid.includes(teamMember)) {
              this.usersSelection.invalid.push(teamMember);
            }
          });
        } else if (!this.usersSelection.invalid.includes(ownerId)) {
          this.usersSelection.invalid.push(ownerId);
        }
      });
    }
  };

  private isSelfApproveDisabled = (): boolean => {
    return !this.session.isSelfApproveAllowed;
  };

  private isUserManagerOfTeam = (userId: string, managerId: string): boolean => {
    return userId === managerId;
  };

  private getOwnerType = (ownerId: string): string => {
    return this.teamsIdMap[ownerId] ? this.ownerEnum.team : this.ownerEnum.user;
  };
}
