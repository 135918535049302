import { Directive, Input, OnChanges } from "@angular/core";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiAvatarComponent } from "@webapp/ui/avatar/avatar.component";
import { Badge } from "../models/badges.models";

@Directive({
  selector: "ui-avatar[fromBadge]",
})
export class FromBadgeDirective implements OnChanges {
  @Input()
  public fromBadge: Badge;

  constructor(private avatar: UiAvatarComponent) {}

  public ngOnChanges(changes: SimpleChangesOf<FromBadgeDirective>): void {
    if (changes.fromBadge) {
      this.avatar.icon = this.fromBadge?.icon;
      this.avatar.pattern = this.fromBadge?.pattern;
      this.avatar.bgColor = this.fromBadge?.background;
      this.avatar.alt = this.fromBadge?.name;
      this.avatar.alt = this.fromBadge?.name;
    }
  }
}
