import { FocusTrapFactory } from "@angular/cdk/a11y";
import { OverlayRef } from "@angular/cdk/overlay";
import { CdkPortalOutlet, PortalModule } from "@angular/cdk/portal";
import { DOCUMENT, NgClass, NgIf, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Inject, NgZone, Optional, Output, Renderer2, ViewChild } from "@angular/core";
import { ANIMATION_MODULE_TYPE } from "@angular/platform-browser/animations";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiI18nModule, UiI18nService } from "@quantive/ui-kit/i18n";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzConfigService } from "ng-zorro-antd/core/config";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzModalI18nInterface } from "ng-zorro-antd/i18n";
import { ModalOptions, NzModalConfirmContainerComponent } from "ng-zorro-antd/modal";
import { NzToCssUnitPipe } from "ng-zorro-antd/pipes";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { CustomModalOptions, UI_MODAL_INDEX } from "../../modal.models";
import { UiModalCloseComponent } from "../modal-close/modal-close.component";

@Component({
  selector: "ui-modal-confirm-container",
  exportAs: "uiModalConfirmContainer",
  templateUrl: "modal-confirm-container.component.html",
  styleUrls: ["./modal-confirm-container.component.less"],
  // Using OnPush for modal caused footer can not to detect changes. we can fix it when 8.x.
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection
  changeDetection: ChangeDetectionStrategy.Default,
  host: {
    tabindex: "-1",
    role: "dialog",
    "[attr.aria-labelledby]": `config.nzTitle && modalTitleId`,
    "[attr.aria-describedby]": `modalDescriptionId`,
    "[class]": 'config.nzWrapClassName ? "ant-modal-wrap " + config.nzWrapClassName : "ant-modal-wrap"',
    "[class.ant-modal-wrap-rtl]": `dir === 'rtl'`,
    "[class.ant-modal-centered]": "config.nzCentered",
    "[style.zIndex]": "config.nzZIndex",
    "(click)": "onContainerClick($event)",
  },
  standalone: true,
  imports: [
    NgIf,
    NgClass,
    NgStyle,
    NzToCssUnitPipe,
    UiIconModule,
    UiLoadingIndicatorModule,
    UiAlertModule,
    UiButtonModule,
    UiI18nModule,
    NzOutletModule,
    UiModalCloseComponent,
    PortalModule,
  ],
})
export class UiModalConfirmContainerComponent extends NzModalConfirmContainerComponent {
  @ViewChild(CdkPortalOutlet, { static: true }) public portalOutlet!: CdkPortalOutlet;
  @ViewChild("modalElement", { static: true }) public modalElementRef!: ElementRef<HTMLDivElement>;

  @Output() public readonly cancelTriggered = new EventEmitter<void>();
  @Output() public readonly okTriggered = new EventEmitter<void>();

  public locale!: NzModalI18nInterface;

  constructor(
    ngZone: NgZone,
    i18n: UiI18nService,
    elementRef: ElementRef,
    focusTrapFactory: FocusTrapFactory,
    cdr: ChangeDetectorRef,
    render: Renderer2,
    overlayRef: OverlayRef,
    nzConfigService: NzConfigService,
    config: ModalOptions,
    @Inject(UI_MODAL_INDEX) private modalIndex: number,
    @Optional() @Inject(DOCUMENT) document?: NzSafeAny,
    @Optional() @Inject(ANIMATION_MODULE_TYPE) animationType?: string
  ) {
    super(ngZone, i18n, elementRef, focusTrapFactory, cdr, render, overlayRef, nzConfigService, config, document, animationType);
  }

  get customConfig(): CustomModalOptions {
    return this.config as CustomModalOptions;
  }

  get modalTitleId(): string {
    return `ui-modal-${this.modalIndex}-title`;
  }

  get modalDescriptionId(): string {
    return `ui-modal-${this.modalIndex}-desc`;
  }
}
