import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { MetricServiceProvider } from "@webapp/okrs/metrics/providers/metrics.provider";
import { ItemNameWithIconAndOwnerModule } from "@webapp/shared/components/item-name-with-icon-and-owner/item-name-with-icon-and-owner.module";
import { UiAvatarModule } from "@webapp/ui/avatar/avatar.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { EditInsightModalComponent } from "./modal/edit-insight-modal.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    LocalizationModule,
    UiSelectModule,
    UiFormModule,
    UiRadioModule,
    UiInputModule,
    UiIconModule,
    UiAvatarModule,
    ItemNameWithIconAndOwnerModule,
  ],
  declarations: [EditInsightModalComponent],
  providers: [MetricServiceProvider],
})
export class EditInsightModule {}
