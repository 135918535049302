import { IIndicator } from "@gtmhub/error-handling";
import { DynamicLocalizationKey } from "@gtmhub/shared/models";
import { ITeam } from "@gtmhub/teams";

// the permissions group names and permission names come from api/v2/permissions/actions
// any changes to these constants would probably require changes to this endpoint as well
// check with Product when adding new groups or actions - currrently they are displayed in an order specified by the product team
export const PERMISSIONS_GROUP_NAMES = ["General", "People", "Data", "Account"] as const;

export type PermissionsFeatureFilter = { enabledFlag: boolean; permissionName: string };

export type PermissionsGroups = typeof PERMISSIONS_GROUP_NAMES;
export type PermissionGroup = PermissionsGroups[number];

export type IKeyToNamePermissionsMap = Map<string, string>;
export type ISelectedPermissions = Map<string, boolean>;

type PermissionsNames = (
  | "AccessGoals"
  | "ManageGoals"
  | "AccessPrivateGoals"
  | "ManageSessions"
  | "CreateTags"
  | "ManageTasks"
  | "ManageWhiteboards"
  | "AccessPeople"
  | "ManagePeople"
  | "ManageReflections"
  | "AwardBadges"
  | "ManageBadges"
  | "AccessInsightboards"
  | "AccessReportsAndDataSourceFilters"
  | "ManageData"
  | "AccessKPIs"
  | "ManageKPIs"
  | "ManageConfiguration"
  | "ManageAccountNotifications"
  | "ManageUsers"
  | "ManageApplications"
  | "ManageApiTokens"
  | "ManageBilling"
  | "team:create"
  | "team:edit"
  | "team:delete"
  | "team:manage_activation"
  | "team:manage_members"
  | "ManageTeams"
)[];
type PermissionName = PermissionsNames[number];

export interface IPermissionsByGroup {
  groupName: PermissionGroup;
  localizedGroupName?: string;
  permissionActionsGroup: Array<IPermissionAction | ILocalizedPermissionAction>;
}

export interface IRole {
  id?: string;
  name: string;
  description?: string;
  accountId?: string;
  ui?: {
    deleting?: IIndicator;
  };
  team?: ITeam;
  teamId?: string;
  userCount?: number;
  // used in ui
  type?: string;
  used?: boolean;
}

export interface IDynamicSubteamsRole extends IRole {
  teamId: string;
}

// represents an object that describes an action that is allowed or denied
export interface IPermissionAction {
  name: PermissionName;
  title: string;
  description: string;
  titleKey?: DynamicLocalizationKey | string;
  descriptionKey?: DynamicLocalizationKey | string;
  group?: PermissionGroup;
  groupKey?: DynamicLocalizationKey | string;
  requiredPermissions?: DynamicLocalizationKey[] | string[] | null;
  allowed?: boolean;
  rootPermissionName?: IPermissionAction["name"];
  parentPermission?: IPermissionAction | ILocalizedPermissionAction;
  subPermissions?: (IPermissionAction | ILocalizedPermissionAction)[];
}

export interface ILocalizedPermissionAction extends IPermissionAction {
  localizedGroup: string;
  localizedTitle?: string;
  localizedDescription?: string;
  localizedPermissionWarning?: string;
}

export interface IAccountRolesResponse {
  items: IRole[];
}
