import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnInit } from "@angular/core";
import { UntilDestroy } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable } from "rxjs";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { Search, SearchCollection, SearchMode } from "@webapp/search/models/search.models";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { getIcon, getItemType } from "@webapp/search/utils/global-search.utils";
import { BoldPipe } from "@webapp/shared/pipes/bold/bold.pipe";
import { RichTextEditorModule } from "@webapp/shared/rich-text-editor/rich-text-editor.module";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";

@UntilDestroy()
@Component({
  selector: "search-item",
  templateUrl: "./search-item.component.html",
  styleUrls: ["./search-item.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  host: {
    "[style.padding.px]": `searchMode === 'search-page' ? 10 : 5`,
  },
  imports: [UiAssigneeModule, UiIconModule, UiTooltipModule, AssigneesModule, CommonModule, RichTextEditorModule, BoldPipe],
})
export class SearchItemComponent implements OnInit {
  @Input()
  public item: Search<SearchCollection>;

  @Input()
  public searchMode: SearchMode;

  /*
   * Internal props
   */
  public icon: string;
  public showAvatar: boolean;
  protected searchTerm$: Observable<string>;
  protected readonly getItemType = getItemType;

  constructor(private globalSearchMediator: GlobalSearchMediatorService) {
    this.searchTerm$ = this.globalSearchMediator.searchTerm$;
  }

  public ngOnInit(): void {
    this.showAvatar = this.item.collectionName === "teams" || this.item.collectionName === "users";
    this.icon = getIcon(this.item.collectionName);
  }

  public getAssigneeIds(collectionName: string): string[] {
    switch (collectionName) {
      case "goals":
      case "kpis":
      case "metrics": {
        return this.item.fields["ownerIds"];
      }
      case "filters":
      case "lists":
      case "automations":
      case "tasks": {
        return [this.item.fields["ownerId"]];
      }
      case "users": {
        // Response for recent items is different that's why we need to check if we have "teams"(search) or "teamIds"(recent)
        // eslint-disable-next-line @typescript-eslint/naming-convention
        return this.item.fields["teams"]?.map((t: { _id: string }) => t._id) || this.item.fields["teamIds"];
      }
      default: {
        return [];
      }
    }
  }
}
