import { IRootScopeService } from "angular";
import { AccountType, IAccount } from "@gtmhub/core";
import { storage } from "@gtmhub/core/storage";
import { IAppConfig } from "@gtmhub/env";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { getCurrentUserId } from "@gtmhub/users";
import { clickMagickCDN, clickMagickConfig } from "./util";

const mod = angular.module("clickMagick", []);

mod.run([
  "$rootScope",
  "appConfig",
  "AccountResolverService",
  ($rootScope: IRootScopeService, appConfig: IAppConfig, accountResolverService: AccountResolverService) => {
    $rootScope.$on("authorizationProcessed", () => {
      const accountData = accountResolverService.getAccountData() || ({} as IAccount);
      const userId = getCurrentUserId();
      const accountOwnerId = accountData.ownerId;
      const loginsCount = Number(storage.get("loginsCount")) || 0;

      const isUserOwnAccount = userId === accountOwnerId;
      const isFirstTimeLogin = loginsCount === 1;
      const isDemoAccount = accountData.type === AccountType.DemoAccount;

      if (appConfig.clickMagick && isUserOwnAccount && isFirstTimeLogin && !isDemoAccount) {
        window["clickmagick_cmc"] = clickMagickConfig;

        const clickmagickSrc = document.createElement("script");
        clickmagickSrc.setAttribute("src", clickMagickCDN);
        document.head.appendChild(clickmagickSrc);
      }
    });
  },
]);

export default mod.name;
