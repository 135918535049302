import { StateService } from "@uirouter/angular";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { Subject, asyncScheduler, throttleTime } from "rxjs";
import { TOP_NAV_BAR_PROPS } from "@webapp/navigation/components/navigation/config";
import { IsNavigationExpandedCache } from "@webapp/navigation/services/is-navigation-expanded.cache";
import { SimplePermissionsConfig } from "@webapp/permissions/models/permissions.model";
import { SearchFacetsOptions } from "@webapp/search/models/search.models";
import { Tab } from "@webapp/shared/models/tabset-builder.models";
import { TopNavBarButtonsConfig } from "./models/top-nav-bar-buttons.models";
import { SubTitle, TopNavBarAvatarsBreakpoints, TopNavBarAvatarsConfig, TopNavBarVisibleAvatarsCount } from "./models/top-nav-bar.models";
import { actionHandlerGenerator } from "./utils/action-handler";
import { setTopNavBarProps } from "./utils/top-nav-bar.utils";

export const TITLE_CONTAINER_MAX_WIDTH = 640;

@UntilDestroy()
@Component({
  selector: "top-nav-bar",
  templateUrl: "./top-nav-bar.component.html",
  styleUrls: ["./top-nav-bar.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    role: "toolbar",
  },
})
export class TopNavBarComponent implements OnChanges, OnInit, AfterViewInit {
  @Input()
  public navTitle: string;

  @Input()
  public navTitleEditable = false;

  @Input()
  public navSubTitle: SubTitle;

  @Input()
  public hideGlobalSearch = false;

  @Input()
  @HostBinding("class.gray-background")
  public isGrayBackground = false;

  @Input()
  public searchFacet: SearchFacetsOptions;

  @Input()
  public topNavBarButtonsConfig: TopNavBarButtonsConfig;

  @Input()
  public topNavBarTabsConfig: Tab[];

  @Input({ required: false })
  public topNavBarAvatarsConfig: TopNavBarAvatarsConfig;

  @Input({ required: false })
  public simplePermissionsConfig: SimplePermissionsConfig;

  @Input()
  public initialActiveTabKey: string;

  @Output()
  public readonly navTitleChange = new EventEmitter<string>();

  @ViewChild("shadowNavTitle")
  public shadowNavTitle: ElementRef<HTMLDivElement>;

  public isNavExpanded = false;

  public titleAriaLabel: string;

  public actionHandler: () => void;

  /**
   * Elements mode - relaxed is the default, compact is when the title or the overall content of the top-nav-bar would overflow.
   */
  public relaxedModes = {
    buttons: true,
    tabs: true,
    permissions: true,
  };
  public visibleAvatarsCount: TopNavBarVisibleAvatarsCount = TopNavBarVisibleAvatarsCount.LARGE_SCREEN;
  public shouldHideButtonsAndAvatars: boolean = false;

  private isSearchOpen: boolean = false;
  private resize$ = new Subject<void>();

  constructor(
    private isNavExpandedCache: IsNavigationExpandedCache,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
    private elRef: ElementRef<HTMLElement>
  ) {}

  public ngOnChanges(changes: SimpleChangesOf<TopNavBarComponent>): void {
    if (this.navTitleEditable && changes.navTitle && !changes.navTitle.isFirstChange()) {
      this.resizeEditableTitleContainerDelayed();
    }
  }

  public ngOnInit(): void {
    setTopNavBarProps({ height: TOP_NAV_BAR_PROPS.height + "px", borderColor: TOP_NAV_BAR_PROPS.borderColor });

    this.isNavExpandedCache
      .get$()
      .pipe(untilDestroyed(this))
      .subscribe((isNavExpanded) => {
        this.isNavExpanded = isNavExpanded;
        this.cdr.markForCheck();
      });

    if (this.navSubTitle?.action) {
      this.actionHandler = actionHandlerGenerator(this.navSubTitle.action, { stateService: this.stateService, window });
    }

    if (this.navSubTitle && typeof this.navTitle === "string") {
      this.titleAriaLabel = "current page, visited link, " + this.navTitle;
    }
  }

  public ngAfterViewInit(): void {
    this.observeContentResize();
    if (this.navTitleEditable) {
      this.resizeEditableTitleContainerDelayed();
    }
  }

  public onGlobalSearchToggle(isSearchOpened: boolean): void {
    this.isSearchOpen = isSearchOpened;
    this.cdr.detectChanges(); // need to detectChanges so the display is updated, otherwise the check for element overflowing will always give the same value
    this.setViewModeRules();
  }

  public onResize(): void {
    this.setRelaxedModes();
    this.setViewModeRules();
    this.setAvatarsMode();
  }

  public resizeEditableTitleContainer(): void {
    // set the value of the input to the shadow element
    this.shadowNavTitle.nativeElement.innerHTML = this.titleInput.value;

    // render the shadow element
    this.shadowNavTitle.nativeElement.style.display = "inline-block";

    // apply the width of the shadow element to the title container
    const parentContainerOffset = 7; // padding and border width of the input parent + 1px extra (the input always overflows without that extra 1px)
    this.titleContainer.style.width = this.shadowNavTitle.nativeElement.offsetWidth + parentContainerOffset + "px";

    // hide the shadow element again
    this.shadowNavTitle.nativeElement.style.display = "none";
  }

  public resizeEditableTitleContainerDelayed(): void {
    // the inner input ngModel works asyncronously when setting its value programmatically,
    // additionally, the ui-input-text-field value handling also uses a timeout delay during blur,
    // so delay the rezising here as well when handling such scenarios
    window.setTimeoutOutsideAngular(() => {
      this.resizeEditableTitleContainer();
    }, 50);
  }

  private setRelaxedModes(): void {
    // reset the items to their default state initially
    this.relaxedModes = {
      buttons: true,
      tabs: true,
      permissions: true,
    };
    this.cdr.detectChanges();

    // the order is important to determine which item should be collapsed first
    if ((this.topNavBarTabsConfig || []).length > 0) {
      this.setItemRelaxedMode("tabs");
    }

    if (this.simplePermissionsConfig) {
      this.setItemRelaxedMode("permissions");
    }

    if (this.topNavBarButtonsConfig) {
      this.setItemRelaxedMode("buttons");
    }
  }

  private setItemRelaxedMode(item: "buttons" | "tabs" | "permissions"): void {
    const relaxItem = !this.isTopNavBarOverflowing && !this.isTitleOverflowing;

    if (this.relaxedModes[item] !== relaxItem) {
      this.relaxedModes[item] = relaxItem;
      this.cdr.detectChanges();

      // when the items get hidden during resize, on the next resize cycle the top-nav-bar
      // will no longer be overflowing (the tabs/permissions/buttons are collapsed to compact items), and we might wrongly render their relaxed version
      // so ensure that re-rendering the items the content doesn't wrongly overflow
      if (this.isTopNavBarOverflowing || this.isTitleOverflowing) {
        this.setItemRelaxedMode(item);
      }
    }
  }

  private setViewModeRules(): void {
    const shouldHideButtonsAndAvatars = this.isSearchOpen && this.isTopNavBarOverflowing;

    if (shouldHideButtonsAndAvatars !== this.shouldHideButtonsAndAvatars) {
      this.shouldHideButtonsAndAvatars = shouldHideButtonsAndAvatars;
      this.cdr.detectChanges();

      // when the buttons get hidden during resize, on the next resize cycle the top-nav-bar
      // will no longer be overflowing (the buttons are de-rendered), and we might wrongly show the buttons
      // so ensure that after rendering the buttons the content doesn't wrongly overflow
      if (this.isTopNavBarOverflowing) {
        this.setViewModeRules();
      }
    }
  }

  private observeContentResize(): void {
    this.resize$.pipe(untilDestroyed(this), throttleTime(50, asyncScheduler, { leading: true, trailing: true })).subscribe(() => this.onResize());
    const resizeObserver = new ResizeObserver(() => this.resize$.next());
    [this.topNavBar, this.titleContainer, this.headerContainer, this.buttonsContainer].forEach((element) => resizeObserver.observe(element));
  }

  private get topNavBar(): HTMLElement {
    return this.elRef.nativeElement.querySelector<HTMLElement>(".top-nav-bar");
  }

  private get buttonsContainer(): HTMLElement {
    return this.topNavBar.querySelector(".top-nav-bar__right-content");
  }

  private get headerContainer(): HTMLElement {
    return this.topNavBar.querySelector(".top-nav-bar__header-container");
  }

  private get toggleNavContainer(): HTMLElement {
    return this.topNavBar.querySelector(".top-nav-bar__toggle-nav");
  }

  private get titleContainer(): HTMLElement {
    return this.topNavBar.querySelector(".top-nav-bar__title-container .top-nav-bar__title");
  }

  private get titleInput(): HTMLInputElement {
    return this.topNavBar.querySelector(".top-nav-bar__title-container input");
  }

  private get isTitleOverflowing(): boolean {
    return (
      (this.titleContainer.scrollWidth > this.titleContainer.clientWidth || this.titleInput?.scrollWidth > this.titleInput?.clientWidth) &&
      this.titleContainer.clientWidth < TITLE_CONTAINER_MAX_WIDTH
    );
  }

  private get isTopNavBarOverflowing(): boolean {
    return (
      (this.toggleNavContainer?.scrollWidth || 0) + (this.headerContainer?.scrollWidth || 0) + (this.buttonsContainer?.scrollWidth || 0) >
      (this.topNavBar?.clientWidth || 0)
    );
  }

  private setAvatarsMode(): void {
    const initialSetting = this.visibleAvatarsCount;
    const screenWidth = document.body.clientWidth;

    if (screenWidth >= TopNavBarAvatarsBreakpoints.LARGE_SCREEN) {
      this.visibleAvatarsCount = TopNavBarVisibleAvatarsCount.LARGE_SCREEN;
    } else if (screenWidth >= TopNavBarAvatarsBreakpoints.MEDIUM_SCREEN) {
      this.visibleAvatarsCount = TopNavBarVisibleAvatarsCount.MEDIUM_SCREEN;
    } else if (screenWidth > +TopNavBarAvatarsBreakpoints.SMALL_SCREEN) {
      this.visibleAvatarsCount = TopNavBarVisibleAvatarsCount.SMALL_SCREEN;
    } else {
      this.visibleAvatarsCount = TopNavBarVisibleAvatarsCount.XS_SCREEN;
    }

    if (this.visibleAvatarsCount !== initialSetting) {
      this.cdr.detectChanges();
    }
  }
}
