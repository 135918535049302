import { HttpContext } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, switchMap, tap } from "rxjs";
import { IGoalChildReorderPayload, IGoalChildReorderResponse, IWorkflowActions } from "@gtmhub/goals/models";
import { UpdateGoalAdditionalPayloadData } from "@gtmhub/goals/services/goal.service";
import { OKRsEventType } from "@gtmhub/okrs/events";
import { ColoringService } from "@webapp/configuration/services/coloring.service";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection, ICollectionMap } from "@webapp/core/core.models";
import { GTMHUB_ADDITIONAL_PARAMS } from "@webapp/core/http/interceptors/track-data.interceptor";
import { GtmhubAdditionalParams } from "@webapp/core/http/models/http.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { IBulkDeleteResponse } from "@webapp/okrs/models/okrs-response.models";
import { Tag } from "@webapp/tags/models/tag.model";
import { CreateGoalDTO, GetGoalParams, Goal, GoalDTO, GoalInTree, IAsigneeIdsGoalsMap } from "../models/goal.models";
import { GoalsApiService } from "./goals-api.service";
import { GoalsState } from "./goals-state.service";

@Injectable({
  providedIn: "any",
})
export class GoalsFacade extends BaseFacade<Goal, GoalDTO, GoalsState, GoalsApiService> {
  constructor(
    state: GoalsState,
    api: GoalsApiService,
    private broadcastService: BroadcastService,
    private coloringService: ColoringService
  ) {
    super(state, api);
  }

  public deleteGoals$(goalsIds: string[]): Observable<IBulkDeleteResponse> {
    return this.deleteMany$(goalsIds, {
      ...new RequestConfig(),
      url: this.apiService.getBulkDeleteGoalsEndpoint(),
    });
  }

  public getGoalsV2$(filter?: RequestPaging, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<ICollection<Goal>> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.apiService.getAll$<ICollection<Goal>>(filter, { ...new RequestConfig(), ...context });
  }

  public createGoal$(
    goal: CreateGoalDTO,
    options: { skipNotification?: boolean; cloned?: boolean } = {},
    additionalGtmhubParams?: GtmhubAdditionalParams
  ): Observable<Goal> {
    const config: RequestConfig = {};

    if (options.skipNotification) {
      config.queryParams = { skipNotification: options.skipNotification };
    }
    if (options.cloned) {
      config.queryParams = { cloned: options.cloned };
    }

    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.post$(goal, {
      ...new RequestConfig(),
      ...context,
      queryParams: {
        ...config.queryParams,
      },
    }).pipe(
      tap((newGoal) => {
        this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalCreated", detail: { goal: newGoal } });
      })
    );
  }

  public deleteGoal$(goalId: string, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Goal> {
    const context = additionalGtmhubParams ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) } : {};

    return this.delete$(goalId, {
      ...new RequestConfig(),
      ...context,
    }).pipe(
      tap(() => {
        this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalDeleted", detail: { goalId } });
      })
    );
  }

  public getGoal$(goalId: string, params?: GetGoalParams, additionalGtmhubParams?: GtmhubAdditionalParams): Observable<Goal> {
    const context = { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalGtmhubParams) };
    return this.get$<Goal>(goalId, {
      ...new RequestConfig(),
      ...context,
      queryParams: { ...params },
    }).pipe(switchMap((goal) => this.applyColoringToGoal(goal)));
  }

  private applyColoringToGoal(goal: GoalInTree): Observable<Goal> {
    goal.color = this.coloringService.getColor(goal.attainment);
    goal.aggregatedAttainmentColor = this.coloringService.getColor(goal.fullAggregatedAttainment);

    if (goal.metrics) {
      for (const metric of goal.metrics) {
        this.applyColoringToMetric(metric);
      }
    }

    return of(goal);
  }

  private applyColoringToMetric(metric: Metric): Metric {
    metric.color = this.coloringService.getColor(metric.attainment);
    return metric;
  }

  public patchGoal$(goalId: string, goal: Partial<Goal>, gtmhubAdditionalParams?: GtmhubAdditionalParams): Observable<Goal> {
    const context = { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, gtmhubAdditionalParams) };

    return this.apiService
      .patch$<Goal>(goalId, goal, {
        ...new RequestConfig(),
        ...context,
        queryParams: {
          includeMetrics: gtmhubAdditionalParams.includeMetrics,
        },
      })
      .pipe(
        tap((patchedGoal) => {
          const args = { reason: "goalPatched", detail: { goal: patchedGoal } };
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { ...args });
        })
      );
  }

  public approveGoal$(goalId: string, comment?: string): Observable<Goal> {
    return this.post$(
      { comment: comment },
      {
        ...new RequestConfig(),
        url: this.apiService.getApproveEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastService.emit("approvedGoal", goalId);
        this.broadcastWorkflowUpdate("reviewApproved", goalId);
        if (comment) {
          this.broadcastService.emit("commentPostedFromWorkflowUpdate", { targetId: goalId, targetType: "goal" });
        }
      })
    );
  }

  public declineGoal$(goalId: string, comment?: string): Observable<Goal> {
    return this.post$(
      { comment: comment },
      {
        ...new RequestConfig(),
        url: this.apiService.getDeclineEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastWorkflowUpdate("reviewDeclined", goalId);
        this.broadcastService.emit("commentPostedFromWorkflowUpdate", { targetId: goalId, targetType: "goal" });
      })
    );
  }

  public withdrawGoal$(goalId: string): Observable<Goal> {
    return this.post$(
      {},
      {
        ...new RequestConfig(),
        url: this.apiService.getWithdrawEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastWorkflowUpdate("reviewWithdrawn", goalId);
      })
    );
  }

  public sendForApproval$(goalId: string, reviewersIds: []): Observable<Goal> {
    return this.post$(
      {
        reviewers: reviewersIds,
      },
      {
        ...new RequestConfig(),
        url: this.apiService.getSendForApprovalEndpoint(goalId),
      }
    ).pipe(
      tap(() => {
        this.broadcastWorkflowUpdate("reviewSentForApproval", goalId);
      })
    );
  }

  public reorderGoalChild$(payload: IGoalChildReorderPayload): Observable<IGoalChildReorderResponse> {
    return this.apiService
      .patch$<IGoalChildReorderResponse>(null, payload, {
        ...new RequestConfig(),
        url: this.apiService.getChildreorderEndpoint(),
      })
      .pipe(
        tap((response) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "goalChildReordered",
            detail: {
              goalId: payload.parentId,
              childId: payload.moved.id,
              childType: payload.moved.type,
              childOrderId: response.orderId,
            },
          });
        })
      );
  }

  public getGoalWorkflowActions$(goalId: string): Observable<IWorkflowActions> {
    return this.get$<IWorkflowActions>(null, {
      ...new RequestConfig(),
      url: this.apiService.getGoalWorkflowActionsEndpoint(goalId),
    });
  }

  private broadcastWorkflowUpdate(update: string, goalId: string): void {
    this.broadcastService.emit(update, goalId);
    this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, { reason: "goalChanged" });
    this.broadcastService.emit("goalsChanged");
  }

  public addTag$(goalId: string, title: string, additionalPayloadData?: UpdateGoalAdditionalPayloadData): Observable<Tag> {
    const context = additionalPayloadData ? { context: new HttpContext().set(GTMHUB_ADDITIONAL_PARAMS, additionalPayloadData) } : {};

    return this.apiService
      .post$<Tag>(
        { title },
        {
          ...new RequestConfig(),
          url: this.apiService.getAddGoalTagsEndpoint(goalId),
          ...context,
        }
      )
      .pipe(
        tap((tag: Tag) => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagAdded",
            detail: {
              goalId,
              tag,
            },
          });
        })
      );
  }

  public removeTag$(goalId: string, tag: Tag): Observable<void> {
    return this.apiService
      .delete$<void>(null, {
        ...new RequestConfig(),
        url: this.apiService.getRemoveGoalTagsByTitleEndpoint(goalId, [tag.title]),
      })
      .pipe(
        tap(() => {
          this.broadcastService.emit(OKRsEventType.OKRS_GOALS_CHANGED, {
            reason: "tagRemoved",
            detail: {
              goalId,
              tag,
            },
          });
        })
      );
  }

  public achieveGoal$(goalId: string): Observable<void> {
    return this.apiService.put$<void>(
      null,
      { status: "achieved" },
      {
        ...new RequestConfig(),
        url: this.apiService.getStatusEndpoint(goalId),
      }
    );
  }

  public abandonGoal$(goalId: string, reason: string): Observable<void> {
    return this.apiService.put$<void>(
      null,
      { status: "abandoned", reason: reason },
      {
        ...new RequestConfig(),
        url: this.apiService.getStatusEndpoint(goalId),
      }
    );
  }

  public reopenGoal$(goalId: string): Observable<void> {
    return this.apiService.delete$<void>(null, {
      ...new RequestConfig(),
      url: this.apiService.getStatusEndpoint(goalId),
    });
  }

  public getGoalsGroupedByOwners$(sessionId: string, skip = null, take = null, approvalStatus = null): Observable<ICollectionMap<IAsigneeIdsGoalsMap>> {
    return this.apiService.get$<ICollectionMap<IAsigneeIdsGoalsMap>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getGoalsGroupedByOwnersEndpoint(),
      queryParams: {
        sessionId,
        skip,
        take,
        approvalStatus,
      },
    });
  }

  public getGoalsGroupedByReviewers$(sessionId: string, skip = null, take = null, review = null, approvalStatus = null): Observable<ICollectionMap<IAsigneeIdsGoalsMap>> {
    return this.apiService.get$<ICollectionMap<IAsigneeIdsGoalsMap>>(null, {
      ...new RequestConfig(),
      url: this.apiService.getGoalsGroupedByReviewersEndpoint(),
      queryParams: {
        sessionId,
        skip,
        take,
        review,
        approvalStatus,
      },
    });
  }
}
