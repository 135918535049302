import { Injectable } from "@angular/core";
import { Observable, filter, map } from "rxjs";
import { EntityTypePermissions } from "@webapp/configuration/models/configuration.model";
import { TreeListItem } from "@webapp/shared/treelist-selector/treelist-selector.models";
import { Assignee } from "../models/assignee.models";
import { AssigneeListDataTransformerService } from "./assignee-data-transformers.service";
import { AssigneesCache } from "./assignees-cache";

interface AssigneeSelectorContext {
  assigneesFilter?(propSetting: EntityTypePermissions, assignee: Assignee[]): Assignee[];
}

// Assignees are populated in Redux by Angular.js,
// so for now we don't need an API service in Angular
@Injectable({ providedIn: "root" })
export class AssigneesRepository {
  constructor(
    private cache: AssigneesCache,
    private assigneeListDataTransformerService: AssigneeListDataTransformerService
  ) {}

  public getMap$(): Observable<Map<string, Assignee>> {
    return this.cache.get$().pipe(filter(Boolean));
  }

  public getAssigneeTreeData$(propSetting: EntityTypePermissions, context: AssigneeSelectorContext): Observable<TreeListItem[]> {
    return this.cache.get$().pipe(
      map((assignees) => {
        const filteredAssignees = context.assigneesFilter(propSetting, Array.from(assignees.values()));

        return this.assigneeListDataTransformerService.buildList(filteredAssignees);
      })
    );
  }
}
