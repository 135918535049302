import { module } from "angular";
import editionPlanChange from "@gtmhub/edition-plan-change/edition-plan-change.module";
import employeesCore from "@gtmhub/employees/employees-core.module";
import { AccountSuspendedCtrl } from "@gtmhub/login/controllers/account-suspended-ctrl";
import { AccountSwitchedCtrl } from "@gtmhub/login/controllers/account-switched-ctrl";
import { CreateAccountController } from "@gtmhub/login/controllers/create-account-ctrl";
import { ResolveAccountCtrl } from "@gtmhub/login/controllers/resolve-account-ctrl";
import { ResolveTokenCtrl } from "@gtmhub/login/controllers/resolve-token";
import { UserDeactivatedCtrl } from "@gtmhub/login/controllers/user-deactivated-ctrl";
import { AccountMarketingInfoScreenComponent } from "@webapp/accounts/components/account-marketing-info-screen/account-marketing-info-screen.component";
import { PrivacyNoticeComponent } from "@webapp/shared/components/privacy-notice/privacy-notice.component";
import { UsersFacade } from "@webapp/users/services/users-facade.service";
import { InviteUserToAccountService } from "./services/invite-user-to-account.service";

const mod = module("login", [editionPlanChange, employeesCore]);

mod.controller("CreateAccountController", CreateAccountController);
mod.controller("ResolveAccountCtrl", ResolveAccountCtrl);
mod.controller("ResolveTokenCtrl", ResolveTokenCtrl);
mod.controller("UserDeactivatedCtrl", UserDeactivatedCtrl);
mod.controller("AccountSuspendedCtrl", AccountSuspendedCtrl);
mod.controller("AccountSwitchedCtrl", AccountSwitchedCtrl);
mod.downgradeComponent("privacyNoticeComponent", { component: PrivacyNoticeComponent, propagateDigest: false });
mod.downgradeComponent("accountMarketingInfoScreenComponent", { component: AccountMarketingInfoScreenComponent, propagateDigest: false });

mod.service("InviteUserToAccountService", InviteUserToAccountService);

mod.downgradeInjectable("UsersFacade", UsersFacade);

export default mod.name;
