import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { IAccount } from "@gtmhub/core";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { EditionFeature } from "@webapp/accounts/models/edition.models";

export const platformIntelligenceEditionFeatures: Record<string, EditionFeature> = {
  pi: "platform-intelligence",
  uc1: "pi.suggest-krs-to-okrs",
  uc2: "pi.suggest-tasks-to-okrs",
  uc3: "pi.suggest-description-to-okrs",
  uc4: "pi.suggest-tags-to-okrs",
  uc1_4_ext: "pi.all-suggestions",
  uc5: "pi.suggest-kr-create-form",
  uc6: "pi.assign-the-right-person",
  uc7: "pi.guided-okr-creation",
  uc7_v3: "pi.guided-okrs-copilot",
  uc8: "pi.effectiveness",
  uc10: "pi.human-filters",
  uc13: "pi.kpi-suggestions",
  uc14: "pi.improve-kpi-through-okr",
  uc17: "pi.suggest-task-creation-from-comment",
  uc22_beta: "pi.text-to-sql",
  uc22: "pi.next-gen-insight-boards",
  uc26: "pi.overview",
  uc27: "pi.who-else-is-working-on-this",
  uc29: "pi.auto-mining-tasks",
  uc30: "pi.explain-sql",
  uc31: "pi.whiteboards",
  uc32: "pi.suggest-title-create-okr",
  uc33: "pi.auto-alignment-of-okrs",
  uc34: "pi.duplicate-detection",

  // introdusing three new features
  // pi.suggestion-drawer-overview-tab, pi.whiteboard-suggestions, pi.insights-text-to-sql
  // that duplicates pi.guided-okrs-copilot, pi.text-to-sql and platform-intelligence
  // with dependencies on Launchdarkly flags,
  // this functionality should be enabled to all accounts with Silver, Gold and Platinum plans
  uc35: "pi.suggestion-drawer-overview-tab",
  uc36: "pi.whiteboard-suggestions",
  uc37: "pi.insights-text-to-sql",
} as const;

export type PIEditionKeys = (typeof platformIntelligenceEditionFeatures)[keyof typeof platformIntelligenceEditionFeatures];
export type AtLeastOne<T> = [T, ...T[]];

/**
 * This facade service is responsible for PI feature visibility evaluation based on feature packaging.
 *
 * In order to have a PI Feature visible, the following conditions must be met:
 * 1. platform-intelligence must be part of the account's edition plan
 * 2. the specific edition key for the feature must be part of the account's edition plan
 *
 * @see {@link https://coda.io/d/Platform-Intelligence_d2VsKQhcZZe/Use-Cases-Briefs_su-0f?utm_source=slack#Pricing-and-packaging_tu1c8/r13}
 */
@Injectable({
  providedIn: "any",
})
export class PiFeaturePackagingFacade {
  private account: IAccount;

  constructor(private accountResolverService: AccountResolverService) {}

  /**
   * UC1: Suggesting Key Results to OKRs
   */
  public isSuggestingKRsToOKRsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc1);
  }

  /**
   * UC2: Suggesting Tasks to OKRs
   */
  public isSuggestingTasksToOKRsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc2);
  }

  /**
   * UC3: Suggesting Description to OKRs
   */
  public isSuggestingDescriptionToOKRsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc3);
  }

  /**
   * UC4: Suggesting Tags to OKRs
   */
  public isSuggestingTagsToOKRsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc4);
  }

  /**
   * UC1-4 Extension: All Suggestions
   */
  public isAllSuggestionsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc1_4_ext);
  }

  /**
   * UC5: Suggest Key Result in the Create Key Result Form
   */
  public isSuggestingKRInCreateKRFormIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc5);
  }

  /**
   * UC6: Assigning The Right Person to an OKR/Task
   */
  public isAssigningTheRightPersonToOKRorTaskIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc6);
  }

  /**
   * UC7: Guided OKRs Creation (aka Quantive Coach)
   */
  public isGuidedOKRsCreationIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc7);
  }

  /**
   * UC7-v3:  Suggesting OKRs in Whiteboards
   */
  public isSuggestingOKRsInWhiteboardsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc7_v3);
  }

  /**
   * UC8: Helping Users Write Better OKRs (Effectiveness)
   */
  public isEffectivenessIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc8);
  }

  /**
   * UC10: Human Filters
   */
  public isHumanFiltersIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc10);
  }

  /**
   * UC13: KPI Suggestions
   */
  public isKPISuggestionsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc13);
  }

  /**
   * UC14: Improving KPI through an OKR
   */
  public isImprovingKPIThroughOKRIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc14);
  }

  /**
   * UC17: Suggest Task Creation Out Of Comment Thread
   */
  public isSuggestingTaskCreationOutOfCommentThreadIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc17);
  }

  /**
   * UC22 (beta): Text2SQL in the SQL editor - would act as a base for UC22: Next Gen InsightBoards
   */
  public isTextToSqlIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc22_beta);
  }

  /**
   * UC22: Next Gen InsightBoards
   */
  public isNextGenInsightBoardsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc22);
  }

  /**
   * UC26: Explaining an Objective or KR like a 5yo (Overview)
   *
   * (Enterprise Addon only - do not add addon feature key yet)
   */
  public isOverviewIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi);
  }

  /**
   * UC27: Who Else Is Working On This
   */
  public isWhoElseIsWorkingOnThisIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc27);
  }

  /**
   * UC29: Automatically Mining Tasks
   */
  public isAutomaticallyMiningTasksIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc29);
  }

  /**
   * UC30 Explaining SQL in Plain English
   */
  public isExplainingSQLInPlainEnglishIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc30);
  }

  /**
   * UC31: AI Whiteboards
   */
  public isAIWhiteboardsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc31);
  }

  /**
   * UC32: Suggesting Objective Title While Creating Objective
   */
  public isSuggestingOKRTitleWhileCreatingOKRIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc32);
  }

  /**
   * UC33: Auto Alignment of OKRs
   */
  public isAutoAlignmentOfOKRsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc33);
  }

  /**
   * UC34: OKR Kill-ision - Duplicate Detection
   */
  public isDuplicateDetectionIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc34);
  }

  /**
   * UC35: Suggestion Drawer Overview Tab
   */
  public isSuggestionDrawerOverviewTabIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc35);
  }

  /**
   * UC36: Whiteboard Suggestions
   */
  public isWhiteboardSuggestionsIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc36);
  }

  /**
   * UC37: Insights Text to SQL
   */
  public isInsightsTextToSQLIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi, platformIntelligenceEditionFeatures.uc37);
  }

  /**
   * Checks if platform-intelligence is part of the current edition plan
   */
  public isPlatformIntelligenceIncludedInEditionPlan$(): Observable<boolean> {
    return this.hasFeatureIncludedInEditionPlan(platformIntelligenceEditionFeatures.pi);
  }

  private hasFeatureIncludedInEditionPlan(...featureKeys: AtLeastOne<PIEditionKeys>): Observable<boolean> {
    this.setAccountDataIfMissing();

    return of(featureKeys.reduce((isAvailable, featureKey) => isAvailable && this.account?.edition.features.indexOf(featureKey) !== -1, true));
  }

  private setAccountDataIfMissing(): void {
    if (this.account) return;

    this.account = this.accountResolverService.getAccountData();
  }
}
