import { Inject, Injectable, InjectionToken, StaticProvider } from "@angular/core";
import { MessageService } from "@progress/kendo-angular-l10n";
import { MESSAGE_OVERRIDES } from "./message-overrides";

const KENDO_MESSAGES = new InjectionToken<Record<string, string>>("KENDO_MESSAGES");

export function kendoMessageServiceProvider(kendoMessages: Record<string, string>): StaticProvider {
  return {
    provide: MessageService,
    useFactory: (): KendoMessageService => new KendoMessageService({ ...kendoMessages, ...MESSAGE_OVERRIDES }),
  };
}

/**
 * A basic implementation of a service that provides localized messages for Kendo components.
 * This service (and related) code could be changed to support more complex localization scenarios(e.g. changing current language or direction, etc.).
 */
@Injectable()
class KendoMessageService extends MessageService {
  constructor(@Inject(KENDO_MESSAGES) private messages: Record<string, string>) {
    super();
  }

  public get(key: string): string {
    return this.messages[key];
  }
}
