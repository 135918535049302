import { Injectable } from "@angular/core";
import { v4 as uuidv4 } from "uuid";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { GlobalSearchTrackingEvents, RecentSearchFacetsOptions } from "@webapp/search/models/search.models";
import dayjs from "@webapp/shared/libs/dayjs";

@Injectable({ providedIn: "root" })
export class GlobalSearchTrackingService {
  private searchTimer: number;
  private searchUuid: string;

  private recentTimer: number;
  private recentUuid: string;

  constructor(
    private analyticsService: AnalyticsService,
    private accountResolverService: AccountResolverService
  ) {}

  public trackSearch(eventName: GlobalSearchTrackingEvents, currentFacet: RecentSearchFacetsOptions, meta: TrackingMetadata = {}): void {
    this.track(eventName, currentFacet, meta, this.getSearchId());
  }

  public trackRecent(eventName: GlobalSearchTrackingEvents, currentFacet: RecentSearchFacetsOptions, meta: TrackingMetadata = {}): void {
    this.track(eventName, currentFacet, meta, this.getRecentSearchId());
  }

  private track(eventName: GlobalSearchTrackingEvents, currentFacet: RecentSearchFacetsOptions, meta: TrackingMetadata = {}, searchId): void {
    const enrichedMeta = {
      ...meta,
      account_type: this.accountResolverService.getAccountData().type,
      state_view: false,
      search_id: searchId,
      facet: currentFacet,
    };
    this.analyticsService.track(eventName, enrichedMeta);
  }

  private getSearchId(): string | number {
    if (dayjs.utc().isBefore(this.searchTimer)) {
      return this.searchUuid;
    } else {
      this.searchTimer = dayjs.utc().add(1, "minutes").valueOf();
      this.searchUuid = uuidv4();
      return this.recentUuid;
    }
  }

  private getRecentSearchId(): string | number {
    if (dayjs.utc().isBefore(this.recentTimer)) {
      return this.recentUuid;
    } else {
      this.recentTimer = dayjs.utc().add(1, "minutes").valueOf();
      this.recentUuid = uuidv4();
      return this.recentUuid;
    }
  }
}
