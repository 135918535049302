import { StateService, UIRouterGlobals } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiCardModule } from "@quantive/ui-kit/card";
import { take } from "rxjs";
import { IUIError, createUIError } from "@gtmhub/error-handling";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";

@UntilDestroy()
@Component({
  selector: "platform-redirect",
  standalone: true,
  imports: [UiCardModule, UiLoadingIndicatorModule, LocalizationModule, UiAlertModule],
  templateUrl: "./platform-redirect.component.html",
  styleUrl: "./platform-redirect.component.less",
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PlatformRedirectComponent implements OnInit, OnDestroy {
  public error: IUIError;
  public loading = true;
  private timeout: ReturnType<typeof setTimeout>;

  constructor(
    private cdr: ChangeDetectorRef,
    private state: StateService,
    private routerGlobals: UIRouterGlobals,
    private permissionsApiService: PermissionsFacade
  ) {}

  public ngOnInit(): void {
    this.permissionsApiService
      .getAvailableProducts$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (products) => {
          const platform = products.find((product) => product.name === "Platform");

          if (platform) {
            // getting the url from the api so that if in future the url changes we don't have to change the code
            const platformUrl = platform.url.split("/")[1] || "platform";

            this.timeout = setTimeout(() => {
              const redirectTo = this.routerGlobals.params.to;
              window.open(`/${platformUrl}/settings/${redirectTo}`, "_self");
            }, 4000);
          }
        },
        error: (error) => {
          this.loading = false;
          this.error = createUIError(error);
          this.cdr.markForCheck();
        },
      });
  }

  public ngOnDestroy(): void {
    clearTimeout(this.timeout);
  }

  public onRefresh(): void {
    this.state.go(".", {}, { reload: true });
  }
}
