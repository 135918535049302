import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, Output } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiConfigurationService, UiCoreModule } from "@quantive/ui-kit/core";
import { UiIconModule, UiThemeType } from "@quantive/ui-kit/icon";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { UiAvatarSize } from "@webapp/ui/avatar/avatar.models";

@Component({
  selector: "ui-avatar",
  exportAs: "uiAvatar",
  templateUrl: "avatar.component.html",
  styleUrls: ["./avatar.component.less"],
  host: {
    "[class.ant-avatar]": `true`,
    "[class.ant-avatar-circle]": `true`,
    "[class.ui-avatar-x-small]": `size === 'xs'`,
    "[class.ui-avatar-small]": `size === 'sm'`,
    "[class.ui-avatar-medium]": `size === 'md'`,
    "[class.ui-avatar-large]": `size === 'lg'`,
    "[class.ui-avatar-x-large]": `size === 'xl'`,
    "[class.ui-avatar-xx-large]": `size === 'xxl'`,
    "[class.ui-avatar-disabled]": `disabled`,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiAccessibilityModule, UiIconModule, UiCoreModule],
})
export class UiAvatarComponent implements OnChanges {
  /**
   * @ignore
   */
  public patternSrc?: string;

  /**
   * @ignore
   */
  public realAlt: string;

  @Input("uiSize")
  public size: UiAvatarSize = "xs";

  @Input("uiPicture")
  public picture?: string;

  @Input("uiAlt")
  public alt?: string;

  @Input("uiIconType")
  public icon?: string;

  @Input("uiTheme")
  public theme?: UiThemeType;

  @Input("uiBgColor")
  public bgColor?: string;

  @Input("uiPattern")
  public pattern?: string;

  @Input("uiDisabled")
  @InputBoolean()
  public disabled?: boolean;

  @Input("uiText")
  public text?: string;

  @Input("uiTextColor")
  public textColor?: string;

  @Input("uiIconFull")
  @InputBoolean()
  public iconFull?: boolean;

  @Input()
  @InputBoolean()
  public hasBorder?: boolean;

  /**
   * @ignore
   */
  @Output()
  public readonly uiError = new EventEmitter<Event>();

  constructor(private configurationService: UiConfigurationService) {}

  /**
   * @ignore
   */
  public ngOnChanges(): void {
    const cdnUrl = this.configurationService.cdnUrl || "";
    this.patternSrc = this.pattern ? `${cdnUrl}/dist/img/patterns/${this.pattern}.png` : undefined;
    this.realAlt = this.alt == null ? "" : this.alt;
  }
}
