import { CommonModule } from "@angular/common";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
  booleanAttribute,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiSkeletonModule } from "@quantive/ui-kit/skeleton";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { SearchResultsComponent } from "@webapp/search/components/search-results/search-results.component";
import {
  GlobalSearchItem,
  ITEMS_LIMIT_FOR_SIMPLE_SEARCH,
  SearchCollection,
  SearchFacetsOptions,
  SearchItemsLimit,
  SearchTracingName,
} from "@webapp/search/models/search.models";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { ModelChangeDebounceDirective } from "@webapp/shared/directives/model-change-debounce/model-change-debounce.directive";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";

@UntilDestroy()
@Component({
  selector: "simple-search",
  templateUrl: "./simple-search.component.html",
  styleUrls: ["./simple-search.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiInputModule,
    UiSkeletonModule,
    UiIconModule,
    LocalizationModule,
    FormsModule,
    UiPopoverModule,
    SearchResultsComponent,
    ModelChangeDebounceDirective,
  ],
})
export class SimpleSearchComponent implements OnInit, OnDestroy {
  @Input()
  public searchPlaceholder: string = "";

  @Input()
  public totalNumberOfItems: SearchItemsLimit = ITEMS_LIMIT_FOR_SIMPLE_SEARCH;

  @Input({ transform: booleanAttribute })
  public readonly activeUsersOnly: boolean;

  @Input({ transform: booleanAttribute })
  public readonly activeTeamsOnly: boolean;

  @Input({ required: true })
  public searchCollections: SearchFacetsOptions[] = [];

  @Input()
  public permissions: SearchFacetsOptions[] = [];

  @Output()
  public readonly itemClicked = new EventEmitter<GlobalSearchItem<SearchCollection>>();

  public searchTerm: string;
  public shouldShowPopover = false;

  private clickSubscription: () => void;

  @ViewChild("searchInput") private searchInput: ElementRef;
  @ViewChild("resultsPopover") private resultsPopover: ElementRef;

  constructor(
    private globalSearchMediatorService: GlobalSearchMediatorService,
    private cdr: ChangeDetectorRef,
    private renderer: Renderer2
  ) {}

  public ngOnDestroy(): void {
    if (this.clickSubscription) this.clickSubscription();
  }

  public ngOnInit(): void {
    this.globalSearchMediatorService.setStaticFacetOptions(this.searchCollections);
    this.globalSearchMediatorService.setStaticItemsLimit(this.totalNumberOfItems);
    this.globalSearchMediatorService.setQueryParams({ take: this.totalNumberOfItems, skip: 0 }, { load: false });

    this.globalSearchMediatorService.setSearchSettings({ activeTeamsOnly: this.activeTeamsOnly, activeUsersOnly: this.activeUsersOnly });

    this.globalSearchMediatorService.customItemClickHandler$.pipe(untilDestroyed(this)).subscribe((item) => {
      this.searchTerm = null;
      this.itemClicked.emit(item);
      this.closeSearchResults();
    });
  }

  public onSearchTermChange(): void {
    this.globalSearchMediatorService.setSearchTerm(this.searchTerm, SearchTracingName.SS__CHANGED_SEARCH_TERM);
    this.shouldShowPopover = true;

    if (!this.clickSubscription) {
      this.subscribeForClickOutside();
    }

    this.cdr.markForCheck();
  }

  public onEsc(): void {
    this.shouldShowPopover = false;
    this.searchInput.nativeElement.focus();
    this.cdr.markForCheck();
  }

  public focusSearchItems(): void {
    this.resultsPopover?.nativeElement?.querySelector("li.item-row.section-list")?.focus();
  }

  private subscribeForClickOutside(): void {
    this.clickSubscription = this.renderer.listen("window", "click", (e: Event) => {
      if (this.shouldShowPopover && !this.searchInput.nativeElement.contains(e.target) && !this.resultsPopover?.nativeElement.contains(e.target)) {
        this.closeSearchResults();
      }
    });
  }

  private closeSearchResults(): void {
    this.shouldShowPopover = false;
    this.clickSubscription();
    this.clickSubscription = null;
    this.cdr.markForCheck();
  }
}
