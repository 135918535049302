export type OkrGridViewMode = "x-small" | "small" | "medium" | "large" | "x-large";

export interface SectionExpandState {
  panel?: boolean;
  summary?: boolean;
}

interface OverviewItem {
  id: string;
  name: string;
  attainment: number;
  attainmentDelta: number;
  ownerIds: string[];
  isRestricted?: boolean;
}

export interface MetricOverviewItem extends OverviewItem {
  confidence?: {
    value: number;
  };
}

export interface GoalOverviewItem extends OverviewItem {
  metrics: MetricOverviewItem[];
  sessionName: string;
}

export interface OkrsSessionGroup {
  id: string;
  title: string;
  items: GoalOverviewItem[];
  isRestricted?: boolean;
}

export type ActiveOkrsListGroup = "session" | "ownership";
export const DEFAULT_ACTIVE_OKRS_LIST_GROUP: ActiveOkrsListGroup = "session";

export type ActiveOkrsListSort = "relevance" | "alphabeticalAsc" | "alphabeticalDesc";
export const DEFAULT_ACTIVE_OKRS_LIST_SORT: ActiveOkrsListSort = "relevance";

export interface ActiveOkrsListGroupSort {
  groupBy: ActiveOkrsListGroup;
  sortBy: ActiveOkrsListSort;
}
