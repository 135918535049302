import { IHttpService, IPromise } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { AccountType } from "@gtmhub/core";
import { EnvironmentService, getPathPrefix } from "@gtmhub/env";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { getCurrentUserId } from "@gtmhub/users";
import { CurrentUserRepository } from "@webapp/users";
import { IFirstOkrGuide } from "./components/first-okr-guide";
import { IChecklist } from "./models/onboarding.models";

export class OnboardingService {
  static $inject = ["$uibModal", "$http", "EnvironmentService", "CurrentUserRepository", "AccountResolverService", "AccountService"];

  constructor(
    private $uibModal: IModalService,
    private $http: IHttpService,
    private environmentService: EnvironmentService,
    private currentUserRepository: CurrentUserRepository,
    private accountResolverService: AccountResolverService,
    private accountService: AccountService
  ) {}

  openVideoModal(checklist: IChecklist): IChecklist {
    this.$uibModal.open({
      template: require("../shared/views/video-modal.html"),
      backdrop: false,
      windowClass: "medium",
      backdropClass: "dark-backdrop",
    });

    const newChecklist = Object.assign({}, checklist);
    newChecklist.stepsV1.watchedVideo = true;
    return newChecklist;
  }

  createDemoAccount(checklist: IChecklist): IPromise<IChecklist> {
    const demoAccountUrl = this.environmentService.getApiEndpoint("/users/demo");
    const newChecklist = Object.assign({}, checklist);

    const demoModal = this.$uibModal.open({
      template: require("../shared/views/demo-account-modal.html"),
      backdrop: true,
      windowClass: "medium vertical-center",
      backdropClass: "dark-backdrop",
    });

    return this.$http
      .post<{ idToken: string; accessToken: string; userId: string; planId: string; edition: string; accountDomain: string }>(demoAccountUrl, null)
      .then((response) => {
        const pathAndQuery = `${getPathPrefix()}/signup-gateway?idToken=${encodeURIComponent(response.data.idToken)}&accessToken=${encodeURIComponent(
          response.data.accessToken
        )}&userId=${encodeURIComponent(response.data.userId)}&planId=${response.data.planId}&edition=${response.data.edition}`;

        const host = this.environmentService.constructGtmhubDomain(response.data.accountDomain);
        const redirectTo = `https://${host}${pathAndQuery}`;
        window.open(redirectTo);

        newChecklist.stepsV1.visitedDemoAccount = true;
        demoModal.close();
        return newChecklist;
      });
  }

  visitMarketplace(checklist: IChecklist): IChecklist {
    window.open("/marketplace");

    const newChecklist = Object.assign({}, checklist);
    newChecklist.stepsV1.visitedMarketplace = true;
    return newChecklist;
  }

  initChecklist(): IChecklist {
    let checklist: IChecklist = this.currentUserRepository.getUserSetting("checklist");

    if (!checklist) {
      checklist = {
        stepsV1: {
          watchedVideo: false,
          invitedTeammate: false,
          createdOkr: false,
          visitedDemoAccount: false,
          visitedMarketplace: false,
          hasAssignedOkrs: false,
        },
        stepsV1Completed: false,
        showCards: {
          inviteTeammate: true,
          visitDemoAccount: true,
          visitMarketplace: true,
        },
        optOut: false,
      };

      this.currentUserRepository.setUserSetting({ checklist });
    }

    return checklist;
  }

  shouldSeeOnboardingExperiment(): boolean {
    const account = this.accountResolverService.getAccountData();
    const checklist: IChecklist = this.initChecklist();
    let isAccountConverted = false;

    // when we pay for the subscription the account is still a trial but is already converted, so the user should not see onboarging
    if (account.subscriptions && account.subscriptions.length) {
      const accountSubscription = account.subscriptions.find((license) => license.type === "regular");
      isAccountConverted = accountSubscription ? accountSubscription.isConverted : false;
    }

    const isAccountTrial = account.type === AccountType.TrialAccount && !isAccountConverted;
    const isFreeAccount = account.type === AccountType.FreeAccount;

    if (!(isAccountTrial || isFreeAccount)) {
      return false;
    }

    if (checklist.stepsV1.hasAssignedOkrs) {
      return false;
    }

    if (checklist.stepsV1.createdOkr) {
      return false;
    }

    if (checklist.showCards.inviteTeammate) {
      return true;
    }

    if (checklist.showCards.visitDemoAccount) {
      return true;
    }

    return true;
  }

  initFirstOkrGuide(): IFirstOkrGuide {
    let guide: IFirstOkrGuide = this.accountService.getAccountSetting("firstOkrGuide");

    if (!guide) {
      guide = {
        steps: {
          showedSession: false,
          welcomedToSession: false,
          explainedObjectiveView: false,
          explainedAlignmentView: false,
          showedCreateNextOkrButton: false,
        },
        optOut: false,
      };

      this.accountService.setAccountSetting("firstOkrGuide", guide);
    }

    return guide;
  }

  shouldSeeFirstOkrGuide(): boolean {
    const account = this.accountResolverService.getAccountData();
    const currentUserId = getCurrentUserId();
    const isTrialOrFreeAccount = account.type === AccountType.TrialAccount || account.type === AccountType.FreeAccount;
    if (isTrialOrFreeAccount && currentUserId === account.ownerId) {
      const guide: IFirstOkrGuide = this.initFirstOkrGuide();
      return guide.optOut === false && !guide.steps.showedCreateNextOkrButton;
    }

    return false;
  }
}
