<ng-template #drawerTemplate>
  <div
    class="ant-drawer"
    [nzNoAnimation]="nzNoAnimation"
    [class.ant-drawer-rtl]="dir === 'rtl'"
    [class.ant-drawer-open]="isOpen"
    [class.no-mask]="!nzMask"
    [class.ant-drawer-top]="nzPlacement === 'top'"
    [class.ant-drawer-bottom]="nzPlacement === 'bottom'"
    [class.ant-drawer-right]="nzPlacement === 'right'"
    [class.ant-drawer-left]="nzPlacement === 'left'"
    [style.transform]="offsetTransform"
    [style.transition]="placementChanging ? 'none' : null"
    [style.zIndex]="nzZIndex"
    [style.--drawer-width]="width">
    @if (nzMask && isOpen) {
      <div class="ant-drawer-mask" [ngStyle]="nzMaskStyle" (click)="maskClick()"></div>
    }
    <div [class]="'ant-drawer-content-wrapper ' + nzWrapClassName" [style.height]="height" [style.transform]="transform" [style.transition]="placementChanging ? 'none' : null">
      <div class="ant-drawer-content">
        <div class="ant-drawer-wrapper-body" [style.height]="isLeftOrRight ? '100%' : null">
          @if (nzTitle || nzClosable) {
            <div class="ant-drawer-header" [class.ant-drawer-header-close-only]="!nzTitle">
              <div class="ant-drawer-header-title">
                @if (nzTitle) {
                  <div class="ant-drawer-title">
                    <ng-container *nzStringTemplateOutlet="nzTitle">
                      <div [innerHTML]="nzTitle"></div>
                    </ng-container>
                  </div>
                }
              </div>
              @if (nzExtra) {
                <div class="ant-drawer-extra">
                  <ng-container *nzStringTemplateOutlet="nzExtra">
                    <div [innerHTML]="nzExtra"></div>
                  </ng-container>
                </div>
              }
              @if (nzClosable) {
                <button class="ant-drawer-close" [a11yTitle]="'Modal.closeText' | uiI18n" (click)="closeClick()" ui-button>
                  <ng-container *nzStringTemplateOutlet="nzCloseIcon; let closeIcon">
                    <i [uiType]="closeIcon" ui-icon></i>
                  </ng-container>
                </button>
              }
            </div>
          }
          <div class="ant-drawer-body" [ngStyle]="nzBodyStyle">
            <ng-template cdkPortalOutlet />
            @if (nzContent) {
              @if (isNzContentTemplateRef) {
                <ng-container *ngTemplateOutlet="$any(nzContent); context: templateContext" />
              }
            } @else {
              @if (contentFromContentChild && (isOpen || inAnimation)) {
                <ng-template [ngTemplateOutlet]="contentFromContentChild" />
              }
            }
          </div>
          @if (nzFooter) {
            <div class="ant-drawer-footer">
              @if (nzLoadingIndicator) {
                <ui-loading-indicator class="drawer-footer-loading-indicator" uiType="linear" />
              }
              <ng-container *nzStringTemplateOutlet="nzFooter">
                <div [innerHTML]="nzFooter"></div>
              </ng-container>
            </div>
          }
        </div>
      </div>
      <div class="side-panel" *ngIf="nzSidePanel">
        <ng-container *nzStringTemplateOutlet="nzSidePanel">
          <div [innerHTML]="nzSidePanel"></div>
        </ng-container>
      </div>
    </div>
  </div>
</ng-template>
