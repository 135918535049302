import { Injectable } from "@angular/core";
import { Observable, combineLatest, map, of, zip } from "rxjs";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureModuleService } from "@webapp/feature-toggles/services/feature-module.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { CurrentUserRepository } from "@webapp/users";

@Injectable()
export class UserOptionsVisibilityService {
  constructor(
    private featureTogglesFacade: FeatureTogglesFacade,
    private featureModuleService: FeatureModuleService,
    private permissionsFacade: PermissionsFacade,
    private editionFeatureService: EditionFeatureService,
    private currentUserRepository: CurrentUserRepository
  ) {}

  get anyOption$(): Observable<boolean> {
    return zip(this.users$, this.singleSignOn$, this.scimProvisioning$, this.groupToRoleMapping$, this.manageRoles$, this.badges$).pipe(
      map(([users, singleSignOn, scimProvisioning, groupToRoleMapping, manageRoles, badges]) => {
        return users || singleSignOn || scimProvisioning || groupToRoleMapping || manageRoles || badges;
      })
    );
  }

  get users$(): Observable<boolean> {
    return combineLatest({
      isPlatformEnabled: this.isPlatformEnabled$(),
      isUsersGranularAccessEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      hasUsersAccessPermission: this.permissionsFacade.hasPermission$("users:access"),
      hasManageUsersPermission: this.permissionsFacade.hasPermission$("ManageUsers"),
    }).pipe(
      map(({ isPlatformEnabled, isUsersGranularAccessEnabled, hasUsersAccessPermission, hasManageUsersPermission }) => {
        if (isPlatformEnabled) {
          return false;
        }

        if (isUsersGranularAccessEnabled) {
          return hasUsersAccessPermission;
        } else {
          return hasManageUsersPermission;
        }
      })
    );
  }

  get singleSignOn$(): Observable<boolean> {
    return zip(
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.FeatureSSOSettings),
      this.editionFeatureService.hasFeature$("authentication.activedirectory"),
      this.permissionsFacade.hasPermission$("ManageConfiguration"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      this.permissionsFacade.hasPermission$("sso_role_mappings:manage"),
      this.isPlatformEnabled$()
    ).pipe(
      map(
        ([
          isfeatureSSOSettingsFeatureEnabled,
          authenticationActivedirectory,
          manageConfiguration,
          isUsersGranularAccessEnabled,
          hasSSORoleMappingsManagePermission,
          isPlatformEnabled,
        ]) => {
          if (isPlatformEnabled) {
            return false;
          }

          if (isUsersGranularAccessEnabled) {
            return isfeatureSSOSettingsFeatureEnabled && authenticationActivedirectory && hasSSORoleMappingsManagePermission;
          } else {
            return isfeatureSSOSettingsFeatureEnabled && authenticationActivedirectory && manageConfiguration;
          }
        }
      )
    );
  }

  get scimProvisioning$(): Observable<boolean> {
    return zip(
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.ModuleScimAccountSettings),
      this.editionFeatureService.hasFeature$("setup.scim"),
      this.permissionsFacade.hasPermission$("ManageConfiguration"),
      of(this.currentUserRepository.userHasRole("admin")),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      this.permissionsFacade.hasPermission$("scim_integrations:manage"),
      this.isPlatformEnabled$()
    ).pipe(
      map(
        ([
          isModuleScimAccountSettingsEnabled,
          setupScim,
          manageConfiguration,
          isAdmin,
          isUsersGranularAccessEnabled,
          hasScimIntegrationsManagePermission,
          isPlatformEnabled,
        ]) => {
          if (isPlatformEnabled) {
            return false;
          }

          if (isUsersGranularAccessEnabled) {
            return isModuleScimAccountSettingsEnabled && setupScim && hasScimIntegrationsManagePermission;
          } else {
            return isModuleScimAccountSettingsEnabled && setupScim && manageConfiguration && isAdmin;
          }
        }
      )
    );
  }

  // eslint-disable-next-line sonarjs/no-identical-functions
  get groupToRoleMapping$(): Observable<boolean> {
    return zip(
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.FeatureSSOSettings),
      this.editionFeatureService.hasFeature$("authentication.activedirectory"),
      this.permissionsFacade.hasPermission$("ManageConfiguration"),
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      this.permissionsFacade.hasPermission$("sso_role_mappings:manage"),
      this.permissionsFacade.hasPermission$("scim_integrations:manage"),
      this.isPlatformEnabled$()
    ).pipe(
      map(
        ([
          isfeatureSSOSettingsFeatureEnabled,
          authenticationActivedirectory,
          manageConfiguration,
          isUsersGranularAccessEnabled,
          hasSSORoleMappingsManagePermission,
          hasSCIMPermission,
          isPlatformEnabled,
          // eslint-disable-next-line sonarjs/no-identical-functions
        ]) => {
          if (isPlatformEnabled) {
            return false;
          }

          if (isUsersGranularAccessEnabled) {
            return isfeatureSSOSettingsFeatureEnabled && authenticationActivedirectory && (hasSSORoleMappingsManagePermission || hasSCIMPermission);
          } else {
            return isfeatureSSOSettingsFeatureEnabled && authenticationActivedirectory && manageConfiguration;
          }
        }
      )
    );
  }

  get manageRoles$(): Observable<boolean> {
    return combineLatest({
      isUsersGranularAccessEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      hasRolesCreatePermission: this.permissionsFacade.hasPermission$("roles:create"),
      hasRolesEditPermission: this.permissionsFacade.hasPermission$("roles:edit"),
      hasRolesDeletePermission: this.permissionsFacade.hasPermission$("roles:delete"),
      hasManageUsersPermission: this.permissionsFacade.hasPermission$("ManageUsers"),
      isPlatformEnabled: this.isPlatformEnabled$(),
    }).pipe(
      map(({ isUsersGranularAccessEnabled, hasRolesCreatePermission, hasRolesEditPermission, hasRolesDeletePermission, hasManageUsersPermission, isPlatformEnabled }) => {
        if (isPlatformEnabled) {
          return false;
        }

        if (isUsersGranularAccessEnabled) {
          return hasRolesCreatePermission || hasRolesEditPermission || hasRolesDeletePermission;
        } else {
          return hasManageUsersPermission;
        }
      })
    );
  }

  get badges$(): Observable<boolean> {
    return this.featureModuleService.isBadgesModuleEnabled$();
  }

  private isPlatformEnabled$(): Observable<boolean> {
    return combineLatest({
      isPlatformFeatureFlagEnabled: this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PlatformLD),
      isPlatformEditionFeatureEnabled: this.editionFeatureService.hasFeature$("platform"),
    }).pipe(map(({ isPlatformFeatureFlagEnabled, isPlatformEditionFeatureEnabled }) => isPlatformFeatureFlagEnabled && isPlatformEditionFeatureEnabled));
  }
}
