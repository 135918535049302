<div class="icon-container item-icon icon-container--next" [ngSwitch]="true">
  <div *ngSwitchCase="itemsType === 'people'">
    <img class="small circle avatar" *ngIf="!avatarIsIcon()" [src]="item.icon" alt="Avatar icon" />
    <span *ngIf="avatarIsIcon()" [ngStyle]="{ background: item.color }" [ngClass]="'small circle icon avatar nc-icon-outline icon ' + (item.icon || 'users_multiple-11')"></span>
  </div>
  <div class="gh-icon gh-icon-goal icon-size-14px" *ngSwitchCase="itemsType === 'okrs'"></div>
  <div class="gh-icon gh-icon-kpi icon-size-14px" *ngSwitchCase="itemsType === 'kpis'"></div>
  <div class="gh-icon gh-icon-whiteboard icon-size-14px" *ngSwitchCase="itemsType === 'whiteboards'"></div>
  <div class="gh-icon gh-icon-insightboard icon-size-14px" *ngSwitchCase="itemsType === 'insightboards'"></div>
  <div class="gh-icon gh-icon-list icon-size-14px" *ngSwitchCase="itemsType === 'tasks'"></div>
  <div class="gh-icon gh-icon-okr-view icon-size-14px" *ngSwitchCase="itemsType === 'sessions' && item.uxcType === 'filter'"></div>
  <div class="gh-icon gh-icon-report icon-size-14px" *ngSwitchCase="itemsType === 'lists'"></div>
  <div class="icon-container__circle icon-container__circle--next" *ngSwitchDefault [ngStyle]="{ 'background-color': item.color }"></div>
</div>
