import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { ConfirmDeleteWithInputComponent } from "./components/confirm-delete-with-input/confirm-delete-with-input.component";
import { ConfirmDeleteService } from "./confirm-delete.service";

@NgModule({
  imports: [CommonModule, UiLoadingIndicatorModule, UiAlertModule, UiInputModule, LocalizationModule],
  declarations: [ConfirmDeleteWithInputComponent],
  exports: [ConfirmDeleteWithInputComponent],
  providers: [ConfirmDeleteService],
})
export class SharedModalModule {}
