import { IHttpService, IPromise } from "angular";
import { EnvironmentService } from "@gtmhub/env";
import { IReportFilter } from "@webapp/insightboards/models";
import { IHubFilter, IUpdatedFilterParams } from "../models/hub";

export class HubService {
  public static $inject = ["$http", "EnvironmentService"];

  constructor(
    private $http: IHttpService,
    private env: EnvironmentService
  ) {}

  public createFilter(filter: IHubFilter | IReportFilter): IPromise<IHubFilter | IReportFilter> {
    const url = this.env.getHubfiltersEndpoint("/filters");

    return this.$http.post<IHubFilter | IReportFilter>(url, filter).then((response) => response.data);
  }

  public getFilters(type: string): IPromise<IHubFilter[] | IReportFilter[]> {
    const url = this.env.getHubfiltersEndpoint(`/filters?filterType=${type}`);

    return this.$http.get<IHubFilter[] | IReportFilter[]>(url).then((response) => response.data);
  }

  public getFilter(filterId: string): IPromise<IHubFilter> {
    const url = this.env.getHubfiltersEndpoint("/filters/" + filterId);

    return this.$http.get<IHubFilter>(url).then((response) => response.data);
  }

  public deleteFilter(filterId: string): IPromise<void> {
    const url = this.env.getHubfiltersEndpoint("/filters/" + filterId);

    return this.$http.delete<void>(url).then(() => null);
  }

  public patchFilter(id: string, updatedFilterParams: IUpdatedFilterParams): IPromise<void> {
    const url = this.env.getHubfiltersEndpoint("/filters/" + id);

    return this.$http.patch<void>(url, updatedFilterParams).then(() => null);
  }
}
