import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { QuillModule } from "ngx-quill";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { HasAnyPermissionsDirective } from "@webapp/permissions/directives/has-any-permissions.directive";
import { CdnImgModule } from "@webapp/shared/directives/cdn-img/cdn-img.module";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiPopoverModule } from "@webapp/ui/popover/popover.module";
import { GifViewModule } from "../components/gifs/gif-view.module";
import { SearchServiceProvider } from "../providers/search.provider";
import { AddGIFComponent } from "./add-gif/add-gif.component";
import { EmojiPickerComponent } from "./emoji-picker/emoji-picker.component";
import { MentionPopoverComponent } from "./mention-popover/mention-popover.component";
import { RichTextEditorIconComponent } from "./rich-text-editor-icon/rich-text-editor-icon.component";
import { RichTextEditorComponent } from "./rich-text-editor.component";
import { RichTextViewerComponent } from "./rich-text-viewer/rich-text-viewer.component";

@NgModule({
  imports: [
    CommonModule,
    QuillModule.forRoot(),
    FormsModule,
    LocalizationModule,
    GifViewModule,
    UiIconModule,
    UiAssigneeModule,
    AssigneesModule,
    UiPopoverModule,
    CdnImgModule,
    UiInputModule,
    UiButtonModule,
    UiAccessibilityModule,
    FeatureTogglesModule,
    HasAnyPermissionsDirective,
  ],
  declarations: [RichTextEditorComponent, RichTextViewerComponent, RichTextEditorIconComponent, AddGIFComponent, EmojiPickerComponent, MentionPopoverComponent],
  exports: [RichTextEditorComponent, RichTextViewerComponent, EmojiPickerComponent],
  providers: [SearchServiceProvider],
})
export class RichTextEditorModule {}
