import { PricingEditionService } from "@gtmhub/edition-plan-change";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { providerUpgradeFactory } from "@webapp/core/provider-upgrade/factories/provider-upgrade.factory";

export const AccountResolverServiceProvider = {
  provide: AccountResolverService,
  useFactory: providerUpgradeFactory("AccountResolverService"),
  deps: ["$injector"],
};

export const PricingEditionServiceProvider = {
  provide: PricingEditionService,
  useFactory: providerUpgradeFactory("PricingEditionService"),
  deps: ["$injector"],
};
