import { ChangeDetectorRef, Directive, ElementRef, Input, OnChanges, OnDestroy, OnInit, Optional } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiAssigneeComponent } from "@webapp/ui/assignee/assignee.component";
import { UiAssigneeAvatarComponent } from "@webapp/ui/assignee/components/assignee-avatar/assignee-avatar.component";
import { UiAssigneeNameComponent } from "@webapp/ui/assignee/components/assignee-name/assignee-name.component";
import { AssigneeComponentInstances } from "../component-instance-tracker";
import { Assignee } from "../models/assignee.models";
import { AssigneesRepository } from "../services/assignees-repository.service";
import { assigneeFromMap, createMissingAssignee } from "../utils/assignee.utils";

/**
 * This directive is used with ui-assignee and ui-assignee-avatar components from the UI Kit.
 * It transforms assignee ID to an assignee and binds it to the underlying component. Since
 * assignees are changed via Web Sockets, we will automatically refresh the bindings when
 * the corresponding socket message is received.
 */
@UntilDestroy()
@Directive({
  selector: "ui-assignee-avatar[fromAssigneeId], ui-assignee[fromAssigneeId], ui-assignee-name[fromAssigneeId]",
})
export class FromAssigneeIdDirective implements OnDestroy, OnChanges, OnInit {
  @Input("fromAssigneeId")
  public assigneeId: string | string[];

  @Input()
  public hideDeleted: boolean;

  private assignees: Map<string, Assignee>;
  private ready = false;

  constructor(
    @Optional() private assigneeAvatarComponent: UiAssigneeAvatarComponent,
    @Optional() private assigneeComponent: UiAssigneeComponent,
    @Optional() private assigneeNameComponent: UiAssigneeNameComponent,
    private cd: ChangeDetectorRef,
    private elementRef: ElementRef,
    private assigneesRepository: AssigneesRepository
  ) {}

  private get component(): UiAssigneeAvatarComponent | UiAssigneeComponent | UiAssigneeNameComponent {
    return this.assigneeAvatarComponent || this.assigneeComponent || this.assigneeNameComponent;
  }

  public ngOnInit(): void {
    this.assigneesRepository
      .getMap$()
      .pipe(untilDestroyed(this))
      .subscribe((assignees) => {
        this.assignees = assignees;
        this.ready = true;
        this.setAssigneeToUnderlyingComponent();
      });
  }

  public ngOnChanges(): void {
    AssigneeComponentInstances.set(this.elementRef.nativeElement, {
      assigneeIds: typeof this.assigneeId === "string" ? [this.assigneeId] : this.assigneeId ? this.assigneeId.filter((assignee) => assignee !== undefined) : [],
      refresh: this.markForCheck.bind(this),
    });

    this.setAssigneeToUnderlyingComponent();
  }

  public ngOnDestroy(): void {
    AssigneeComponentInstances.delete(this.elementRef.nativeElement);
  }

  private setAssigneeToUnderlyingComponent(): void {
    if (!this.ready) {
      return;
    }

    if (typeof this.assigneeId === "string") {
      this.component.uiAssignee = this.assigneeId !== "" ? assigneeFromMap(this.assignees, this.assigneeId) : createMissingAssignee();
    } else {
      // If it's an array, find the first valid assignee
      this.component.uiAssignee = (this.assigneeId || [])
        .map((assigneeId) => assigneeFromMap(this.assignees, assigneeId, { hideDeleted: this.hideDeleted }))
        .filter((assignee) => assignee !== undefined)[0];
    }

    if ("ngOnChanges" in this.component) {
      this.component.ngOnChanges();
    }
  }

  private markForCheck(): void {
    this.setAssigneeToUnderlyingComponent();
    this.cd.markForCheck();
  }
}
