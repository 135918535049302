import { CommonModule } from "@angular/common";
import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiSkeletonComponent } from "@quantive/ui-kit/skeleton";
import { IIndicator } from "@gtmhub/error-handling";
import { FeatureTogglesModule } from "@webapp/feature-toggles/feature-toggles.module";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { NoSearchItemsFoundComponent } from "@webapp/search/components/no-search-items-found/no-search-items-found.component";
import { SearchItemComponent } from "@webapp/search/components/search-item/search-item.component";
import { SearchResultItemsComponent } from "@webapp/search/components/search-result-items/search-result-items.component";
import { SearchItemsLimit, SearchMode } from "@webapp/search/models/search.models";
import { GlobalSearchTrackingService } from "@webapp/search/services/global-search-tracking.service";
import { GlobalSearchMediatorService } from "@webapp/search/services/global-search.mediator.service";
import { SortByPipe } from "@webapp/shared/pipes/sort-by/sort-by.pipe";
import { UiDividerModule } from "@webapp/ui/divider/divider.module";

@UntilDestroy()
@Component({
  selector: "search-results",
  templateUrl: "./search-results.component.html",
  styleUrls: ["./search-results.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    LocalizationModule,
    UiDividerModule,
    UiButtonModule,
    FeatureTogglesModule,
    SearchItemComponent,
    SortByPipe,
    NoSearchItemsFoundComponent,
    SearchResultItemsComponent,
    UiSkeletonComponent,
  ],
  providers: [GlobalSearchTrackingService],
})
export class SearchResultsComponent implements AfterViewInit {
  @Input({ required: true })
  public searchMode: SearchMode;

  @Input()
  public maxItems: SearchItemsLimit;

  @Output()
  public readonly escKeydown = new EventEmitter<void>();

  /**
   * Custom handler for item click. It won't execute default behaviour if observed.
   * Default behaviour is to navigate to the item's url.
   * */
  @Input()
  public isCustomClickHandlerAvailable: boolean;

  @ViewChild(SearchResultItemsComponent) public searchResultItemsComponent: SearchResultItemsComponent;

  public gettingSearchData: IIndicator;

  constructor(
    private globalSearchMediatorService: GlobalSearchMediatorService,
    private cdr: ChangeDetectorRef
  ) {}

  public ngAfterViewInit(): void {
    this.globalSearchMediatorService.indicators$.pipe(untilDestroyed(this)).subscribe((indicators) => {
      this.gettingSearchData = indicators.gettingSearchData;
      this.cdr.detectChanges();
    });
  }
}
