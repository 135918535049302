import { module } from "angular";
import { badgesReducer } from "@gtmhub/badges/redux/badges-reducer";
import { DocumentExporterService } from "@gtmhub/document-exporter/document-exporter-service";
import { currentEmployeeReducer } from "@gtmhub/employees/redux/current-employee-reducer";
import { feedReducer } from "@gtmhub/feed/redux/feed-reducer";
import { rolesReducer } from "@gtmhub/roles";
import { Ng1UiModalService } from "@gtmhub/shared/components/modal/ng1-modal.service";
import ngExtensions from "@gtmhub/shared/ng-extensions/ng-extensions.module";
import { teamsReducer } from "@gtmhub/teams";
import { EditionFeature } from "@webapp/accounts/models/edition.models";
import { currentAccountCache } from "@webapp/accounts/services/current-account-cache";
import { CurrentAccountRepository } from "@webapp/accounts/services/current-account-repository.service";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { LastUrlService } from "@webapp/shared/services/last-url-service";
import { PageTitleService } from "@webapp/shared/services/page-title-service";
import { UiDrawerService } from "@webapp/ui/drawer/services/drawer.service";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { UiNotificationService } from "@webapp/ui/notification/services/notification.service";
import { UiToastService } from "@webapp/ui/toast/services/toast.service";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";
import { WhiteboardsUserSettingsService } from "@webapp/whiteboards/services/whiteboards-user-settings.service";
import { AccountService } from "./accounts/accounts.service";
import { AssigneeActions, assigneeReducer } from "./assignees";
import auth from "./auth/module";
import configurationCore from "./configuration/configuration-core.module";
import { organizationsReducer } from "./configuration/workday/redux/organizations-reducer";
import upgrade from "./core/upgrade/module";
import { customFieldsReducer } from "./customFields/redux/custom-field-reducer";
import { PricingEditionService } from "./edition-plan-change/services/pricing-edition.service";
import env from "./env/env.module";
import errorHandling from "./error-handling/module";
import featureToggle from "./featureToggle/module";
import { HubService } from "./hub/services/hub";
import insightsCore from "./insights/insights-core.module";
import localization from "./localization/module";
import { IGtmhubRootScopeService } from "./models";
import okrsCore from "./okrs/okrs-core.module";
import { pluginsReducer } from "./plugins";
import { currentUserRolesReducer } from "./roles";
import { sessionsReducer } from "./sessions/redux/session-reducer";
import { UnitFormattingForm } from "./shared/controllers/unit-formatting";
import { CheckAccess } from "./shared/factories/check-permissions";
import { Ng1ConfirmDeleteService } from "./shared/services/ng1-confirm-delete.service";
import { INgRedux } from "./state-management";
import { stateManagementModule } from "./state-management/state-management.module";
import { AccountResolverService } from "./state/account-resolver-service";
import { IPermissionsStoreState, hasPermissions, permissionsReducer } from "./users/redux";

const mod = module("app-core", [
  upgrade,
  auth,
  env,
  localization,
  featureToggle,
  ngExtensions,
  errorHandling,
  configurationCore,
  insightsCore,
  okrsCore,
  stateManagementModule({
    badges: badgesReducer,
    teams: teamsReducer,
    roles: rolesReducer,
    assignees: assigneeReducer,
    sessions: sessionsReducer,
    customFields: customFieldsReducer,
    currentEmployee: currentEmployeeReducer,
    currentUserRoles: currentUserRolesReducer,
    organizations: organizationsReducer,
    plugins: pluginsReducer,
    feed: feedReducer,
    permissions: permissionsReducer,
  }),
]);

mod.service("AccountService", AccountService);
mod.service("HubService", HubService);
mod.service("DocumentExporterService", DocumentExporterService);
mod.service("AccountResolverService", AccountResolverService);
mod.downgradeInjectable("LastUrlService", LastUrlService);
mod.service("UnitFormattingForm", UnitFormattingForm);
mod.service("PageTitleService", PageTitleService);
mod.downgradeInjectable("UiNotificationService", UiNotificationService);
mod.service("PricingEditionService", PricingEditionService);
mod.downgradeInjectable("UiToastService", UiToastService);
mod.downgradeInjectable("UserProfileService", UserProfileService);
mod.service("AssigneeActions", AssigneeActions);
mod.service("Ng1ConfirmDeleteService", Ng1ConfirmDeleteService);
mod.downgradeInjectable("CurrentAccountRepository", CurrentAccountRepository);
mod.downgradeInjectable("WhiteboardsUserSettingsService", WhiteboardsUserSettingsService);
mod.downgradeInjectable("UiModalService", UiModalService);
mod.downgradeInjectable("UiDrawerService", UiDrawerService);
mod.service("Ng1UiModalService", Ng1UiModalService);

mod.factory("CheckAccess", CheckAccess.factory());

mod.run([
  "$rootScope",
  "AccountService",
  "CurrentUserRepository",
  "$ngRedux",
  function ($rootScope: IGtmhubRootScopeService, accountService: AccountService, currentUserRepository: CurrentUserRepository, $ngRedux: INgRedux) {
    $rootScope.hasPermission = function (permission: Permission): boolean {
      const state = $ngRedux.getState<IPermissionsStoreState>();
      return hasPermissions(state, permission);
    };

    $rootScope.hasRole = function (role: string): boolean {
      return currentUserRepository.userHasRole(role);
    };

    $rootScope.featureAvailable = (name: EditionFeature): boolean => {
      return accountService.featureAvailable(name);
    };
  },
]);

mod.run([
  "AccountResolverService",
  (accountResolverService: AccountResolverService) => {
    currentAccountCache.get$().subscribe((account) => {
      if (account) {
        accountResolverService.setAccountData(account);
      }
    });
  },
]);

export default mod.name;
