<div
  class="ant-alert"
  *ngIf="!closed"
  [class.ant-alert-rtl]="dir === 'rtl'"
  [class.ant-alert-success]="nzType === 'success'"
  [class.ant-alert-info]="nzType === 'info'"
  [class.ant-alert-warning]="nzType === 'warning'"
  [class.ant-alert-error]="nzType === 'error'"
  [class.ant-alert-no-icon]="!nzShowIcon"
  [class.ant-alert-banner]="nzBanner"
  [class.ant-alert-closable]="nzCloseable"
  [class.ant-alert-with-description]="!!nzDescription">
  <ng-container *ngIf="nzShowIcon">
    <i class="ant-alert-icon" [uiType]="nzIconType || inferredIconType" [a11yTitle]="generateAlertIconAriaLabel()" [uiTheme]="iconTheme" role="img" ui-icon uiSize="lg"></i>
  </ng-container>
  <div class="ant-alert-content" *ngIf="nzMessage || nzDescription">
    <span class="ant-alert-message sp-py-2 sp-mb-0" *ngIf="nzMessage">
      <ng-container *nzStringTemplateOutlet="nzMessage">{{ nzMessage }}</ng-container>
    </span>
    <span class="ant-alert-description sp-pt-3 sp-pb-2" *ngIf="nzDescription">
      <p class="sp-m-0" *ngIf="uiIsLegacy" [innerHTML]="nzDescription"></p>
      <ng-container *ngIf="!uiIsLegacy">
        <ng-container *nzStringTemplateOutlet="nzDescription">{{ nzDescription }}</ng-container>
      </ng-container>
    </span>
    <ng-container *ngIf="nzType === 'error' && errorDetails?.description.length > 0">
      <ui-collapse>
        <ui-collapse-panel [uiIsExpandable]="true" [uiBorderless]="true" [collapsePanelHeader]="errorDetails.title && errorDetails.title !== '' ? errorDetails.title : 'Details'" uiSize="small">
          <p class="accordion-text">{{ errorDetails.description }}</p>
        </ui-collapse-panel>
      </ui-collapse>
    </ng-container>

    <ng-template #alertActionsTemplate>
      <div class="sp-pt-12 sp-pb-2">
        <button class="sp-mr-6" *ngIf="uiPrimaryCTA" [attr.data-test-id]="'alert-button-' + uiPrimaryCTA" (click)="uiOnPrimaryAction.emit()" ui-button uiShape="round" uiType="primary">
          {{ uiPrimaryCTA }}
        </button>
        <button class="sp-mr-6" *ngIf="uiSecondaryCTA" [attr.data-test-id]="'alert-button-' + uiSecondaryCTA" (click)="uiOnSecondaryAction.emit()" ui-button uiShape="round">
          {{ uiSecondaryCTA }}
        </button>
        <button *ngIf="uiTertiaryCTA" [attr.data-test-id]="'alert-button-' + uiTertiaryCTA" (click)="uiOnTertiaryAction.emit()" ui-button uiType="link" uiShape="round">{{ uiTertiaryCTA }}</button>
      </div>
    </ng-template>
    <ng-container *ngIf="uiPrimaryCTA || uiSecondaryCTA || uiTertiaryCTA">
      <ng-container *ngTemplateOutlet="alertActionsTemplate"></ng-container>
    </ng-container>
  </div>
  <button class="ant-alert-close-icon" *ngIf="nzCloseable || nzCloseText" [a11yTitle]="a11yCloseBtnTitle" (click)="closeAlert()" ui-button uiSize="default">
    <ng-template #closeDefaultTemplate>
      <i ui-icon uiType="close-big"></i>
    </ng-template>
    <ng-container *ngIf="nzCloseText; else closeDefaultTemplate">
      <ng-container *nzStringTemplateOutlet="nzCloseText">
        <span class="ant-alert-close-text">{{ nzCloseText }}</span>
      </ng-container>
    </ng-container>
  </button>
</div>
