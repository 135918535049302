import { AsyncPipe, KeyValuePipe, NgClass, NgFor, NgIf, NgStyle, NgTemplateOutlet } from "@angular/common";
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Injector,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
  forwardRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormControlDirective,
  FormControlName,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
  NgControl,
  ReactiveFormsModule,
} from "@angular/forms";
import { ThemeType } from "@ant-design/icons-angular";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzAutosizeDirective } from "ng-zorro-antd/input";
import { BehaviorSubject, Observable, noop } from "rxjs";
import { ExcludeFunctions, SimpleChangesTyped } from "@webapp/shared/models";
import { UiInputDirective } from "../../input.directive";
import { DisplayMaxCharacterCountMode, FormatedTextareaCountChange } from "../../input.models";
import { TextareaValidatorFactory, TextareaValidatorFn } from "../../utils/textarea-validator.factory";
import { UiTextareaCountComponent } from "../textarea-count/textarea-count.component";
import { UiValidationHintComponent } from "../validation-hint/validation-hint.component";

@UntilDestroy()
@Component({
  selector: "ui-input-text-area",
  exportAs: "uiInputTextArea",
  templateUrl: "./input-text-area.component.html",
  styleUrls: ["./input-text-area.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UiInputTextAreaComponent),
      multi: true,
    },
  ],
  standalone: true,
  imports: [
    NgIf,
    NgTemplateOutlet,
    UiTextareaCountComponent,
    UiInputDirective,
    ReactiveFormsModule,
    NgClass,
    AsyncPipe,
    UiValidationHintComponent,
    NgFor,
    UiIconModule,
    KeyValuePipe,
    NzAutosizeDirective,
    NgStyle,
  ],
})
export class UiInputTextAreaComponent implements OnInit, OnChanges, AfterViewInit, ControlValueAccessor {
  @Input() public uiId: string;
  @Input() public uiPlaceholder: string;
  @Input() public visibleRowCount = 3;
  @Input() public validationHint = "";
  @Input() @InputBoolean() public isValidationHintAccented = false;
  @Input() @InputBoolean() public showCharacterCount = false;
  @Input() @InputBoolean() public showMaxCharacterCount = false;
  @Input() @InputBoolean() public enforceCharactersLimit = false;
  @Input() @InputBoolean() public preventEmpty = false;
  @Input() public showCharacterCountMode: DisplayMaxCharacterCountMode = "always";
  @Input() public valueChangeEvent: string;
  @Input() public maxCharacterCount = 0;
  @Input() public maxCharacterCountErrorContent = "";
  @Input() public requiredErrorContent = "";
  @Input() @InputBoolean() public isDisabled = false;
  @Input() @InputBoolean() public isReadonly = false;
  @Input() @InputBoolean() public isRequired = false;
  @Input() @InputBoolean() public isBorderless = false;
  @Input() @InputBoolean() public isAutoresized = false;
  @Input() @InputBoolean() public isManualResizeEnabled = true;
  @Input() public ariaLabel = "";
  @Input() public e2eTestId = "";
  @Input() public focusMe = false;
  @Input() public uiIconType: string;
  @Input() public uiIconTheme: ThemeType;
  @Input() public textAreaClassName: string;
  @Input() public textAreaStyles: Record<string, string>;

  @Output() public readonly valueChange = new EventEmitter<string>();

  @ViewChild("textareaRef") public textareaRef: ElementRef<HTMLTextAreaElement>;

  public control: FormControl;

  constructor(
    private injector: Injector,
    private textareaValidatorFactory: TextareaValidatorFactory,
    private cdr: ChangeDetectorRef
  ) {}

  private maxCharacterCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  private formatedCharacterCountSubject: BehaviorSubject<FormatedTextareaCountChange> = new BehaviorSubject<FormatedTextareaCountChange>({
    value: "0",
    isMaxLengthExceeded: false,
    isMaxLengthReached: false,
  });
  public maxCharacterCount$: Observable<number> = this.maxCharacterCountSubject.asObservable();
  public formatedCharacterCount$: Observable<FormatedTextareaCountChange> = this.formatedCharacterCountSubject.asObservable();
  public isFocused: boolean;
  private validatorMap: Record<string, TextareaValidatorFn> = {};
  private originalInput: string;

  public ngOnInit(): void {
    this.setComponentControl();

    this.handleDisabledStateChange();
    this.handleRequiredStateChange();
    this.handleMaxCharacterCount();
  }

  public ngAfterViewInit(): void {
    if (this.focusMe) {
      this.focusTextarea();
    }
  }

  public ngOnChanges(changes: SimpleChangesTyped<UiInputTextAreaComponent>): void {
    if (this.hasInputValueChanged(changes, "isDisabled")) {
      this.handleDisabledStateChange();
    }

    if (this.hasInputValueChanged(changes, "isRequired")) {
      this.handleRequiredStateChange();
    }

    if (
      this.hasInputValueChanged(changes, "maxCharacterCount") ||
      this.hasInputValueChanged(changes, "showCharacterCount") ||
      this.hasInputValueChanged(changes, "showMaxCharacterCount") ||
      this.hasInputValueChanged(changes, "enforceCharactersLimit") ||
      this.hasInputValueChanged(changes, "showCharacterCountMode")
    ) {
      this.handleMaxCharacterCount();
    }

    if (this.hasInputValueChanged(changes, "focusMe") && changes.focusMe.currentValue) {
      this.focusTextarea();
    }
  }

  public onFormatedCountChange(formatedCount: FormatedTextareaCountChange): void {
    this.formatedCharacterCountSubject.next(formatedCount);
    this.cdr.detectChanges();
  }

  public writeValue(value: string): void {
    if (this.control?.value !== value) {
      this.control?.setValue(value);
    }
  }

  public registerOnChange(fn: (value: string | null) => string): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouch = fn;
  }

  public onFocus(): void {
    this.isFocused = true;
  }

  public onBlur(): void {
    this.isFocused = false;
    if (this.control.updateOn !== "blur" && this.valueChangeEvent === "blur") {
      window.setTimeoutOutsideAngular(() => {
        if (this.preventEmpty && !this.control.value.trim()) {
          this.control.setValue(this.originalInput);
        }

        if (this.control.value !== this.originalInput) {
          this.originalInput = this.control.value;
          this.valueChange.emit(this.control.value);
        }
        this.cdr.markForCheck();
      }, 20);
    }
  }
  public onChange = (value: string | null): string | null => value;

  public onTouch = noop;

  public applyStringWhenTrue(value: string, { condition }: { condition: boolean }): string {
    return condition ? value : "";
  }

  public get controlInvalid(): boolean {
    // touched will be added after we refactor forms
    return this.control.invalid && this.control.dirty;
  }

  private setComponentControl(): void {
    const formControl = this.injector.get(NgControl);
    if (formControl instanceof FormControlDirective) {
      this.control = formControl.control;
    } else if (formControl instanceof FormControlName) {
      const formGroup = this.injector.get(FormGroupDirective);
      if (formGroup) {
        this.control = formGroup.getControl(formControl);
      }
    }

    if (!this.control) {
      console.warn(`Unable to set control for ${UiInputTextAreaComponent.name}`);
      return;
    }

    this.originalInput = this.control?.value;

    // this is a workaround because nzAutosize doesn't calculate the size of the textarea correctly
    if (this.isAutoresized) {
      window.setTimeoutOutsideAngular(() => {
        const originalValue = this.control.value;
        this.control.setValue(originalValue + " ");
        this.control.setValue(originalValue);
      }, 10);
    }

    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe((newValue) => {
      if (this.valueChangeEvent !== "blur") {
        this.valueChange.emit(newValue);
      }
    });
  }

  private calculateMaxCharacterCount(): void {
    if (!this.showCharacterCount || !this.showMaxCharacterCount) {
      this.maxCharacterCountSubject.next(0);
      this.cdr.detectChanges();
      return;
    }

    if (!this.maxCharacterCount) {
      this.maxCharacterCountSubject.next(0);
      this.cdr.detectChanges();
      return;
    }

    this.maxCharacterCountSubject.next(this.maxCharacterCount);
    this.cdr.detectChanges();
  }

  private handleMaxCharacterCount(): void {
    if (!this.control) return;

    if (this.maxCharacterCount && typeof this.maxCharacterCount === "number") {
      this.calculateMaxCharacterCount();
      this.enrichFieldWithMaxCountValidator();
      return;
    }

    this.removeMaxCountValidator();
  }

  private handleRequiredStateChange(): void {
    if (!this.control) return;

    if (this.isRequired) {
      this.enrichFieldWithRequiredValidator();
      return;
    }

    this.removeRequiredValidator();
  }

  private handleDisabledStateChange(): void {
    if (!this.control) return;

    if (this.isDisabled) {
      this.disableControl();
      return;
    }

    this.enableControl();
  }

  private disableControl(): void {
    this.control.disable();
  }

  private enableControl(): void {
    this.control.enable();
  }

  private enrichFieldWithRequiredValidator(): void {
    if (this.validatorMap.required) {
      this.removeRequiredValidator();
    }

    this.validatorMap.required = this.textareaValidatorFactory.requiredValidator(this.requiredErrorContent);

    this.control.addValidators(this.validatorMap.required);
    this.control.updateValueAndValidity();
  }

  private removeRequiredValidator(): void {
    if (!this.validatorMap.required) return;

    this.control.removeValidators(this.validatorMap.required);
    this.control.updateValueAndValidity();
  }

  private enrichFieldWithMaxCountValidator(): void {
    if (this.validatorMap.maxLength) {
      this.removeMaxCountValidator();
    }

    this.validatorMap.maxLength = this.textareaValidatorFactory.maxLengthValidator(this.maxCharacterCountErrorContent, {
      maxLength:
        this.enforceCharactersLimit && typeof this.maxCharacterCount === "number" && this.maxCharacterCount > 1 ? this.maxCharacterCount - 1 : this.maxCharacterCount,
    });

    this.control.addValidators(this.validatorMap.maxLength);
    this.control.updateValueAndValidity();
  }

  private removeMaxCountValidator(): void {
    if (!this.validatorMap.maxLength) return;

    this.control.removeValidators(this.validatorMap.maxLength);
    this.control.updateValueAndValidity();
  }

  private hasInputValueChanged(changes: SimpleChangesTyped<UiInputTextAreaComponent>, prop: keyof ExcludeFunctions<UiInputTextAreaComponent>): boolean {
    return changes[prop] && !changes[prop].firstChange;
  }

  private focusTextarea(): void {
    setTimeout(() => {
      this.textareaRef.nativeElement.focus();
    }, 0);
  }
}
