import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { AccountMarketingInfoFormComponent } from "./components/account-marketing-info-form/account-marketing-info-form.component";
import { AccountMarketingInfoScreenComponent } from "./components/account-marketing-info-screen/account-marketing-info-screen.component";
import { AccountPickerComponent } from "./components/account-picker/account-picker.component";
import { AccountServiceProvider } from "./providers/account.provider";

@NgModule({
  declarations: [AccountPickerComponent, AccountMarketingInfoFormComponent, AccountMarketingInfoScreenComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiFormModule,
    UiGridModule,
    UiButtonModule,
    UiSelectModule,
    UiInputModule,
    LocalizationModule,
    IndicatorModule,
    UiAlertModule,
    UiIconModule,
  ],
  providers: [AccountServiceProvider],
  exports: [AccountPickerComponent, AccountMarketingInfoFormComponent, AccountMarketingInfoScreenComponent],
})
export class AccountsModule {}
