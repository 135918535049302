import { Directive, Input, OnChanges } from "@angular/core";
import { UiChipComponent } from "@quantive/ui-kit/chip";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { Assignee, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { isUnknownAssignee } from "@webapp/assignees/utils/assignee.utils";

const DEFAULT_AVATAR_ICON = "travel_world";
const DEFAULT_BG_COLOR = "#87a6bc";

@Directive({
  selector: "ui-chip[chipAssignee]",
  standalone: true,
})
export class ChipAssigneeDirective implements OnChanges {
  @Input()
  public chipAssignee: Assignee | UnknownAssignee;

  constructor(private chip: UiChipComponent) {}

  public ngOnChanges(changes: SimpleChangesOf<UiChipComponent>): void {
    if (isUnknownAssignee(this.chipAssignee)) {
      this.chip.uiIconFull = true;
      this.chip.uiIconTheme = "outline";
      this.chip.uiPicture = null;
      this.chip.uiIconType = this.chipAssignee.avatar;
      this.chip.uiIconBgColor = this.chipAssignee.color;
      this.chip.uiDisabled = true;
    } else {
      const hasPicture = !!this.chipAssignee?.picture;
      this.chip.uiIconFull = hasPicture;
      this.chip.uiIconTheme = "fill";
      this.chip.uiPicture = this.chipAssignee?.picture;
      this.chip.uiIconType = hasPicture ? null : (this.chipAssignee?.type === "team" && this.chipAssignee?.avatar) || DEFAULT_AVATAR_ICON;
      this.chip.uiIconBgColor = hasPicture ? null : (this.chipAssignee?.type === "team" && this.chipAssignee?.color) || DEFAULT_BG_COLOR;
      this.chip.uiDisabled = this.chipAssignee?.isActive === false;
    }
    this.chip.ngOnChanges(changes);
  }
}
