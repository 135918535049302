import { IComponentOptions } from "angular";
import { GtmhubController } from "@gtmhub/core";
import { localize } from "@gtmhub/localization";
import { Task } from "@webapp/tasks/models/tasks.models";
import { GtmhubTaskStatus, statusOfTask } from "../utils/tasks-status";

interface ITaskStatusBindings {
  task?: Task;
  tabIndexStart?: number;
  readonly?: boolean;
  disabled?: boolean;
  onChange?(arg: { value: GtmhubTaskStatus }): void;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
interface TaskStatusCtrl extends ITaskStatusBindings {}
class TaskStatusCtrl extends GtmhubController {
  statuses = GtmhubTaskStatus;

  buttonText(): string {
    return this.task.archived ? localize("archived") : statusOfTask(this.task);
  }

  statusChanged(value: GtmhubTaskStatus): void {
    if (this.onChange) {
      this.onChange({ value });
    }
  }

  tabIndex(offset: number): number {
    if (typeof this.tabIndexStart !== "undefined") {
      return this.tabIndexStart + offset;
    } else {
      return 0;
    }
  }

  toggleButtonClass(): string {
    if (this.task && this.task.status && !this.task.archived) {
      return this.task.status.toLowerCase().replace(" ", "");
    } else {
      return "";
    }
  }
}

export const TaskStatusComponent: IComponentOptions = {
  template: require("./task-status.html"),
  controller: TaskStatusCtrl,
  bindings: {
    task: "<",
    tabIndexStart: "<?",
    readonly: "<?",
    disabled: "<?",
    onChange: "&",
  },
};
