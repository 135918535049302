import { module } from "angular";
import { OkrMethodologyFacade } from "./components/facades/okr-methodology.facade";
import { GoalService } from "./services/goal.service";

const mod = module("goalsCore", []);

mod.service("GoalService", GoalService);
mod.service("OkrMethodologyFacade", OkrMethodologyFacade);

export default mod.name;
