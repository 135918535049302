import { HttpClient, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { RequestPaging } from "@webapp/core/abstracts/models/request.paging";
import { HttpEncoderService } from "@webapp/core/abstracts/services/http-encoder.service";
import { IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { ICollection } from "@webapp/core/core.models";
import { ApiVersionOption } from "../abstracts/enums/api-versions.enum";

export abstract class BaseApiServiceV2<Model, RP = RequestPaging> {
  constructor(
    protected entityPath: string,
    protected apiVersion: ApiVersionModel,
    protected httpClient: HttpClient,
    protected appConfig: IAppConfig
  ) {}

  public getAll$<ResponseType = ICollection<Model>>(filters?: RP, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || this.getBasePath(HttpActions.getAll);

    return this.httpClient.get<ResponseType>(url, {
      params: this.encodeQueryParams(config, filters),
      headers: config.headers,
      context: config.context,
    });
  }

  public get$<ResponseType = Model>(id: number | string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || `${this.getBasePath(HttpActions.get)}/${id}`;

    return this.httpClient.get<ResponseType>(url, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }

  public post$<ResponseType = Model>(model: Partial<Model> | string[], config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || this.getBasePath(HttpActions.post);

    return this.httpClient.post<ResponseType>(url, model, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }

  public put$<ResponseType = Model>(id: number | string, model: Partial<Model>, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || `${this.getBasePath(HttpActions.put)}/${id}`;

    return this.httpClient.put<ResponseType>(
      url,
      {
        ...model,
      },
      {
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      }
    );
  }

  public patch$<ResponseType = Model>(id: number | string, model: Partial<Model>, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || `${this.getBasePath(HttpActions.patch)}/${id}`;

    return this.httpClient.patch<ResponseType>(
      url,
      {
        ...model,
      },
      {
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      }
    );
  }

  public delete$<ResponseType = Model>(id: number | string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || `${this.getBasePath(HttpActions.delete)}/${id}`;

    return this.httpClient.delete<ResponseType>(url, {
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
      body: config.body,
    });
  }

  public deleteMany$<ResponseType = Model>(ids: string[], config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config?.url || this.getBasePath(HttpActions.delete);

    return this.httpClient.delete<ResponseType>(url, {
      body: ids,
      params: this.encodeQueryParams(config),
      headers: config.headers,
      context: config.context,
    });
  }

  public getBasePath(httpAction: HttpActions, params: { apiVersionOverwrite?: ApiVersionOption } = {}): string {
    const protocolAndScheme = this.appConfig.endpoints.secure ? "https://" : "http://";
    const apiVersionOption = params.apiVersionOverwrite || this.apiVersion[httpAction];
    const apiVersion = typeof apiVersionOption === "object" ? apiVersionOption.version : apiVersionOption;
    const trailingSlash = typeof apiVersionOption === "object" && apiVersionOption.trailingSlash ? "/" : "";

    return `${protocolAndScheme}${this.appConfig.endpoints.apiEndpoint}/${apiVersion}/${this.entityPath}${trailingSlash}`;
  }

  protected encodeQueryParams(config: RequestConfig, filters?: RP): HttpParams {
    const queryParams = { ...config.queryParams };

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (key === "filter" || typeof value === "boolean") {
          queryParams[key] = `${JSON.stringify(value)}`;
          continue;
        }

        if (key === "fields" || key === "sort") {
          queryParams[key] = `${filters[key].join(",")}`;
          continue;
        }

        if (typeof value === "number") {
          queryParams[key] = value.toFixed(0);
        }
      }
    }

    return new HttpParams({
      fromObject: queryParams,
      encoder: new HttpEncoderService(),
    });
  }
}
