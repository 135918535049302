import { IHttpResponse, IPromise, IQService, IRootScopeService, IWindowService, jwt } from "angular";
import { IModalStackService } from "angular-ui-bootstrap";
import { Ng1StateDeclaration } from "@uirouter/angularjs";
import Cookies from "js-cookie";
import {
  IAppConfig,
  getPathPrefix,
  isCurrentDomainGtmhub,
  isCurrentDomainQuantive,
  isQuantiveDomain,
  stripProtocolFromURL,
  switchToQuantiveDomain,
  toQuantiveDomain,
} from "@gtmhub/env";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { isMsTeamsApp } from "@webapp/shared/utils/msteams";
import { IProfile } from "@webapp/user-profile/models/user-profile.models";
import { CurrentUserRepository } from "@webapp/users";
import { AuthorizationService } from "./authorization-service";
import { AuthProvider, IAuthMultipleDomainsResponse, IAuthResponse, IGtmhubJwtToken } from "./models";
import auth0 from "./providers/auth0.provider";

export const getAccessToken = (): string => localStorage.getItem("accessToken");
const getIdToken = (): string => (localStorage.getItem("token") ? JSON.parse(localStorage.getItem("token")) : null);
export const getToken = (): string => getAccessToken() || getIdToken();

const fullName = (token: IGtmhubJwtToken): string => [token.given_name, token.last_name].filter((x) => !!x).join(" ");

export class AuthenticationResolverService {
  public static $inject = [
    "$rootScope",
    "$window",
    "$uibModalStack",
    "$q",
    "jwtHelper",
    "appConfig",
    "AuthorizationService",
    "AnalyticsService",
    "CurrentUserRepository",
    "FeatureTogglesFacade",
    "AccountResolverService",
    "AuthProvider",
  ];

  private isLoadingToken = false;

  public get isAuthDataPresent(): boolean {
    return this.authProvider.hasClientOptions();
  }

  constructor(
    private $rootScope: IRootScopeService,
    private $window: IWindowService,
    private $uibModalStack: IModalStackService,
    private $q: IQService,
    private jwtHelper: jwt.IJwtHelper,
    private appConfig: IAppConfig,
    private authorizationService: AuthorizationService,
    private analyticsService: AnalyticsService,
    private currentUserRepository: CurrentUserRepository,
    private featureToggleService: FeatureTogglesFacade,
    private accountResolverService: AccountResolverService,
    private authProvider: AuthProvider
  ) {}

  public refreshToken({ blocking }: { blocking: boolean }): IPromise<string> {
    if (this.isLoadingToken && blocking === false) {
      return this.$q.resolve(getToken());
    }

    this.isLoadingToken = true;
    return this.$q.when(this.authProvider.refreshTokenSilently()).finally(() => (this.isLoadingToken = false));
  }

  public getAuthUserId(): string {
    return this.authProvider.getUserId();
  }

  public setAuthUserId(id: string): void {
    this.authProvider.setUserId(id);
  }

  public getUserDisplayNameFromIdToken(): string {
    const idToken = getIdToken();
    if (!idToken) {
      return null;
    }

    try {
      const token: IGtmhubJwtToken = this.jwtHelper.decodeToken(idToken);
      return token && (token.name || fullName(token) || token.username || token.nickname || token.email);
    } catch (err) {
      return null;
    }
  }

  // todo: this must be refactored since the current state is awful. This service does not depend on any of the services that are owners
  // of the store values but at the same time it clears them to ensure that no stale data is left out when you logout.
  // a possible solution is to have some dependency registration mechanism - all services that need to clean data on logout should register
  // this way the authenticationResolverService is not bogged down with billion dependencies as it is now
  public clear(): void {
    localStorage.removeItem("token");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("collapsedEntities");
    localStorage.removeItem("okrs-list-state");

    this.authProvider.clearData();
    this.clearCookiesOnLogout();
  }

  private clearCookiesOnLogout(): void {
    const { host } = window.location;

    this.currentUserRepository.removeMarketplaceRedirectCookie();
    Cookies.remove("auth0UserId", { domain: host });
    Cookies.remove("quantiveAccountDomain");
  }

  public navigateToLogin(user?: IProfile, { isResolved }: { isResolved?: boolean } = { isResolved: true }): IPromise<void> {
    const { host } = window.location;
    const email = user ? user.email : undefined;
    const webappQuantiveEndpoint = toQuantiveDomain(this.appConfig.endpoints.webappEndpoint);

    if (!email && ([this.appConfig.endpoints.webappEndpoint, webappQuantiveEndpoint].includes(host) || this.appConfig.env.bypassDomainCheck)) {
      return this.$q.resolve().then(() => this.redirect(this.loginUrl()));
    }

    return this.isSsoLogoutEnabled({ hasState: isResolved })
      .then((ssoEnabled) => this.redirectToLoginPage({ email, ssoEnabled }))
      .catch(() => {
        this.redirectToLoginPage({ email: email, ssoEnabled: false });
      });
  }

  private redirectToLoginPage(args: { email?: string; ssoEnabled: boolean }): IPromise<void> {
    const currentGtmhubDomain = this.currentDomain();

    const makeRequest = (data: { email?: string; domain?: string }) =>
      this.authorizationService.getAuthData(data).then(
        (response) => {
          return this.resolveUrlOnAuthDataSuccess(response, { email: args.email, currentGtmhubDomain: currentGtmhubDomain, ssoEnabled: args.ssoEnabled });
        },
        (response: IHttpResponse<IAuthMultipleDomainsResponse>) => {
          const userHasMultipleDomains = response.status === 409;
          if (userHasMultipleDomains) {
            // Try to resolve the current Gtmhub domain from the list of domains
            // and if it exists there, make a second request to get the proper details
            // for the redirect URL
            const multipleDomainsResponse = response;
            const isCurrentGtmhubDomainInList = multipleDomainsResponse.data.domains.some((domain) => domain === currentGtmhubDomain);
            if (isCurrentGtmhubDomainInList) {
              return makeRequest({ ...data, domain: currentGtmhubDomain });
            }

            // just redirect to the current domain,
            // the login page will fix it if necessary

            let redirectURL = `${currentGtmhubDomain}/login`;
            const searchParams = this.buildLoginUrlSearchParams({ email: args.email, returnPath: window.location.hash.substring(1) });
            if (searchParams) {
              redirectURL += `?${searchParams}`;
            }
            this.redirect(redirectURL);
          } else {
            // in case of any error, we go to the general login page
            this.redirect(this.loginUrl(args.email));
          }
        }
      );

    return makeRequest(args.email ? { email: args.email } : { domain: currentGtmhubDomain });
  }

  private stateRequiresLogin(state: Ng1StateDeclaration): boolean {
    return state && state.data && state.data.requiresLogin;
  }

  public demandsAreMet(toState: Ng1StateDeclaration): IPromise<boolean> {
    if (!this.stateRequiresLogin(toState)) {
      return this.$q.resolve(true);
    }

    if (this.isAuthDataPresent) {
      return this.$q.when(this.authProvider.isUserAuthenticated());
    }

    const token = getToken();
    const auth0UserId = this.getAuthUserId();
    const isAuthenticationDataValid = !!token && !this.jwtHelper.isTokenExpired(token);
    const isAuthenticationDataPresent = !!token && !!auth0UserId;

    return this.$q.resolve(isAuthenticationDataPresent && isAuthenticationDataValid);
  }

  public signout(): void {
    this.clear();
    this.$rootScope.$broadcast("userSignedOut");
  }

  public logout(meta: { ssoLogoutIfEnabled: boolean; returnUrl?: string }): IPromise<void> {
    this.analyticsService.track("Signed Out");
    this.analyticsService.reset();

    let presentAccountState = true;
    const account = this.accountResolverService.getAccountData();
    if (!account) {
      presentAccountState = false;
    }

    return this.isSsoLogoutEnabled({ hasState: meta.ssoLogoutIfEnabled && presentAccountState })
      .then((fflagEnabled) => this.logoutBasedOnFederatedFlag({ isLogoutFederated: meta.ssoLogoutIfEnabled && fflagEnabled, returnUrl: meta.returnUrl }))
      .catch(() => this.logoutBasedOnFederatedFlag({ isLogoutFederated: false, returnUrl: meta.returnUrl }));
  }

  private logoutBasedOnFederatedFlag(options: { isLogoutFederated: boolean; returnUrl: string }): IPromise<void> {
    // do not append `/results` twice as a prefix if the returnUrl already has it
    const pathPrefix = options.returnUrl?.includes("/results") ? "" : getPathPrefix();
    const returnTo = `${this.currentDomain()}${pathPrefix}${options.returnUrl || "/logout-success"}`;
    this.signout();

    return this.$q.when(this.authProvider.logout({ federated: options.isLogoutFederated, returnTo }));
  }

  private isSsoLogoutEnabled({ hasState }: { hasState: boolean }): IPromise<boolean> {
    if (!hasState) {
      return this.$q.resolve(false);
    }

    if (!this.featureToggleService.isInitialized()) {
      return this.$q.reject("LaunchDarkly is not initialized");
    }

    return this.$q.when(this.featureToggleService.isFeatureAvailable("automatic-sso-provider-logout"));
  }

  private resolveUrlOnAuthDataSuccess(
    response: IHttpResponse<IAuthResponse>,
    options: { email: string; currentGtmhubDomain: string; ssoEnabled: boolean }
  ): IPromise<void> {
    if (this.authProvider !== auth0) {
      throw new Error("Only Auth0 supports SSO or navigate to login");
    }

    const auth = response.data;
    // if coming from ms-teams - the cookie should contain "SameSite=None" due to chrome security concern
    const isMsTeamsState = isMsTeamsApp();

    // If Orbit wants us to continue on the quantive domain while we are on the gtmhub.com domain,
    // then we need to switch domains before we set any cookies
    // Note: we won't do redirects with inside MS Teams as we might experience issues with it
    if (isQuantiveDomain(auth.gtmhubDomain) && isCurrentDomainGtmhub() && !isMsTeamsState) {
      // Remove the '#' from the current hash path as in the new quantive domain we don't have a hash
      switchToQuantiveDomain(options.email, stripProtocolFromURL(auth.gtmhubDomain), window.location.hash.substring(1));
      return;
    }

    if (options.ssoEnabled) {
      this.redirect(auth0.buildSsoLogoutAndLoginUrl(auth, options.currentGtmhubDomain));
      return this.$q.resolve();
    }

    if (auth.connections && auth.connections.length === 1) {
      return this.$q.when(
        auth0.navigateToAuth0Login(auth, options.email, {
          appState: { returnPath: response.data.returnPath },
          isMsTeamsState,
          bypassDomainCheck: this.appConfig.env.bypassDomainCheck,
          connection: auth.connections[0],
        })
      );
    } else if (auth.connections && auth.connections.length > 1) {
      this.redirect(`${options.currentGtmhubDomain}${getPathPrefix()}/login?domain=${options.currentGtmhubDomain}`);
      return this.$q.resolve();
    }

    return this.$q.when(
      auth0.navigateToAuth0Login(auth, options.email, {
        appState: { returnPath: response.data.returnPath },
        isMsTeamsState,
        bypassDomainCheck: this.appConfig.env.bypassDomainCheck,
      })
    );
  }

  private redirect(url: string): void {
    this.$uibModalStack.dismissAll();
    this.$window.location.href = url;
  }

  private currentDomain(): string {
    const { protocol, host } = window.location;
    return `${protocol}//${host}`;
  }

  public logoutToWebappLogoutUrl(): void {
    this.analyticsService.track("Signed Out");
    this.analyticsService.reset();

    this.$uibModalStack.dismissAll();
    this.signout();

    const { protocol } = location;
    const webappQuantiveEndpoint = toQuantiveDomain(this.appConfig.endpoints.webappEndpoint);
    const webappEndpoint = isCurrentDomainQuantive() ? `${webappQuantiveEndpoint}/results` : this.appConfig.endpoints.webappEndpoint;
    this.$window.location.href = `${protocol}//${webappEndpoint}/logout`;
  }

  private loginUrl(email?: string): string {
    const currentHashPath = window.location.hash.substring(1);
    const searchParams = this.buildLoginUrlSearchParams({ email: email, returnPath: currentHashPath });

    let result = `${this.currentDomain()}${getPathPrefix()}/login`;
    if (searchParams) {
      result += `?${searchParams}`;
    }

    return result;
  }

  private buildLoginUrlSearchParams(opts: { email?: string; domain?: string; returnPath?: string }): string {
    return new URLSearchParams({
      ...(opts.email && { email: opts.email }),
      ...(opts.domain && { domain: opts.domain }),
      ...(opts.returnPath && { returnPath: opts.returnPath }),
    }).toString();
  }
}
