import { IAngularEvent, IPromise, IQService } from "angular";
import { IModalStackService } from "angular-ui-bootstrap";
import { Ng1StateDeclaration, StateService, TargetState } from "@uirouter/angularjs";
import Cookies from "js-cookie";
import { firstValueFrom } from "rxjs";
import { IAccount, IEdition } from "@gtmhub/core";
import { storage } from "@gtmhub/core/storage";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { CurrentAccountRepository } from "@webapp/accounts/services/current-account-repository.service";
import dayjs from "@webapp/shared/libs/dayjs";
import { ISubscriptionConverted } from "../login/models";
import { IGtmhubRootScopeService } from "../models";

export class AccountResolverService {
  private initPromise: IPromise<void>;

  public static $inject = ["$rootScope", "$q", "$state", "$uibModalStack", "CurrentAccountRepository"];

  private static featureMappings = {
    slack: "gtmhub.configuration.slack",
  };

  private account: IAccount;

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $q: IQService,
    private $state: StateService,
    private $uibModalStack: IModalStackService,
    private currentAccountRepository: CurrentAccountRepository
  ) {
    this.$rootScope.$on("subscriptionConverted", this.subscriptionConverted);
  }

  public getAccountName(): string {
    return storage.get("accountName");
  }

  private setAccountName(accountName: string): void {
    storage.set("accountName", accountName);
  }

  private setEdition(edition: IEdition): void {
    this.$rootScope.edition = edition;
    // used by marketplace
    storage.set("edition", edition);
  }

  private setAccountSettings(accountSettings: string): void {
    // Account settings come stringified and the set method stringifies them for second time
    // Bear that in mind if changing the storage methods logic
    // If improved, please add a task for changing the current double parsing in the marketplace
    storage.set("accountSettings", accountSettings);
  }

  public getAccountId(): string {
    return storage.get("accountId");
  }

  public setAccountId(accountId: string): void {
    storage.set("accountId", accountId);
  }

  public getFeatureStateName(): string | undefined {
    const featureKey = storage.get<string>("feature");
    if (featureKey) {
      return AccountResolverService.featureMappings[featureKey];
    }
  }

  public clear(): void {
    storage.remove("accountName");
    storage.remove("accountSettings");
    storage.remove("accountId");
  }

  public prepareForResolveAccount(): TargetState {
    this.$uibModalStack.dismissAll();

    return this.$state.target("accountBootstrap.resolveAccount");
  }

  public shouldShowAccountDetailsForm(): Promise<boolean> {
    const isUserAccountOwner = (accountData: IAccount): boolean => {
      const accountOwnerId = accountData.ownerId,
        userId = getCurrentUserId();

      return accountOwnerId === userId;
    };

    return new Promise((resolve) => {
      const accountData: IAccount | undefined = this.getAccountData();

      if (!accountData) {
        // dont try to fetch account data if we dont have account id in LS
        if (!this.getAccountId()) return resolve(false);
        this.initAccount().then(() => {
          const account: IAccount = this.getAccountData(),
            isUserAccOwner = isUserAccountOwner(account);

          if (!isUserAccOwner)
            resolve(false); // only account owner can add account details
          else resolve(!account.accountMarketingInfo);
        });
      } else {
        const isUserAccOwner = isUserAccountOwner(accountData);

        if (!isUserAccOwner)
          resolve(false); // only account owner can add account details
        else resolve(!accountData.accountMarketingInfo);
      }
    });
  }

  public demandsAreMet(toState: Ng1StateDeclaration): boolean {
    const stateRequiresAccount: boolean = toState && toState.data && toState.data.requiresAccount;
    const accountId = this.getAccountId();
    const accountName = this.getAccountName(); // used to assign a variable in TopMenuCtrl then unused -> can be removed
    return !stateRequiresAccount || (accountId && !!accountName);
  }

  public initAccount(): IPromise<void> {
    const accountData = this.getAccountData();
    if (accountData) {
      this.$rootScope.$broadcast("accountDataInitialized", accountData);
      return this.$q.resolve();
    }

    if (this.initPromise == null) {
      this.initPromise = this.$q.when(firstValueFrom(this.currentAccountRepository.get$())).then((account) => {
        Cookies.set("quantiveAccountDomain", account.domain, { expires: 30, sameSite: "None", secure: true });
        this.$rootScope.$broadcast("accountDataInitialized", account);
        delete this.initPromise;
      });
    }
    return this.initPromise;
  }

  public setAccountData(account: IAccount): void {
    if (account.id !== "") {
      this.setAccountId(account.id);
      this.setAccountSettings(account.settings);
      this.setEdition(account.edition);
      this.setAccountName(account.name);
      this.setAccount(account);
    }
  }

  public getAccountData(): IAccount {
    return this.account;
  }

  public getTimezone(): string {
    return this.account?.notifications?.reportOptions?.okrUpdate?.timezone || "UTC+0";
  }

  private setAccount(account: IAccount): void {
    this.account = account;
  }

  public checkExpirationOfTrialAccount(): { secondsLeft: number; timeLeft: string; percentageTimeLeft: number } {
    const account: IAccount = this.getAccountData();

    const secondsLeft = dayjs(account.trialEnds).diff(dayjs(), "seconds");
    const trialLength = dayjs(account.trialEnds).diff(account.dateCreated, "seconds");
    const percentageTimeLeft = (dayjs().diff(account.dateCreated, "seconds") * 100) / trialLength;
    let timeLeft: string = null;

    // hours converted to seconds
    const twentyFourHours = 86400;
    const fortyEightHours = 172800;
    const twoHours = 7200;
    const oneHour = 3600;

    if (secondsLeft > twentyFourHours && secondsLeft < fortyEightHours) {
      timeLeft = `1 ${localize("day")}`;
    }

    if (secondsLeft > fortyEightHours) {
      timeLeft = `${Math.ceil(secondsLeft / twentyFourHours)} ${localize("days")}`;
    }

    if (secondsLeft < twoHours && secondsLeft > oneHour) {
      timeLeft = `1 ${localize("hour")}`;
    }

    if (secondsLeft < twentyFourHours && secondsLeft > twoHours) {
      timeLeft = `${Math.ceil(secondsLeft / oneHour)} ${localize("hours")}`;
    }

    if (secondsLeft < oneHour) {
      timeLeft = `${Math.ceil(secondsLeft / 60)} ${localize("minutes")}`;
    }

    return {
      secondsLeft,
      timeLeft,
      percentageTimeLeft,
    };
  }

  private subscriptionConverted = (event: IAngularEvent, subscriptionConverted: ISubscriptionConverted): void => {
    const accountId = this.getAccountId();

    if (accountId === subscriptionConverted.accountId) {
      const accountData = { ...this.getAccountData() };

      if (accountData.subscriptions.length) {
        const subscriptionTypeRegular = subscriptionConverted.subscriptions.find((license) => license.type === "regular");

        accountData.subscriptions = accountData.subscriptions.map((license) => {
          if (license.type === "regular") {
            license.isConverted = subscriptionTypeRegular.isConverted;
          }

          return license;
        });
      } else {
        accountData.subscriptions = subscriptionConverted.subscriptions;
      }

      this.$rootScope.edition = subscriptionConverted.accountEdition;
      accountData.edition = subscriptionConverted.accountEdition;
      this.setAccount(accountData);
    }
  };
}
