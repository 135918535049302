import { IComponentOptions, IOnChangesObjectOf, IScope, ITimeoutService } from "angular";
import { combineLatest, take } from "rxjs";
import { GtmhubController } from "@gtmhub/core";
import { untilScopeDestroyed } from "@gtmhub/core/rxjs";
import { IUIError } from "@gtmhub/error-handling";
import { IRole, IRolesStoreState, RolesActions, filterOutTeamRoles } from "@gtmhub/roles";
import { INgRedux } from "@gtmhub/state-management";
import { toKeyMap } from "@gtmhub/util";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";

const VIEW_ONLY = "view-only";
const USER = "user";
const RESTRICTED = "restricted";
const REGULAR = "regular";

interface IRolesSelectorComponentRedux {
  roles: IRole[];
  rolesFetched: boolean;
  rolesError: IUIError;
}

const bindStateToCtrl = (state: IRolesStoreState): IRolesSelectorComponentRedux => {
  return {
    roles: filterOutTeamRoles(state),
    rolesFetched: state.roles.isFetching,
    rolesError: state.roles.error,
  };
};

export interface IRolesBindings {
  ids: string[];
  accountLicense: string;
  onUpdate(ids: { ids: string[]; subscriptionType?: string }): void;
}

export interface RolesSelectorCtrl extends IRolesBindings, IRolesSelectorComponentRedux {}
export class RolesSelectorCtrl extends GtmhubController {
  // bindings
  ids: string[];
  accountLicense: string;
  subscriptionType: string;
  allowChangeUserRole: boolean;
  hideSuggestionList: boolean;
  selectedRoles: IRole[];
  nameRoleMap: Record<string, IRole>;
  static $inject = ["$timeout", "$ngRedux", "RolesActions", "$scope", "FeatureTogglesFacade", "PermissionsFacade"];

  constructor(
    private $timeout: ITimeoutService,
    private $ngRedux: INgRedux,
    private rolesActions: RolesActions,
    private $scope: IScope,
    private featureTogglesFacade: FeatureTogglesFacade,
    private permissionsFacade: PermissionsFacade
  ) {
    super();
    this.onDestroy($ngRedux.connect(bindStateToCtrl)(this));
  }

  $onInit(): void {
    this.$ngRedux.dispatch(this.rolesActions.getRolesIfMissing());
    this.nameRoleMap = toKeyMap("name", this.roles);
    this.setDefaultRole();

    combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      this.permissionsFacade.hasPermission$("users:edit_roles"),
      this.permissionsFacade.hasPermission$("ManageUsers"),
      this.permissionsFacade.hasPermission$("ManageConfiguration"),
    ])
      .pipe(take(1), untilScopeDestroyed(this.$scope))
      .subscribe({
        next: ([isUsersGranularAccessEnabled, hasUsersEditRoles, hasManageUsers, hasManageConfiguration]) => {
          if (isUsersGranularAccessEnabled) {
            this.allowChangeUserRole = hasUsersEditRoles;
          } else {
            this.allowChangeUserRole = hasManageUsers && hasManageConfiguration;
          }
        },
      });
  }

  $onChanges(onChangesObj: IOnChangesObjectOf<IRolesBindings>): void {
    if (onChangesObj.ids) {
      this.setSelectedRoles();
    }
  }

  searchRole(query: string): IRole[] {
    return query ? [...new Set(this.roles.filter((role) => role.name.includes(query)))] : this.roles;
  }

  addRole(tagId: string): void {
    const viewOnlyRoleId = this.nameRoleMap[VIEW_ONLY].id;

    if (tagId === viewOnlyRoleId) {
      this.ids = [tagId];
      this.hideSuggestionList = true;

      this.subscriptionType = this.accountLicense === REGULAR ? RESTRICTED : this.accountLicense;

      const tagsInputEl: HTMLInputElement = document.querySelector(".roles-input-container input.input");
      // We want to hide the suggestionList of the autocomplete directive once we choose the 'view-only' role
      // we rely on suggestionList.visible (which is internal for the directive) to be true AND hideSuggestionList (which is a binding) to be false
      // in order to see the suggestionList, that's why first we set it to true which removes the suggestionList from the DOM
      // then we use the first timeout to blur the input field which in return closes the suggestionList
      // and then we call the second timeout in order to set the hideSuggestionList to false which returns it to the DOM
      // The nested timeout is necessary because the blur() method as every other DOM operation is async
      // therefore the reassignment of hideSuggestionList happens first which appears as blinking
      this.$timeout(() => {
        tagsInputEl.blur();
        this.$timeout(() => {
          this.hideSuggestionList = false;
        });
      }, 120);
    } else {
      const newSetOfIds = new Set(this.ids);
      newSetOfIds.add(tagId);
      this.ids = [...newSetOfIds].filter((id) => id !== viewOnlyRoleId);
    }
    this.onUpdate({ ids: this.ids, subscriptionType: this.subscriptionType });
  }

  removeRole(tagId: string): void {
    this.ids = this.ids.filter((id) => id !== tagId);
    this.onUpdate({ ids: this.ids });
  }

  checkIfNotSingleTag(): boolean {
    return this.ids.length > 1;
  }

  private setSelectedRoles(): void {
    this.selectedRoles = this.roles.filter((role) => this.ids.includes(role.id));
  }

  private setDefaultRole(): void {
    if (!this.ids.length) {
      this.ids = this.accountLicense !== RESTRICTED ? [this.nameRoleMap[USER].id] : [this.nameRoleMap[VIEW_ONLY].id];
      this.onUpdate({ ids: this.ids });
    }
  }
}

export const RolesSelectorComponent: IComponentOptions = {
  controller: RolesSelectorCtrl,
  template: require("./roles-selector.html"),
  bindings: {
    ids: "<",
    accountLicense: "<",
    onUpdate: "&",
  },
};
