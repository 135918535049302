import { IHttpService, IPromise, IQService, IRequestShortcutConfig, IRootScopeService } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { EnvironmentService } from "@gtmhub/env";
import { OkrEvents } from "@gtmhub/okrs/events";
import { getFullCollectionInBatches } from "@gtmhub/util";
import { ColoringService } from "@webapp/configuration/services/coloring.service";
import { ICollection } from "@webapp/core/core.models";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { ObjectiveFlowName } from "@webapp/platform-intelligence/shared/utils/pi-tracking";
import { PluginGtmhubAdditionalParams } from "@webapp/plugins/plugins.models";
import { IBaseGoal, IBulkCreateGoalRequest, IBulkCreateGoalResult, IGetGoalParams, IGoal, IGoalInTree } from "../models";

const fields =
  "metrics{trend,confidence,customFields,description,ownerIds,name,attainment,insightName,fieldName,settings,lastCheckInDate,targetOperator,dynamic," +
  "format,target,critical,initialValue,actual,goalId,manualType,softDueDate,dueDate,progressStatus,links{spec},cascadeType,sourceMetricId,targetMetricIds,orderId,sessionId}," +
  "currentUserAllowedActions,parentId,parentType,ownerIds,assignee{name,email,color,avatar,picture,type},description," +
  "name,dateFrom,dateTo,childrenCount,closedStatus,fullSubTreeCount,metricsCount,designScore,attainment,attainmentTypeString,fullAggregatedAttainment,sessionId," +
  "tags{id,name,title,isActive,itemsTaggedCount},customFields,obfuscated,private,access,parentGoalSessionId," +
  "workflow{status,reviewersNeeded,reviewers,approvedAt},locked,orderId,assignees,watchers";

export const fieldsLinks = fields + ",links{spec,expanded}";

const getGoalsFieldsV2 =
  "metrics{id,name,attainment,ownerIds,manualType,goalId,customFields,actual,format,links{spec},cascadeType,sourceMetricId,targetMetricIds,orderId,confidence}," +
  "parentId,parentType,ownerIds,description," +
  "name,dateFrom,dateTo,childrenCount,fullSubTreeCount,metricsCount,attainment,attainmentTypeString,fullAggregatedAttainment,sessionId," +
  "tags{id,name,title,isActive,itemsTaggedCount},customFields,obfuscated,private,parentGoalSessionId,locked,workflow{status},orderId,designScore{totalPoints}";

interface IPatchGoalParams {
  includeMetrics?: boolean;
  includeLinks?: boolean;
  gtmhubAdditionalParams?: { pluginId: string };
}

type GtmhubAdditionalParams = PluginGtmhubAdditionalParams & { gtmhubAdditionalParams: Record<string, unknown> };

export type UpdateGoalAdditionalPayloadData = Partial<{
  flowId: string;
  flowName: ObjectiveFlowName;
}>;

export interface IResponseProcessing {
  applyColoring?: boolean;
}

export class GoalService {
  private okrEvents: OkrEvents;

  public static $inject = ["$rootScope", "$http", "$q", "EnvironmentService", "ColoringService"];

  private defaultResponseProcessing: IResponseProcessing = {
    applyColoring: true,
  };

  constructor(
    $rootScope: IRootScopeService,
    private $http: IHttpService,
    private $q: IQService,
    private env: EnvironmentService,
    private coloringService: ColoringService
  ) {
    this.okrEvents = new OkrEvents($rootScope);
  }

  private applyColoringToGoal(goal: IGoalInTree): IGoal {
    goal.color = this.coloringService.getColor(goal.attainment);
    goal.aggregatedAttainmentColor = this.coloringService.getColor(goal.fullAggregatedAttainment);

    if (goal.metrics) {
      for (const metric of goal.metrics) {
        this.applyColoringToMetric(metric);
      }
    }

    return goal;
  }

  public applyColoringToGoalCollection<T>(collection: ICollection<T>): ICollection<T> {
    for (const goal of collection.items) {
      this.applyColoringToGoal(goal);
    }

    return collection;
  }

  private applyColoringToMetric(metric: Metric): Metric {
    metric.color = this.coloringService.getColor(metric.attainment);
    return metric;
  }

  public applyColoringToMetricCollection(collection: ICollection<Metric>): ICollection<Metric> {
    for (const goal of collection.items) {
      this.applyColoringToMetric(goal);
    }

    return collection;
  }

  public getGoalWithFields(id: string, ctrlFields = fieldsLinks): IPromise<IGoal> {
    const url = this.env.getApiEndpoint("/goals/" + id);
    const query: IRequestShortcutConfig = {
      params: {
        fields: ctrlFields || fieldsLinks,
      },
    };

    return this.$http.get<IGoal>(url, query).then((response) => this.applyColoringToGoal(response.data));
  }

  public getGoal(id: string, params?: IGetGoalParams, additionalGtmhubParams?: GtmhubAdditionalParams): IPromise<IGoal> {
    const url = this.env.getApiEndpoint("/goals/" + id);
    const config: IRequestShortcutConfig = {
      params: {
        ...params,
        ...additionalGtmhubParams,
      },
    };

    return this.$http.get<IGoal>(url, config).then((response) => this.applyColoringToGoal(response.data));
  }

  public createGoal(goal: IGoal, options: { skipNotification?: boolean; cloned?: boolean } = {}, additionalGtmhubParams?: GtmhubAdditionalParams): IPromise<IGoal> {
    const url = this.env.getApiEndpoint("/goals");
    const query: IRequestShortcutConfig = {};
    if (options.skipNotification) {
      query.params = { skipNotification: options.skipNotification };
    }
    if (options.cloned) {
      query.params = { cloned: options.cloned };
    }
    if (additionalGtmhubParams) {
      query.params = { ...query.params, ...additionalGtmhubParams };
    }

    return this.$http.post<IGoal>(url, goal, query).then((response) => {
      const newGoal = response.data;
      this.okrEvents.broadcastGoalsChanged("goalCreated", { goal: newGoal });
      return newGoal;
    });
  }

  public patchGoal(goalId: string, patch: Partial<IGoal> & UpdateGoalAdditionalPayloadData, patchGoalParams?: IPatchGoalParams): IPromise<IGoal> {
    const url = this.env.getApiEndpoint(`/goals/${goalId}`);
    const config: IRequestShortcutConfig = {
      params: patchGoalParams,
    };

    return this.$http.patch<IGoal>(url, patch, config).then((response) => {
      const patchedGoal = response.data;
      this.okrEvents.broadcastGoalsChanged("goalPatched", { goal: patchedGoal });
      return patchedGoal;
    });
  }

  public deleteGoal(goalId: string, additionalGtmhubParams?: GtmhubAdditionalParams): IPromise<void> {
    const url = this.env.getApiEndpoint(`/goals/${goalId}`);

    const config: IRequestShortcutConfig = {
      params: additionalGtmhubParams,
    };

    return this.$http.delete(url, config).then(() => this.okrEvents.broadcastGoalsChanged("goalDeleted", { goalId }));
  }

  public getAllGoalsV2<T>(params: IRestLayerRequest): IPromise<ICollection<T>> {
    if (!params.fields) {
      params.fields = getGoalsFieldsV2;
    }

    const url = this.env.getApiEndpointV2("/goals");
    return getFullCollectionInBatches<T>(url, { params }, this.$http, this.$q).then((collection) => this.applyColoringToGoalCollection(collection));
  }

  public getGoalsV2<T extends IBaseGoal>(
    params: IRestLayerRequest,
    additionalGtmhubParams?: GtmhubAdditionalParams,
    responseProcessing: IResponseProcessing = this.defaultResponseProcessing
  ): IPromise<ICollection<T>> {
    if (!params.fields) {
      params.fields = getGoalsFieldsV2;
    }

    const url = this.env.getApiEndpointV2("/goals");

    const config: IRequestShortcutConfig = {
      params: { ...params, ...additionalGtmhubParams },
    };

    return this.$http.get<ICollection<T>>(url, config).then((response) => {
      if (responseProcessing.applyColoring) {
        return this.applyColoringToGoalCollection(response.data);
      }

      return response.data;
    });
  }

  public createBulkGoals(okrs: IBulkCreateGoalRequest[]): IPromise<IBulkCreateGoalResult[]> {
    const url: string = this.env.getApiEndpoint("/goals/bulk");
    return this.$http.post<IBulkCreateGoalResult[]>(url, okrs).then((response) => response.data);
  }
}
