import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { LoginMobileWarningService } from "@webapp/login/services/login-mobile-warning.service";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";

export class Ng1LoginMobileWarningService extends LoginMobileWarningService {
  static $inject = ["Ng1UiModalService", "AccountResolverService"];

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(modalService: UiModalService, accountResolverService: AccountResolverService) {
    super(modalService, accountResolverService);
  }
}
