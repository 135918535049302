import { ITeam } from "@gtmhub/teams";
import { toKeyMap } from "@gtmhub/util";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { Comment, CommentTargetType } from "@webapp/comments";
import dayjs from "@webapp/shared/libs/dayjs";
import { MENTION_PATTERN, OKR_LINK_SELECTOR_PATTERN, okrMappingRecord } from "@webapp/shared/rich-text-editor/rich-text-editor.utils";
import { CheckIn, CheckInAnswer, CheckInNotes } from "../models/checkins-models";
import { IReflectionContributor } from "../models/reflection-contributor-list.models";
import { ReflectionPanel } from "../models/reflection-panel.models";
import { ReflectionQuestion } from "../models/reflection-question.model";
import { ReflectionNote, ReflectionNoteType, ReflectionOverviewFilterName, reflectionNoteTypes } from "../models/reflections.models";
import { CheckinPostRequestBody, CheckinPutRequestBody, CheckinsRequestFilterParams, CheckinsRequestTeam, moodMap } from "../models/team-reflections.utils";
import { transformLocalToUtcString } from "./transform-local-to-utc-string";

export function startWithFieldValue<T extends { [K in keyof T] }>(field: keyof T, valueToStartWith: T[keyof T]): (a: T, b: T) => number {
  return function (a: T, b: T): number {
    return a[field] === valueToStartWith ? -1 : b[field] === valueToStartWith ? 1 : 0;
  };
}

export const extractUniqueTeamMemberIds = (team: ITeam): string[] => {
  return [...new Set([...(team.members || []), ...(team.manager ? [team.manager] : [])])];
};

export const toEnrichedContributorListMember = (member: Assignee, params = { withDotIndicator: false }): IReflectionContributor => ({
  ...member,
  withDotIndicator: params.withDotIndicator,
});

export const getLastMondayAsDate = (): string => {
  return dayjs().startOf("isoWeek").toISOString();
};

export const getCurrentWeeksSundayAsDate = (): string => {
  return dayjs().day(7).toISOString();
};

export const getLastEditableMondayAsDate = (): string => {
  return dayjs().startOf("isoWeek").subtract(7, "days").toISOString();
};

export const createdAtBelongsInCadance = (createdAt: string, cadanceStart: string, cadanceEnd: string): boolean => {
  return dayjs(createdAt).isBetween(cadanceStart, cadanceEnd, "days", "[]");
};

export const getLastDayOfCadence = (cadenceStartDate: string, cadenceDurationInDays: number): string => {
  const startDate = dayjs(cadenceStartDate);
  const endDate = startDate
    .clone()
    .add(cadenceDurationInDays - 1, "day")
    .hour(23)
    .minute(59)
    .second(59);

  return endDate.utc().toISOString();
};

export const getPrevCadenceStartDate = (currCadenceStartDate: string, cadenceDurationInDays: number): string => {
  const currStartDate = dayjs(currCadenceStartDate);
  const prevStartDate = currStartDate
    .clone()
    .subtract(cadenceDurationInDays - 1, "day")
    .startOf("isoWeek");

  return prevStartDate.utc().toISOString();
};

export const getFilteredByNoReflectionLength = (reflection: ReflectionPanel[]): number => {
  return reflection.filter((reflection) => reflection.noReflection)?.length || 0;
};

export const getFilteredByReflectionLength = (reflection: ReflectionPanel[]): number => {
  return reflection.filter((reflection) => !reflection.noReflection)?.length || 0;
};

export const getFilteredByNoteTypeLength = (reflection: ReflectionPanel[], filter: ReflectionOverviewFilterName): number => {
  return reflection.filter((reflection) => toKeyMap("type", reflection.notes)[filter]?.text || toKeyMap("type", reflection.notes)[filter]?.gif?.id)?.length || 0;
};

export const generateCommentTemplate = (targetId: string, targetType: CommentTargetType): Comment => {
  return {
    createdBy: "",
    createdAt: "",
    id: targetId,
    mentioned: [],
    seen: [],
    targetId,
    targetTitle: "",
    targetType,
    text: "",
  };
};

export const getLatestDateFromComments = (comments: Comment[]): string => {
  if (!comments?.length) return "";

  return new Date(Math.max(...comments.map((comment) => new Date(comment.createdAt).getTime()))).toISOString();
};

export const isNoteFilterEnabled = (filter: string): boolean => {
  return reflectionNoteTypes.includes(filter as ReflectionNoteType);
};

export const buildCheckinCommentsCounterAriaLabel = (comments: Comment[]): string => {
  return `${comments.length} comment${comments.length === 1 ? "" : "s"}, last comment `;
};

export const getA11yLabelFromAnswer = (answer: string): string => {
  const mentionRegExp = new RegExp(MENTION_PATTERN, "g");
  const okrLinkRegExp = new RegExp(OKR_LINK_SELECTOR_PATTERN, "g");

  let a11yLabel = answer;

  const mentionMatches = a11yLabel.matchAll(mentionRegExp);
  for (const match of mentionMatches) {
    const name = match[1];
    a11yLabel = a11yLabel.replace(match[0], `${name} `);
  }

  const okrLinkMatches = a11yLabel.matchAll(okrLinkRegExp);
  for (const match of okrLinkMatches) {
    const linkedItem = decodeURIComponent(match[2]);
    a11yLabel = a11yLabel.replace(match[0], `linked ${okrMappingRecord[match[1]]} ${linkedItem} `);
  }
  return a11yLabel;
};

export const createCheckinsRequestFilterByTeam = (teamId: string): Record<string, CheckinsRequestTeam> => {
  return {
    filter: {
      teamId: { $in: [teamId] },
    },
  };
};

export const createCheckinsRequestFilterByTeamWithCadence = (teamId: string, startDate: string, endDate: string): Record<string, CheckinsRequestFilterParams> => {
  return {
    filter: {
      $and: [{ createdAt: { $gte: transformLocalToUtcString(startDate) } }, { createdAt: { $lt: transformLocalToUtcString(endDate) } }, { teamId: { $in: [teamId] } }],
    },
  };
};

export const createCheckinPostRequestBody = ({ teamId, templateId, mood, answers, notesV2, settings, cadenceDate }: CheckinPostRequestBody): Partial<CheckIn> => {
  return {
    teamId,
    templateId,
    mood: moodMap[mood],
    answers,
    notesV2,
    settings,
    cadenceDate: transformLocalToUtcString(cadenceDate),
  };
};

export const createCheckinPostRequestAnswersDTO = (questions: ReflectionQuestion[]): CheckInAnswer[] => {
  return questions.flatMap((question) => {
    return question.isEnabled ? { text: question.answer, questionText: question.question, questionId: question.id, gif: question.gif } : [];
  });
};

export const createCheckinPutRequestBody = ({ mood, answers, notesV2 }: CheckinPutRequestBody): Partial<CheckIn> => {
  return {
    mood: moodMap[mood],
    answers,
    notesV2,
  };
};

export const createCheckinPutRequestAnswersDTO = (questions: ReflectionQuestion[]): CheckInAnswer[] => {
  return questions?.map((question) => ({
    id: question.answerId,
    text: question.answer,
    gif: question.gif,
  }));
};

export const createCheckinRequestNotesDTO = (notes: ReflectionNote[]): CheckInNotes => {
  return notes?.reduce((notes, note) => {
    notes[note.type] = { id: note.id, text: note.text !== "-" ? note.text : "", gif: note.gif };
    return notes;
  }, {} as CheckInNotes);
};
export const updateVisibleLabels = (params: { currentBrowserWidth: number; isLeftNavigationEnabled: boolean; compareMode: boolean }): number => {
  let maxVisibleLabels = 2;
  if (params.currentBrowserWidth <= 1280 && params.isLeftNavigationEnabled && !params.compareMode) {
    maxVisibleLabels = 1;
  }
  if (params.currentBrowserWidth <= 1280 && params.compareMode) {
    maxVisibleLabels = 0;
  }
  if (params.currentBrowserWidth >= 1025 && !params.compareMode && !params.isLeftNavigationEnabled) {
    maxVisibleLabels = 2;
  }
  if (params.currentBrowserWidth >= 1281 && !params.compareMode && !params.isLeftNavigationEnabled) {
    maxVisibleLabels = 5;
  }
  if (params.currentBrowserWidth >= 1441 && !params.compareMode) {
    maxVisibleLabels = 5;
  }
  if (params.currentBrowserWidth >= 1441 && params.compareMode) {
    maxVisibleLabels = 2;
  }
  if (params.currentBrowserWidth >= 1025 && params.currentBrowserWidth < 1440 && params.isLeftNavigationEnabled && !params.compareMode) {
    maxVisibleLabels = 1;
  }
  if (params.currentBrowserWidth <= 1024 && !params.compareMode) {
    maxVisibleLabels = 1;
  }
  if (params.currentBrowserWidth <= 1024 && params.isLeftNavigationEnabled) {
    maxVisibleLabels = 0;
  }
  if (params.currentBrowserWidth <= 768 && !params.isLeftNavigationEnabled) {
    maxVisibleLabels = 0;
  }
  if (params.currentBrowserWidth >= 1281 && params.currentBrowserWidth < 1440 && params.compareMode) {
    maxVisibleLabels = 1;
  }
  return maxVisibleLabels;
};
