import { UIRouter } from "@uirouter/angular";
import { Inject, Injectable } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { CoreExtraContext, CoreOptions } from "@segment/analytics-core";
import { AnalyticsBrowser } from "@segment/analytics-next";
import { first, take, zip } from "rxjs";
import { AccountType, IAccount } from "@gtmhub/core";
import { APP_CONFIG, IAppConfig } from "@gtmhub/env";
import { IChecklist } from "@gtmhub/onboarding";
import { ICurrentUserRolesStoreState } from "@gtmhub/roles";
import { Intercom } from "@gtmhub/shared/intercom";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { getCurrentUserId } from "@gtmhub/users";
import { IPermissionsStoreState } from "@gtmhub/users/redux";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { Permission } from "@webapp/permissions/models/permissions.model";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { AnalyticsIntegration, IAnalyticsPageOptions, IAnalyticsPagePayload, TrackingMetadata } from "../models/analytics.model";
import { AnalyticsFactoryService } from "./analytics.factory.service";
import { IntercomFacade } from "./intercom/intercom-facade.service";

@UntilDestroy()
@Injectable({
  providedIn: "root",
})
export class AnalyticsService {
  private analytics: AnalyticsBrowser;

  constructor(
    @Inject(APP_CONFIG) private appConfig: IAppConfig,
    private analyticsFactoryService: AnalyticsFactoryService,
    private router: UIRouter,
    private accountResolverService: AccountResolverService,
    private profileService: UserProfileService,
    private intercomFacade: IntercomFacade
  ) {
    this.analytics = this.analyticsFactoryService.getAnalytics();
  }

  public track(event: string, meta?: TrackingMetadata): void {
    try {
      if (!this.canUseAnalytics(`Track: ${event}`)) {
        return;
      }

      const analyticsPayload = this.generateAnalyticsTrackPayload(meta);
      const analyticsOptions = this.generateAnalyticsOptions();

      this.analytics.track(event, analyticsPayload, analyticsOptions);
    } catch (error) {
      console.warn(error);
    }
  }

  public page(page: string, opts?: IAnalyticsPageOptions): void {
    try {
      if (!this.canUseAnalytics(`Page: ${page}`)) {
        return;
      }

      const analyticsPageProperties = this.generateAnalyticsPagePayload(opts);
      const analyticsOptions = this.generateAnalyticsOptions();
      this.analytics.page(page, analyticsPageProperties, analyticsOptions);
    } catch (error) {
      console.error(error);
    }
  }

  public setOnboardingProperties(onboardingChecklist: IChecklist): void {
    try {
      if (!this.canUseAnalytics("setOnboardingProperties.analytics.identify")) {
        return;
      }

      this.analytics.identify(getCurrentUserId(), onboardingChecklist);
    } catch (error) {
      console.error(error);
    }
  }

  public identify(): void {
    if (!this.canUseAnalytics("analytics.identify")) {
      return;
    }
    const reduxObservable$ = new ReduxStoreObserver(reduxStoreContainer.reduxStore)
      .whenFetched$<ICurrentUserRolesStoreState & IPermissionsStoreState>("currentUserRoles", "permissions")
      .pipe(first((state) => !!state.permissions.items.size && !!state.currentUserRoles.nonShadowRoles));

    const userHash$ = this.intercomFacade.getIntercomUserHash$().pipe(take(1));

    zip(reduxObservable$, userHash$)
      .pipe(untilDestroyed(this))
      .subscribe(([state, { userHash }]) => {
        this.identifyUser(
          state.permissions.items,
          state.currentUserRoles.nonShadowRoles.map((role) => role.name),
          userHash
        );
      });
  }

  public reset(): void {
    try {
      if (!this.canUseAnalytics("analytics.reset")) {
        return;
      }

      this.analytics.reset();
    } catch (error) {
      console.warn(error);
    }
  }

  public contactSales(eventName: string): void {
    Intercom("showNewMessage");

    const meta = {
      account_id: this.accountResolverService.getAccountId(),
      page: window.location.hash,
    };
    this.track(eventName, meta);
  }

  private identifyUser(permissions: Set<Permission>, rolesNames: string[], userHash: string): void {
    try {
      const accountData = this.accountResolverService.getAccountData();
      let accountStatus: string;
      switch (accountData.type) {
        case 0:
          accountStatus = "Trial";
          break;
        case 1:
          accountStatus = "Active";
          break;
        case 2:
          accountStatus = "Internal";
          break;
        case 3:
          accountStatus = "Demo";
          break;
        // partner type should also be marked as active
        case 4:
          accountStatus = "Active";
          break;
        case 5:
          accountStatus = "Free";
          break;
        default:
          accountStatus = "Internal";
      }

      const userInfo = {
        id: getCurrentUserId(),
        email: this.profileService.getProfile().email,
        name: this.profileService.getProfile().firstName + " " + this.profileService.getProfile().lastName,
        firstName: this.profileService.getProfile().firstName,
        lastName: this.profileService.getProfile().lastName,
        rolesNames,
        permissions: [...permissions],
        created: this.profileService.getProfile().created,
        picture: this.profileService.getProfile().picture,
      };

      let isPrimary = this.profileService.getProfile().isPrimary;
      if (isPrimary === undefined) {
        isPrimary = true;
      }

      const integrations = this.generateAnalyticsIntegrations(accountData);

      const roles = userInfo.rolesNames.join(",");

      const context = this.generateAnalyticsContext(integrations, userHash);

      this.analytics.identify(
        userInfo.id,
        {
          email: userInfo.email,
          name: userInfo.name,
          firstName: userInfo.firstName,
          lastName: userInfo.lastName,
          company: {
            id: accountData.id,
            name: accountData.name,
            plan: accountData.edition.planId,
          },
          avatar: userInfo.picture,
          createdAt: userInfo.created,
          roles,
          permissions: userInfo.permissions.join(","),
          accountId: accountData.id,
          accountName: accountData.name,
          accountstatus: accountStatus,
          accountcreated: accountData.dateCreated,
          trialends: accountData.trialEnds,
          editionname: accountData.edition.name,
          editionplanid: accountData.edition.planId,
          isPrimary: isPrimary,
          screenSize: `${screen.width}x${screen.height}`,
          screenWidth: screen.width,
          screenHeight: screen.height,
        },
        {
          integrations,
          ...(context ? { context: context } : {}),
        }
      );
    } catch (error) {
      console.warn(error);
    }
  }

  private generateAnalyticsPagePayload(opts: IAnalyticsPageOptions = {}): IAnalyticsPagePayload {
    const path = opts.path || this.router.locationService.url();
    const hash = this.router.locationService.hash();

    return {
      controller: opts.controller,
      location_hash: hash,
      path,
      gtmhub_application_name: "webapp",
      gtmhub_path: this.router.stateService.current.name,
      // Analytics.js collects url, title, referrer and path are automatically.
      // This defaults to a canonical url, if available, and falls back to document.location.href.
      // https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#page

      title: "", // Title shouldn't be sent GDPR https://quantive-inc.atlassian.net/browse/GVS-32124
    };
  }

  private generateAnalyticsTrackPayload(meta: TrackingMetadata = {}): TrackingMetadata {
    const payload = structuredClone(meta);
    if (!("accountId" in payload) && !("account_id" in payload)) {
      payload.account_id = this.accountResolverService.getAccountId();
    }

    payload.gtmhub_application_name = "webapp";
    payload.gtmhub_path = this.router.stateService.current.name;
    return payload;
  }

  private generateAnalyticsOptions(): CoreOptions {
    const account = this.accountResolverService.getAccountData();
    if (!account) {
      return {};
    }
    const integrations = this.generateAnalyticsIntegrations(account);
    return {
      context: {
        groupId: account.id,
        accountType: account.type,
      },
      integrations,
    };
  }

  private generateAnalyticsIntegrations(account: IAccount): AnalyticsIntegration {
    const integrations = {};
    if (account.type === AccountType.DemoAccount) {
      integrations["Intercom"] = false;
    }

    return integrations;
  }

  private generateAnalyticsContext(integrations: AnalyticsIntegration, userHash: string): CoreExtraContext {
    return integrations["Intercom"] !== false
      ? {
          Intercom: {
            userHash,
          },
        }
      : null;
  }

  private canUseAnalytics(eventName: string): boolean {
    return this.appConfig.segment.enabled && this.shouldTrackSegment(eventName);
  }

  private shouldTrackSegment(eventName: string): boolean {
    const account = this.accountResolverService.getAccountData();
    if (!account) {
      console.warn(`No account data supplied, analytics disabled. ${eventName}`);
      return false;
    }
    return account.trackingProperties.shouldTrack;
  }
}
