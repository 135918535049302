import { Injectable } from "@angular/core";
import { Assignee, TeamAssignee } from "@webapp/assignees/models/assignee.models";
import { TreeListItem } from "@webapp/shared/treelist-selector/treelist-selector.models";

@Injectable({
  providedIn: "root",
})
export class AssigneeListDataTransformerService {
  public buildList(assignees: Assignee[]): TreeListItem[] {
    const list: TreeListItem[] = [];
    this.populateList(assignees, list);
    return list;
  }

  private populateList(assignees: Assignee[], list: TreeListItem[]): void {
    for (const assignee of assignees) {
      const listItem = this.createAssigneeListItem(assignee);
      list.push(listItem);
    }
  }

  private createAssigneeListItem(assigneeNode: Assignee): TreeListItem {
    const assigneeItem: TreeListItem = {
      key: assigneeNode.id,
      title: assigneeNode.name,
      disabled: !assigneeNode.isActive,
      parentKey: null,
      picture: assigneeNode.picture,
      type: assigneeNode.type,
      avatar: (assigneeNode as TeamAssignee).avatar,
      color: (assigneeNode as TeamAssignee).color,
    };

    return assigneeItem;
  }
}
