import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { localize } from "@gtmhub/localization";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import dayjs from "@webapp/shared/libs/dayjs";
import { UiModalButtonOptions } from "@webapp/ui/modal/modal.models";
import { UiModalService } from "@webapp/ui/modal/services/modal.service";
import { MobileWarningComponent } from "../components/mobile-warning.component";

@Injectable()
export class LoginMobileWarningService {
  constructor(
    private modalService: UiModalService,
    private accountResolverService: AccountResolverService
  ) {}

  public openWarningModal(): Observable<void> {
    const account = this.accountResolverService.getAccountData();
    const createdInTheLastFiveMinutes = dayjs.utc().diff(account.dateCreated, "minutes") < 5;

    if (createdInTheLastFiveMinutes) {
      const subject = new Subject<void>();
      this.modalService.create<MobileWarningComponent, null>({
        uiTitle: localize("your_quantive_results_account_has_been_created"),
        uiContent: MobileWarningComponent,
        uiFooter: [
          this.secondaryButton(localize("maybe_later"), {
            onClick: () => this.closeModal(subject),
          }),
        ],
        uiClosable: false,
        uiMaskClosable: false,
      });
      return subject;
    }
  }

  private closeModal(subject: Subject<void>): void {
    subject.next();
    subject.complete();
  }

  // the reason this is not util as primaryButton is because this is not following
  // the desing system requirements that modal should have buttons primaty and/or tetriary
  private secondaryButton = <T>(label: string, options: Omit<UiModalButtonOptions<T>, "label" | "type"> = {}): UiModalButtonOptions<T> => {
    const { onClick, ...restOptions } = options;
    return {
      label,
      type: "default",
      shape: "round",
      size: "large",
      ...restOptions,
      onClick(component, modalRef): unknown {
        modalRef.close();
        return onClick.call(this, component, modalRef);
      },
    };
  };
}
