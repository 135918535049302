import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, Output, Renderer2, ViewChild } from "@angular/core";
import { localize } from "@webapp/localization/utils/localization.utils";
import { ItemNameWithIconSetAriaLabelParameters } from "@webapp/okrs/components/item-name-with-icon/item-name-with-icon.models";

/**
 * @example <item-name-with-icon [name]="metric.name" icon="metric"></item-name-with-icon>
 */
@Component({
  selector: "item-name-with-icon",
  templateUrl: "./item-name-with-icon.component.html",
  styleUrls: ["./item-name-with-icon.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ItemNameWithIconComponent implements OnChanges, AfterViewInit, OnDestroy {
  /**
   * @param name string - The text to be shown
   */
  @Input() public name: string;
  /**
   * @param placeholder string - Text to be shown if `name` is empty. Can happen for tasks for example.
   */
  @Input() public placeholder: string;
  /**
   * @param icon string - Icon type to be shown. Uses the `fill` theme
   */
  @Input() public icon: string;
  /**
   * @param bold boolean - Should the text be bold
   */
  @Input() public bold: boolean;
  /**
   * @param isEditable boolean - Should the text be editable - needed to task as they can be created without a name
   */
  @Input() public isEditable = false;
  /**
   * @param isDisabled boolean - Should the text and click be disabled
   */
  @Input() public isDisabled = false;
  /**
   * @param tabIndex number - The `tabindex` value of the component; By default it'll be 0
   */
  @Input() public tabIndex = 0;
  /**
   * @param a11yDescribedby string - The ARIA described-by attribute
   */
  @Input() public a11yDescribedby: string;
  /**
   * @param a11yLabelExtra string - The ARIA label attribute additional text after the default one
   */
  @Input() public a11yLabelExtra: string;

  /**
   * @param ariaHasPopup boolean - The ARIA haspopup attribute
   */
  @Input() public ariaHasPopup: boolean;

  /**
   * Specifies whether a tooltip with the full item name should be displayed, when the content gets cut off.
   */
  @Input() public showTooltipWhenTextHidden = false;

  /**
   * @param click () => void - Function to be executed оn click
   */
  @Output() public readonly nameClick = new EventEmitter<void>();
  /**
   * @param nameChange () => void - Function to be executed when user has finished entering text
   */
  @Output() public readonly nameChange = new EventEmitter<string>();

  constructor(private renderer: Renderer2) {}

  public newName: string;
  public ariaLabel: string;
  private clickSubscription: () => void;
  private enterSubscription: () => void;

  @ViewChild("nameElem") private nameElem: ElementRef;

  public ngOnChanges(): void {
    this.setAriaLabel({ icon: this.icon, name: this.name || this.placeholder });
  }

  public ngOnDestroy(): void {
    if (this.clickSubscription) this.clickSubscription();
    if (this.enterSubscription) this.enterSubscription();
  }

  public ngAfterViewInit(): void {
    if (!this.isDisabled && this.isNameReadonly()) {
      this.clickSubscription = this.renderer.listen(this.nameElem.nativeElement, "click", () => {
        this.nameClick.emit();
      });
      this.enterSubscription = this.renderer.listen(this.nameElem.nativeElement, "keyup.enter", () => {
        this.nameClick.emit();
      });
    }
  }

  public inputChanged(): void {
    const name = this.newName.trim();

    if (name) {
      this.nameChange.emit(name);
    }
  }

  private setAriaLabel({ icon = "", name = "" }: ItemNameWithIconSetAriaLabelParameters): void {
    let type: string;

    if (icon === "goal") {
      type = localize("objective");
    } else if (icon === "metric") {
      type = localize("key_result");
    } else if (icon) {
      type = localize(icon);
    } else {
      this.ariaLabel = name;

      return;
    }

    this.ariaLabel = `${type || ""} ${name} ${this.a11yLabelExtra ? ". " + this.a11yLabelExtra : ""}`.trim();
  }

  public isNameReadonly(): boolean {
    return !!this.name || !this.isEditable;
  }
}
