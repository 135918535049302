import { IAssigneesStoreState } from "@gtmhub/assignees";
import { localize } from "@gtmhub/localization";
import { Assignee, AssigneeSelectorType, MissingAssignee, UnknownAssignee } from "../models/assignee.models";

export const assigneeFromMap = (
  assignees: Map<string, Assignee> | undefined,
  assigneeId: string,
  options?: { hideDeleted: boolean }
): Assignee | UnknownAssignee | undefined => {
  if (!assigneeId) {
    return undefined;
  }

  if (!assignees) {
    return { id: assigneeId, isUnknown: true, name: localize("loading"), avatar: "refresh", color: "#87a6bc" };
  }

  if (options?.hideDeleted) {
    return assignees.get(assigneeId) || undefined;
  } else {
    return assignees.get(assigneeId) || deletedAssignee(assigneeId);
  }
};

export const assigneeFromRedux = (state: IAssigneesStoreState, assigneeId: string, options?: { hideDeleted: boolean }): Assignee | UnknownAssignee => {
  if (!assigneeId) {
    return undefined;
  }

  if (!state.assignees.isFetched) {
    return { id: assigneeId, isUnknown: true, name: localize("loading"), avatar: "refresh", color: "#87a6bc" };
  }

  if (options?.hideDeleted) {
    return state.assignees.map[assigneeId] || undefined;
  } else {
    return state.assignees.map[assigneeId] || deletedAssignee(assigneeId);
  }
};

export const isUnknownAssignee = (assignee: Assignee | UnknownAssignee | MissingAssignee): assignee is UnknownAssignee => "isUnknown" in assignee && assignee.id !== "";
export const isMissingAssignee = (assignee: Assignee | UnknownAssignee | MissingAssignee): assignee is MissingAssignee => "isUnknown" in assignee && assignee.id === "";

export const deletedAssignee = (id: string, assigneeType: "user" | "team" = "user"): UnknownAssignee => ({
  id,
  isUnknown: true,
  name: "(" + localize(`deleted_${assigneeType}`) + ")",
  avatar: "unknown",
  color: "#87a6bc",
});

// unkown is someone that we do have an Id for, but we no longer have any reference to
// missing is we don't have an id for, so we don't know who he is
export const createMissingAssignee = (): MissingAssignee => ({
  id: "",
  isUnknown: true,
  name: localize("unknown"),
  avatar: "unknown",
  color: "#87a6bc",
});

export const respectsAssignPermissions = (assignee: Assignee, assignPermissions: AssigneeSelectorType): boolean =>
  (assignPermissions !== "team" && assignee.type === "user") || (assignPermissions !== "user" && assignee.type === "team");
