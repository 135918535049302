import { IAugmentedJQuery, ICompileService, IDirective, IDirectiveFactory, IScope } from "angular";
import { INgRedux } from "@gtmhub/state-management";
import { AssigneeComponentInstances } from "@webapp/assignees/component-instance-tracker";
import { assigneeFromRedux } from "@webapp/assignees/utils/assignee.utils";
import { IAssigneesStoreState } from "../redux/assignee-reducer";

// "Flatten" expressions like item[column.name], team.id, assigneeId so that we can cache them on the scope
const createResolvedExpression = (expr: string) => expr.replace(/[^\w]/g, "_") + "_resolved";

/**
 * This directive is used with ui-assignee and ui-assignee-avatar components from the UI Kit.
 * It transforms assignee ID to an assignee and binds it to the underlying component. Since
 * assignees are changed via Web Sockets, we will automatically refresh the bindings when
 * the corresponding socket message is received.
 */
export class FromAssigneeIdDirective implements IDirective {
  public restrict = "A";

  constructor(
    private $ngRedux: INgRedux,
    private $compile: ICompileService,
    private ngRepeatDirective: IDirective[]
  ) {}

  public compile(element: IAugmentedJQuery) {
    const assigneeIdExpr = element.attr("from-assignee-id");
    element.removeAttr("from-assignee-id");

    const hideDeleted = element.attr("hide-deleted") === "true";
    element.removeAttr("hide-deleted");

    const resolvedAssigneeExpr = createResolvedExpression(assigneeIdExpr);
    element.attr("bind-ui-assignee", resolvedAssigneeExpr);

    return (scope: IScope, el: IAugmentedJQuery) => {
      // Compile all directives with lower priority than ng-repeat,
      // since it will be handled separately by Angular.js
      const maxPriority = this.ngRepeatDirective[0].priority - 1;
      this.$compile(el, null, maxPriority)(scope);

      scope.$on("$destroy", () => {
        AssigneeComponentInstances.delete(el[0]);
      });

      scope.$watch<string[] | string>(assigneeIdExpr, (assigneeIds) => {
        const refresh = () => {
          const state: IAssigneesStoreState = this.$ngRedux.getState();

          if (typeof assigneeIds === "string") {
            scope[resolvedAssigneeExpr] = assigneeFromRedux(state, assigneeIds);
          } else {
            // If it's an array, find the first valid assignee
            scope[resolvedAssigneeExpr] = (assigneeIds || [])
              .map((assigneeId) => assigneeFromRedux(state, assigneeId, { hideDeleted }))
              .filter((assignee) => assignee !== undefined)[0];
          }
        };

        AssigneeComponentInstances.set(el[0], {
          assigneeIds: typeof assigneeIds === "string" ? [assigneeIds] : assigneeIds ? assigneeIds.filter((assignee) => assignee !== undefined) : [],
          refresh,
        });

        refresh();
      });
    };
  }

  public static factory(): IDirectiveFactory {
    const factory = ($ngRedux: INgRedux, $compile: ICompileService, ngRepeatDirective: IDirective[]) =>
      new FromAssigneeIdDirective($ngRedux, $compile, ngRepeatDirective);
    factory.$inject = ["$ngRedux", "$compile", "ngRepeatDirective"];
    return factory;
  }
}
