import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { ReactiveFormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { ConnectorServiceProvider } from "@webapp/connectors/providers/connector.service.provider";
import { DataSourceServiceProvider } from "@webapp/datasources/providers/datasource.service.provider";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { FormControlErrorComponent } from "@webapp/standalone/form-control-error/form-control-error.component";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiFormModule } from "@webapp/ui/form/form.module";
import { UiGridModule } from "@webapp/ui/grid/grid.module";
import { UiTextAreaModule } from "@webapp/ui/input/components/textarea-count/ui-textarea.module";
import { UiInputModule } from "@webapp/ui/input/input.module";
import { UiSelectModule } from "@webapp/ui/select/select.module";
import { ConfigurePowerBIModalService } from "./components/configure-power-bi/configure-power-bi-modal.service";
import { ConfigurePowerBIComponent } from "./components/configure-power-bi/configure-power-bi.component";
import { PowerBIFormComponent } from "./components/power-bi-form/power-bi-form.component";
import { ScalarDataSourceModalComponent } from "./components/scalar-datasource-modal/scalar-datasource-modal.component";

@NgModule({
  declarations: [PowerBIFormComponent, ConfigurePowerBIComponent, ScalarDataSourceModalComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiFormModule,
    UiIconModule,
    UiGridModule,
    UiButtonModule,
    UiSelectModule,
    UiTextAreaModule,
    UiInputModule,
    LocalizationModule,
    IndicatorModule,
    UiAlertModule,
    FormControlErrorComponent,
  ],
  exports: [PowerBIFormComponent, ConfigurePowerBIComponent, ScalarDataSourceModalComponent],
  providers: [ConfigurePowerBIModalService, ConnectorServiceProvider, DataSourceServiceProvider],
})
export class PowerBIModule {}
