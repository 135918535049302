import { Directionality } from "@angular/cdk/bidi";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnChanges, Optional, Output, TemplateRef } from "@angular/core";
import { UiAccessibilityModule } from "@quantive/ui-kit/accessibility";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { SimpleChangesOf, uiToNz } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { NzAlertComponent } from "ng-zorro-antd/alert";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { UiAlertType } from "@webapp/ui/alert/alert.models";
import { UiCollapseModule } from "../collapse/collapse.module";

export type ErrorDetails = {
  title?: string;
  description: string;
};

@Component({
  selector: "ui-alert",
  exportAs: "uiAlert",
  templateUrl: "alert.component.html",
  styleUrls: ["alert.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: "ui-alert",
  },
  standalone: true,
  imports: [CommonModule, NzOutletModule, UiIconModule, UiButtonModule, UiAccessibilityModule, UiCollapseModule],
})
export class UiAlertComponent extends NzAlertComponent implements OnChanges {
  @Input("uiCloseText") public nzCloseText: string | TemplateRef<void> | null = null;
  @Input("uiIconType") public nzIconType: string | null = null;
  @Input("uiMessage") public nzMessage: string | TemplateRef<void> | null = null;
  @Input("uiDescription") public nzDescription: string | TemplateRef<void> | null = null;
  @Input("uiType") public nzType: UiAlertType = "info";
  @Input("uiCloseable") @WithConfig() @InputBoolean() public nzCloseable = false;
  @Input("uiShowIcon") @WithConfig() @InputBoolean() public nzShowIcon = true;
  @Input("uiBanner") @InputBoolean() public nzBanner = false;
  @Input() public uiIsLegacy = false;
  @Input() public uiPrimaryCTA: string;
  @Input() public uiSecondaryCTA: string;
  @Input() public uiTertiaryCTA: string;
  @Input() public a11yCloseBtnTitle: string = "Alert dismiss";
  @Input() public errorDetails?: ErrorDetails;

  @Output("uiOnClose") public readonly nzOnClose = new EventEmitter<boolean>();
  @Output() public readonly uiOnPrimaryAction = new EventEmitter<void>();
  @Output() public readonly uiOnSecondaryAction = new EventEmitter<void>();
  @Output() public readonly uiOnTertiaryAction = new EventEmitter<void>();

  constructor(nzConfigService: NzConfigService, cdr: ChangeDetectorRef, @Optional() directionality: Directionality) {
    super(nzConfigService, cdr, directionality);
  }

  public ngOnChanges(changes: SimpleChangesOf<UiAlertComponent>): void {
    super.ngOnChanges(uiToNz(changes));

    if (changes.nzType) {
      this.setInferredIconType();
    }
  }

  public generateAlertIconAriaLabel(): string {
    switch (this.nzType) {
      case "success": {
        return this.nzBanner ? "Banner success" : "Alert success";
      }
      case "warning": {
        return this.nzBanner ? "Banner warning" : "Alert warning";
      }
      case "error": {
        return this.nzBanner ? "Banner error" : "Alert error";
      }
      case "info":
      default:
        return this.nzBanner ? "Banner info" : "Alert info";
    }
  }

  /**
   * @internal
   */
  public closeAlert(): void {
    super.closeAlert();
    super.onFadeAnimationDone();
  }

  private setInferredIconType(): void {
    switch (this.nzType) {
      case "success": {
        this.inferredIconType = "verification";
        break;
      }
      case "warning": {
        this.inferredIconType = "attention";
        break;
      }
      case "error": {
        this.inferredIconType = "failed";
        break;
      }
      case "info":
      default:
        this.inferredIconType = "info";
    }
  }
}
