import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input } from "@angular/core";
import { NzListItemMetaAvatarComponent } from "ng-zorro-antd/list";
import { UiAvatarModule } from "@webapp/ui/avatar/avatar.module";

@Component({
  selector: "ui-list-item-meta-avatar",
  exportAs: "uiListItemMetaAvatar",
  templateUrl: "./list-item-meta-avatar.component.html",
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiAvatarModule],
})
export class UiListItemMetaAvatarComponent extends NzListItemMetaAvatarComponent {
  @Input("uiSrc") public nzSrc?: string;
}
