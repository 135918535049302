import { IComponentOptions, IController } from "angular";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { AccountResolverService } from "../../../state/account-resolver-service";
import { IUserMultiAccount, UserMultiAccountInvitationTypes } from "../../models";
import { MultiAccountService } from "../../services/multi-account-service";

export class AccountPickerCtrl implements IController {
  currentAccountId: string;
  currentUserEmail: string;
  accounts: IUserMultiAccount[];
  indicators: {
    loading: boolean;
  };
  resolve: {
    accounts: IUserMultiAccount[];
  };

  static $inject = ["AccountResolverService", "UserProfileService", "MultiAccountService"];
  constructor(
    private accountResolverService: AccountResolverService,
    private profileService: UserProfileService,
    private multiAccountService: MultiAccountService
  ) {}

  $onInit(): void {
    this.indicators = {
      loading: true,
    };
    this.currentAccountId = this.accountResolverService.getAccountId();
    this.currentUserEmail = this.profileService.getProfile().email;

    this.accounts = this.resolve.accounts
      .filter((account) => !account.invitation || (account.invitation && account.invitation.status === UserMultiAccountInvitationTypes.approved))
      .sort((acc1, acc2) => acc1.accountName.toLowerCase().localeCompare(acc2.accountName.toLowerCase()));

    this.indicators.loading = false;
  }

  switchAccount(account: IUserMultiAccount): void {
    this.multiAccountService.switchUserAccount(account, this.currentAccountId, this.currentUserEmail);
  }

  checkIfCurrentAccount(accountId: string): boolean {
    return accountId === this.currentAccountId;
  }

  displayBorderBottom(account: IUserMultiAccount, { isLast }: { isLast: boolean }): boolean {
    const isNotCurrent = !this.checkIfCurrentAccount(account.accountId);
    const isPrimary = !account.invitation;
    const isApproved = account.invitation && account.invitation.status === UserMultiAccountInvitationTypes.approved;

    return isNotCurrent && (isApproved || isPrimary) && isLast;
  }
}

export const AccountPickerComponent: IComponentOptions = {
  template: require("./account-picker.component.html"),
  controller: AccountPickerCtrl,
  bindings: {
    dismiss: "&",
    resolve: "<",
  },
};
