<ng-container *ngIf="!gettingSearchData">
  <search-result-items [maxItems]="maxItems" [searchMode]="searchMode" [isCustomClickHandlerAvailable]="isCustomClickHandlerAvailable" (keydown.esc)="escKeydown.emit()"></search-result-items>
  <no-search-items-found [searchMode]="searchMode"></no-search-items-found>
</ng-container>
<ng-container *ngIf="!!gettingSearchData">
  <div class="skeleton">
    <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 1 }"></ui-skeleton>
    <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 3 }"></ui-skeleton>
    <ui-skeleton [uiActive]="true" [uiParagraph]="{ rows: 2 }"></ui-skeleton>
  </div>
</ng-container>
