<!--Default Header Based on Design System-->
<div
  class="ant-collapse-header ui-collapse-panel-header"
  #collapseHeader
  [attr.aria-expanded]="nzActive"
  [attr.aria-label]="a11yLabel"
  [class.ui-collapse-panel-header-active]="nzActive"
  [attr.data-test-id]="'collapse-panel-header'"
  (keydown.space)="clickHeader()"
  (keydown.enter)="clickHeader()"
  role="tab"
  tabindex="0">
  <!--Arrow-->
  <ng-container *ngIf="isExpandable">
    <ng-container *nzStringTemplateOutlet="nzExpandedIcon; let expandedIcon">
      <i class="ant-collapse-arrow ui-collapse-arrow" *ngIf="nzActive" [uiType]="expandedIcon || getDefaultIcon(size)" [uiRotate]="90" ui-icon></i>
      <i class="ant-collapse-arrow ui-collapse-arrow" *ngIf="!nzActive" [uiType]="expandedIcon || getDefaultIcon(size)" [uiRotate]="0" ui-icon></i>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="isCustomHeader; then customHeader; else defaultHeader"></ng-container>
</div>

<ng-template #defaultHeader>
  <!--Header Text-->
  <ng-container *nzStringTemplateOutlet="nzHeader">{{ nzHeader }}</ng-container>
  <!--Extras-->
  <div class="ui-collapse-panel-header-extras" *ngIf="icon || labels">
    <div class="ui-collapse-panel-header-extras-icon">
      <i *ngIf="icon" [class]="icon" [uiType]="icon" ui-icon></i>
    </div>
    <div *ngIf="labels.length">
      <ui-label class="sp-mr-8" *ngFor="let label of labels" [uiColor]="label.color" [uiIcon]="label.icon" [attr.data-test-id]="'collapse-panel-header-label'">{{ label.text }}</ui-label>
    </div>
  </div>
  <!--ngZorro Extras-->
  <div class="ant-collapse-extra" *ngIf="nzExtra">
    <ng-container *nzStringTemplateOutlet="nzExtra">{{ nzExtra }}</ng-container>
  </div>
</ng-template>

<!--Custom Header-->
<ng-template #customHeader>
  <ng-content select="[header]"></ng-content>
</ng-template>

<div class="ant-collapse-content ui-collapse-panel-content" *ngIf="isExpandable" [class.ant-collapse-content-active]="nzActive">
  <div class="ant-collapse-content-box" *ngIf="nzActive" [attr.tabindex]="a11yTextContentOnly ? '0' : '-1'">
    <ng-content></ng-content>
  </div>
</div>
