import { StateService, UIRouterModule } from "@uirouter/angular";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiCardModule } from "@quantive/ui-kit/card";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable } from "rxjs";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { humanError } from "@gtmhub/error-handling/util";
import { localize } from "@gtmhub/localization";
import { getCurrentUserId } from "@gtmhub/users";
import { EditionFeatureDirective } from "@webapp/accounts/directives/edition-feature.directive";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { CheckInOverview, CheckInsWidgetService } from "@webapp/home/services/check-ins-widget/check-ins-widget.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { BillingInfoService } from "@webapp/navigation/services/billing-info.service";
import { ContainerEmptyStateComponent } from "@webapp/shared/components/container-empty-state/container-empty-state.component";
import dayjs from "@webapp/shared/libs/dayjs";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { BaseWidgetComponent } from "@webapp/ui/dashboard/components/base-widget.component";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { WidgetActionsComponent } from "../widget-actions/widget-actions.component";
import { WidgetSkeletonComponent } from "../widget-skeleton/widget-skeleton.component";

@UntilDestroy()
@Component({
  selector: "check-ins-widget",
  templateUrl: "./check-ins-widget.component.html",
  styleUrls: ["./check-ins-widget.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    CommonModule,
    UiAssigneeModule,
    UiButtonModule,
    AssigneesModule,
    UiIconModule,
    UiTooltipModule,
    UiCardModule,
    LocalizationModule,
    UIRouterModule,
    WidgetActionsComponent,
    UiLoadingIndicatorModule,
    WidgetSkeletonComponent,
    UiAlertModule,
    ContainerEmptyStateComponent,
    AnalyticsModule,
    EditionFeatureDirective,
  ],
  providers: [CheckInsWidgetService],
})
export class CheckInsWidgetComponent extends BaseWidgetComponent implements OnInit {
  public teams: CheckInOverview[] = [];
  public noteTypes: string[] = ["blockers", "kudos", "requests", "takeaways", "wins"];
  public loading = false;
  public error: string;
  private languageFromLocalStorage: string;
  public cadanceStart = dayjs().startOf("isoWeek").format("YYYY-MM-DD");

  public get currentUserId(): string {
    return getCurrentUserId();
  }

  public get title(): string {
    return localize("check_ins");
  }

  public get a11yLabel(): string {
    return "Check-ins";
  }

  public get showUpgradeButton$(): Observable<boolean> {
    return this.billingInfoService.getUpgradeShouldBeShown$();
  }

  constructor(
    private checkInsWidgetService: CheckInsWidgetService,
    private changeDetectorRef: ChangeDetectorRef,
    private billingInfoService: BillingInfoService,
    private state: StateService,
    private apmService: ApmService
  ) {
    super();
    this.apmService.startDataLoadSpan("check-ins-widget-init");
  }

  public ngOnInit(): void {
    this.loading = true;
    this.languageFromLocalStorage = this.checkInsWidgetService.languageFromLocalStorage;
    this.checkInsWidgetService
      .getCheckInsOverviews$()
      .pipe(untilDestroyed(this))
      .subscribe({
        next: (teams) => {
          this.teams = teams;
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.apmService.endDataLoadSpan("check-ins-widget-init");
        },
        error: (error) => {
          this.error = humanError(error);
          this.loading = false;
          this.changeDetectorRef.markForCheck();
          this.apmService.endDataLoadSpan("check-ins-widget-init");
        },
      });
  }

  public isGermanOrBulgarian(): boolean {
    return this.languageFromLocalStorage === "german" || this.languageFromLocalStorage === "bulgarian";
  }

  public constructTeamNameAriaLabel(currentTeam: CheckInOverview): string {
    return (
      "team:" +
      currentTeam?.name +
      " ," +
      currentTeam.filledOutCheckInsCount +
      " of " +
      localize("reflections_overview_published", { count: currentTeam.membersCount }) +
      " " +
      (currentTeam.hasCurrentUserFilledOut ? localize("filled_out") : currentTeam.dueOn)
    );
  }

  public onEnterKeyDown(teamId: string): void {
    this.state.go(".team", {
      teamId: teamId,
      activeTab: "check-ins",
    });
  }
}
