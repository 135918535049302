import { IComponentOptions } from "angular";
import { StateService } from "@uirouter/angular";
import { AssigneeActions } from "@gtmhub/assignees/redux/assignee-actions";
import { IAssigneesStoreState } from "@gtmhub/assignees/redux/assignee-reducer";
import { GtmhubController } from "@gtmhub/core";
import { INgRedux } from "@gtmhub/state-management";
import { IReduxTeam, ITeam, ITeamsStoreState, TeamsActions } from "@gtmhub/teams";
import { IdMap } from "@gtmhub/util";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { Employee } from "@webapp/employees";

interface IAssigneeDetailsPopoverComponentRedux {
  assignee?: Employee | ITeam;
  list?: Employee[] | ITeam[];
  assignees?: IdMap<Assignee>;
  teams?: IdMap<IReduxTeam>;
}

const getEmployeeTeams = (employee: Employee, teams: ITeam[]): ITeam[] => {
  if (!employee || !teams?.length) {
    return [];
  }

  return teams.filter((team) => team.members?.includes(employee.id));
};

/**
 * Provides a trigger and content for a popover with details about the targeted assignee (user/team) and a link to its profile.
 * Used by wrapping the targeted trigger element with the 'assignee-details-popover' selector. Clicking on the wrapped element will open the popover.
 *
 * @param assigneeId - specifies the targeted user or team ID : string;
 * @param assigneeType - specifies the targeted assignee type ('user' or 'team') : 'user' | 'team';
 * @param enabled (optional) - specifies whether the popover will be displayed on trigger click : boolean;
 * @param isOpen (optional) - specifies whether the popup will be opened or closed : boolean;
 */
export class AssigneeDetailsPopoverCtrl extends GtmhubController implements IAssigneeDetailsPopoverComponentRedux {
  public assigneeId: string;
  public assigneeType: "user" | "team";

  public enabled = true;
  public isOpen = false;

  public assignee: Employee | ITeam;
  public list: Employee[] | ITeam[];
  public assignees: IdMap<Assignee>;
  public teams: IdMap<ITeam>;

  public static $inject = ["$ngRedux", "$state", "TeamsActions", "AssigneeActions"];

  constructor(
    private ngRedux: INgRedux,
    private state: StateService,
    private teamActions: TeamsActions,
    private assigneeActions: AssigneeActions
  ) {
    super();
  }

  public $onInit(): void {
    if (!this.assigneeType) {
      return;
    }

    this.onDestroy(this.ngRedux.connect(this.bindStateToCtrl.bind(this))(this));
    this.ngRedux.dispatch(this.teamActions.fetchTeamsIfMissing());
    this.ngRedux.dispatch(this.assigneeActions.getAssignees());
  }

  public navigateToProfile(): void {
    if (!this.assigneeType) {
      return;
    }

    const goToUrl = this.assigneeType === "user" ? "gtmhub.employees.employee" : "gtmhub.teams.team";
    const goToUrlParamProp = this.assigneeType === "user" ? "employeeId" : "teamId";

    this.state.go(goToUrl, { [goToUrlParamProp]: this.assigneeId });
    this.close();
  }

  public close(): void {
    this.isOpen = false;
  }

  private bindStateToCtrl(state: ITeamsStoreState & IAssigneesStoreState): IAssigneeDetailsPopoverComponentRedux {
    // parse the data only in case both the teams and assignees maps have actually changed
    if (!state.teams.isFetched || !state.assignees.isFetched || (this.assignees === state.assignees.map && this.teams === state.teams.map)) {
      return {};
    }

    const maps = {
      assignees: state.assignees.map,
      teams: state.teams.map,
    };

    if (this.assigneeType === "user") {
      return {
        ...maps,
        assignee: state.assignees.map[this.assigneeId],
        list: getEmployeeTeams(state.assignees.map[this.assigneeId], state.teams.items),
      };
    }

    if (this.assigneeType === "team") {
      return {
        ...maps,
        assignee: { ...state.assignees.map[this.assigneeId], ...state.teams.map[this.assigneeId] },
        list: (state.teams.map[this.assigneeId]?.members || []).map((memberId) => state.assignees.map[memberId]),
      };
    }
  }
}

export const AssigneeDetailsPopoverComponent: IComponentOptions = {
  template: require("./assignee-details-popover.component.html"),
  controller: AssigneeDetailsPopoverCtrl,
  transclude: true,
  bindings: {
    assigneeId: "<",
    assigneeType: "<",
    enabled: "<?",
    isOpen: "=?",
  },
};
