import { StateParams, StateService, UIRouterGlobals } from "@uirouter/angular";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Inject, OnInit, Output, ViewChild } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { NzTreeNodeOptions } from "ng-zorro-antd/tree";
import { EMPTY, Observable, Subscription, catchError, take } from "rxjs";
import { UIErrorHandlingService, createUIError } from "@gtmhub/error-handling";
import { ContributorTypeEnum } from "@gtmhub/goals/models";
import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { filterOpenedActiveAndFutureSessions } from "@gtmhub/sessions/redux/session-selectors";
import { reduxStoreContainer } from "@gtmhub/state-management/state-management.module";
import { getCurrentUserId } from "@gtmhub/users";
import { IdMap } from "@gtmhub/util";
import { Flavor, recommendation } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { ReduxStoreObserver } from "@webapp/core/state-management/redux-store-observer";
import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { Goal } from "@webapp/okrs/goals/models/goal.models";
import { GoalsFacade } from "@webapp/okrs/goals/services/goals-facade.service";
import { Metric } from "@webapp/okrs/metrics/models/metric.models";
import { MetricsFacade } from "@webapp/okrs/metrics/services/metrics-facade.service";
import { IGetSearchParams, IParentSelectorNode, ParentOkrSelectorType } from "@webapp/okrs/models/parent-selector.models";
import { ParentSelectorFacade } from "@webapp/okrs/services/parent-selector-facade.service";
import { objectiveNode, suggestedSessionNode } from "@webapp/okrs/utils/okr-and-kpi-selector-nodes-builder.util";
import { Session } from "@webapp/sessions/models/sessions.model";
import {
  IMultiSelectorGoal,
  IMultiSelectorGoalNodes,
  IMultiSelectorNodeContext,
  IMultiSelectorNodesContext,
} from "@webapp/shared/components/multi-selector/multi-selector.models";
import { PeopleSelectorRequest } from "@webapp/shared/components/people-selector/models/models";
import { FormGroupComponent } from "@webapp/shared/form/form-group.component";
import dayjs from "@webapp/shared/libs/dayjs";
import { Task, TaskFormValue, TaskParentType, TaskStatus } from "@webapp/tasks/models/tasks.models";
import { TasksFacade } from "@webapp/tasks/services/task-facade.service";
import { UI_MODAL_DATA } from "@webapp/ui/modal/modal.models";

type TaskParentItem = (Goal | Metric) & { type?: ParentOkrSelectorType };

interface CreateTaskForm {
  name: string;
  ownerId: string;
  status: TaskStatus;
  dueDate: string;
  linkedTo: NzTreeNodeOptions;
  description: { note: string };
  customFields: CfMap;
}

interface FieldsToPreventDefault {
  name: boolean;
  dueDate: boolean;
  linkedTo: boolean;
}

@UntilDestroy()
@Component({
  selector: "create-task",
  templateUrl: "./create-task.component.html",
  styleUrls: ["./create-task.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CreateTaskComponent implements OnInit {
  private readonly fieldsToPreventDefault: FieldsToPreventDefault = {
    name: true,
    dueDate: true,
    linkedTo: true,
  };
  public readonly statusList: TaskStatus[];
  public parentType: TaskParentType;

  private parent: TaskParentItem;
  public selectedParent: NzTreeNodeOptions;
  public task: Task;
  public allOpenSessionsIds: string[];
  public indicators = {
    loading: true,
    creating: false,
  };

  @ViewChild(FormGroupComponent)
  public form: FormGroupComponent<CreateTaskForm>;

  public dueDate: Date;
  public readonly ownerRequest: PeopleSelectorRequest;
  public nodes: NzTreeNodeOptions[];
  public nodesLoading = false;

  private sessionsMap: IdMap<Session> = {};
  private goals: IMultiSelectorGoal[] = [];
  private stateParams: StateParams;

  @Output() public readonly dismiss: EventEmitter<null> = new EventEmitter();

  constructor(
    private metricsFacade: MetricsFacade,
    private goalsFacade: GoalsFacade,
    private tasksFacade: TasksFacade,
    private analyticsService: AnalyticsService,
    private broadcastService: BroadcastService,
    private stateService: StateService,
    private parentSelectorFacade: ParentSelectorFacade,
    private uiErrorHandlingService: UIErrorHandlingService,
    private cdr: ChangeDetectorRef,
    private sessionActions: PlanningSessionsActions,
    routerGlobals: UIRouterGlobals,
    @Inject(UI_MODAL_DATA) modalData: { parentType: TaskParentType }
  ) {
    this.stateParams = routerGlobals.params;
    this.ownerRequest = {
      ...new PeopleSelectorRequest(),
      type: "user",
      permissionSetting: { enabled: true, permissions: ["ManageTasks"] },
    };
    this.statusList = Object.values(TaskStatus);
    Object.assign(this, modalData);
  }

  public ngOnInit(): void {
    this.analyticsService.track("Create Task Form Opened");
    const reduxStoreObserver = new ReduxStoreObserver(reduxStoreContainer.reduxStore);
    reduxStoreContainer.reduxStore.dispatch(this.sessionActions.getSessionsIfMissing());
    reduxStoreObserver
      .whenFetched$<ISessionsStoreState>("sessions")
      .pipe(untilDestroyed(this))
      .subscribe(({ sessions }) => {
        this.sessionsMap = sessions.map;
        this.allOpenSessionsIds = filterOpenedActiveAndFutureSessions(sessions.items).map((session: Session) => session.id);
        this.setNewTaskInitialDetails();
        this.setNodes();

        if (this.task.parentId) {
          this.getParent(this.task.parentId, this.task.parentType as ParentOkrSelectorType);
          return;
        }

        this.indicators.loading = false;
      });
  }

  private setNodes(): void {
    this.nodesLoading = true;

    this.getGoalsRequest(this.allOpenSessionsIds).subscribe((goals) => {
      this.goals = goals;

      this.buildTreeNodes();
      this.nodesLoading = false;
      this.cdr.markForCheck();
    });
  }

  public onKeydown(event: KeyboardEvent, field: keyof FieldsToPreventDefault): void {
    const isEnterKey = event.key === "Enter";

    if (!isEnterKey || !this.fieldsToPreventDefault[field]) return;

    event.preventDefault(); // prevents opening the Cf Drop-down menu when hitting Enter
  }

  public submitForm = (): void => {
    if (this.form.invalid) {
      return this.form.formGroup.markAsTouched();
    }

    this.createTask();
  };

  private createTask(): void {
    this.indicators.creating = true;

    this.tasksFacade
      .create$(this.task)
      .pipe(
        take(1),
        untilDestroyed(this),
        catchError((err) => {
          this.uiErrorHandlingService.handleModal(createUIError(err));
          return EMPTY;
        })
      )
      .subscribe((newTask: Task) => {
        this.trackRecommendationHelpfulness(newTask);
        this.stateService.go(".task", { taskId: newTask.id }, { location: "replace" });
        this.broadcastService.emit("taskCreated", { task: newTask, sessionId: this.parent ? this.parent.sessionId : null });
      });
  }

  private trackRecommendationHelpfulness(task: Task): void {
    // recommendation is helpful if user creates task and does not remove himself as an owner
    this.analyticsService.track(recommendation, { is_useful: task.ownerId === getCurrentUserId(), flavor: Flavor.SMART_DEFAULT, action: "creates_task;owner" });
  }

  private getParentId(): string {
    if (this.parentType === ContributorTypeEnum.metric) {
      return this.stateParams.metricId;
    }

    // defaults to parent type 'goal'
    return this.stateParams.parentId || this.stateParams.id || "";
  }

  private getParent(parentId: string, parentType: ParentOkrSelectorType): Subscription {
    const getParentObservable: Observable<ICollection<Metric> | ICollection<Goal>> =
      parentType === ContributorTypeEnum.metric
        ? this.metricsFacade.getMetricsV2$({ filter: { _id: parentId } })
        : this.goalsFacade.getAll$({ filter: { _id: parentId }, fields: ["name", "sessionId"] });

    return getParentObservable
      .pipe(
        take(1),
        untilDestroyed(this),
        catchError((err) => {
          this.uiErrorHandlingService.handleModal(createUIError(err));
          return EMPTY;
        })
      )
      .subscribe((parentItem) => {
        this.parent = { ...parentItem.items[0], type: parentType };
        this.selectedParent = { id: this.task.parentId, type: this.task.parentType, title: this.parent.name || "", key: this.task.parentId };
        this.indicators.loading = false;
        this.cdr.markForCheck();
      });
  }

  private setNewTaskInitialDetails(): void {
    this.task = <Task>{
      name: "",
      description: "",
      ownerId: getCurrentUserId(),
      status: TaskStatus.toDo,
      parentId: this.getParentId(),
      parentType: this.parentType,
      dueDate: null,
    };
  }

  private getGoalsRequest(sessionIds: string[]): Observable<IMultiSelectorGoal[]> {
    return this.parentSelectorFacade
      .getParentSelectionItems$({
        sessionIds: sessionIds,
        ownerIds: [this.task.ownerId],
      })
      .pipe(untilDestroyed(this));
  }

  public searchingFunc = (searchTerm: string): Observable<NzTreeNodeOptions[]> => {
    const config: IGetSearchParams = {
      searchTerm,
      sessionIds: this.allOpenSessionsIds,
    };

    return this.parentSelectorFacade.getParentSelectionItemsBySearchTerm$(config);
  };

  private buildTreeNodes(): void {
    let alreadyExpandedGoal = false;

    this.nodes = this.allOpenSessionsIds.reduce((sessionNodes, sessionId) => {
      const { goalNodes, hasExpandedGoal } = this.buildGoalNodes({ sessionId, hasExpandedGoal: alreadyExpandedGoal });
      alreadyExpandedGoal = hasExpandedGoal;

      if (goalNodes.length > 0) {
        sessionNodes.push(suggestedSessionNode({ sessionId, goalNodes, sessionsMap: this.sessionsMap }));
      }

      return sessionNodes;
    }, []);
  }

  private buildGoalNodes({ sessionId, hasExpandedGoal = false }: IMultiSelectorNodesContext): IMultiSelectorGoalNodes {
    const goalNodes = this.getGoalsBySessionId(sessionId).map((goal) => {
      const goalNode = this.buildTreeGoalNode({ goal, hasExpandedGoal });

      if (goalNode.expanded) {
        hasExpandedGoal = true;
      }

      return goalNode;
    });

    return { goalNodes, hasExpandedGoal };
  }

  private buildTreeGoalNode({ goal, hasExpandedGoal }: IMultiSelectorNodeContext): IParentSelectorNode {
    const goalNode = objectiveNode(goal);
    goalNode.expanded = !hasExpandedGoal && this.canExpandGoalNode(goalNode);
    return goalNode;
  }

  private canExpandGoalNode(goalNode: IParentSelectorNode): boolean {
    const hasSelectedParentItem = Boolean(this.parent?.id);
    const hasChildren = Boolean(goalNode.children);
    const hasSelectedMetric = goalNode.children?.some((metric) => metric.key === this.parent?.id);

    return (!hasSelectedParentItem && hasChildren) || hasSelectedMetric;
  }

  private getGoalsBySessionId(sessionId: string): IMultiSelectorGoal[] {
    return this.goals.filter((goal) => goal.sessionId === sessionId);
  }

  public onOpenChange(isOpen: boolean): void {
    if (isOpen) {
      this.setNodes();
    }
  }

  public onDescriptionUpdate(): void {
    if (this.form.value.description.note === this.task.description) {
      return;
    }

    this.task.description = this.form.value.description.note;
  }

  public handleFormValueChange(event: TaskFormValue): void {
    const { name, value } = event.updatedControl;

    switch (name) {
      case "name":
        this.task.name = value as string;
        break;
      case "ownerId":
        this.task.ownerId = value[0];
        break;
      case "status":
        this.task.status = value as TaskStatus;
        break;
      case "dueDate":
        this.task.dueDate = dayjs(value as string).format();
        break;
      case "linkedTo":
        this.task.parentId = (value as NzTreeNodeOptions)?.key ?? "";
        this.task.parentType = (value as NzTreeNodeOptions)?.type ?? null;
        break;
      case "customFields":
        this.task.customFields = value as CfMap;
        break;
    }

    this.cdr.detectChanges();
  }
}
