import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnInit, Output } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiDropDownDirective, UiDropdownMenuComponent } from "@quantive/ui-kit/dropdown";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiMenuDirective } from "@quantive/ui-kit/menu";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { take } from "rxjs";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { IRole } from "@gtmhub/roles";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { getCurrentUserId } from "@gtmhub/users";
import { toIdMap } from "@gtmhub/util";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { Assignee } from "@webapp/assignees/models/assignee.models";
import { AssigneesFacade } from "@webapp/assignees/services/assignees/assignees-facade.service";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { OkrViewsCopyLinkFormComponent } from "@webapp/okrs/okr-views/components/okr-views-copy-link-form/okr-views-copy-link-form/okr-views-copy-link-form.component";
import { AccessChange, AccessOption, ShareableLink, SimplePermissionsAccessType, principalKindByCollectionMap } from "@webapp/permissions/models/permissions.model";
import { SimplePermissionsMediator } from "@webapp/permissions/services/simple-permissions.mediator";
import { determineAccessType } from "@webapp/permissions/utils/simple-permissions.utils";
import { RolesRepository } from "@webapp/roles/services/roles-repository.service";
import { SimpleSearchComponent } from "@webapp/search/components/simple-search/simple-search.component";
import { Search, SearchCollection, SearchFacetsOptions, SearchFacetsOptionsEnum } from "@webapp/search/models/search.models";
import { IAccess, IApiPermission } from "@webapp/sessions/models/sessions.model";
import { UiAssigneeModule } from "@webapp/ui/assignee/assignee.module";
import { UiRadioModule } from "@webapp/ui/radio/radio.module";
import { checkOverflow } from "@webapp/ui/tooltip/tooltip.utils";

type Principal = (Assignee | IRole) & { inUse?: boolean };

@UntilDestroy()
@Component({
  selector: "simple-permissions",
  templateUrl: "./simple-permissions.component.html",
  styleUrls: ["./simple-permissions.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    UiRadioModule,
    UiIconModule,
    LocalizationModule,
    UiAssigneeModule,
    AssigneesModule,
    UiTooltipModule,
    SimpleSearchComponent,
    UiDropDownDirective,
    UiDropdownMenuComponent,
    UiMenuDirective,
    OkrViewsCopyLinkFormComponent,
  ],
})
export class SimplePermissionsComponent implements OnInit {
  public searchCollections: SearchFacetsOptions[] = [SearchFacetsOptionsEnum.Teams, SearchFacetsOptionsEnum.Employees, SearchFacetsOptionsEnum.Roles];
  public principalsMap: { [id: string]: Principal };
  public selectedAccessOption: AccessOption;
  private principalsInUse: { [id: string]: boolean };
  private principals: Principal[];
  public accessTypes = SimplePermissionsAccessType;
  public isPermissionDropdownVisible = false;
  public accessOptions: AccessOption[] = [
    { icon: "restricted", labelKey: "private_access_only_you", value: SimplePermissionsAccessType.privateAccess },
    { icon: "users-group", labelKey: "private_access_only_invited", value: SimplePermissionsAccessType.restricted },
    { icon: "view-only", labelKey: "everyone_can_view", value: SimplePermissionsAccessType.publicRead },
    { icon: "inline", labelKey: "everyone_can_edit", value: SimplePermissionsAccessType.publicUpdate },
  ];

  @Input()
  public access: IAccess;

  @Input()
  public ownerId: string;

  @Input()
  public showPermissions: boolean;

  @Input()
  public compactMode?: boolean = false;

  @Input()
  public shareableLink: ShareableLink;

  @Input()
  public tabIndex = 0;

  @Input()
  public isGrayBackground = false;

  @Output()
  public readonly accessChange: EventEmitter<AccessChange> = new EventEmitter();

  @Output()
  public readonly toggle: EventEmitter<{ isVisible: boolean }> = new EventEmitter();

  constructor(
    private assigneesFacade: AssigneesFacade,
    private uiErrorHandlingService: UIErrorHandlingService,
    private accountResolverService: AccountResolverService,
    private cdRef: ChangeDetectorRef,
    private simplePermissionsMediator: SimplePermissionsMediator,
    private analyticsService: AnalyticsService,
    private rolesRepository: RolesRepository
  ) {}

  public ngOnInit(): void {
    this.determineAccessType();
    this.getRoles();

    this.simplePermissionsMediator.showPermissions$.pipe(untilDestroyed(this)).subscribe((showPermissions) => {
      this.showPermissions = showPermissions;
      this.cdRef.markForCheck();
    });
  }

  @HostBinding("class.gray-background")
  public get backgroundClass(): boolean {
    return this.isGrayBackground;
  }

  private determineAccessType(): void {
    const accessType: SimplePermissionsAccessType = determineAccessType(this.access);

    this.selectedAccessOption = this.accessOptions.find((o) => o.value === accessType);
  }

  private getRoles(): void {
    const assignees = this.assigneesFacade.getAssigneesIdMap();

    this.rolesRepository
      .getAll$()
      .pipe(take(1), untilDestroyed(this))
      .subscribe({
        next: (roles) => {
          this.principals = [...Object.values(assignees), ...roles.items];
          this.principalsMap = { ...assignees, ...toIdMap(roles.items) };

          this.markUsedPrincipals();
        },
        error: (error) => {
          this.uiErrorHandlingService.handleModal(error);
        },
      });
  }

  private markUsedPrincipals(): void {
    this.principalsInUse = {};

    for (const p of this.access.permissions) {
      this.principalsInUse[p.principalId] = true;
    }

    for (const principal of this.principals) {
      principal.inUse = !!this.principalsInUse[principal.id];
    }
  }

  public trackByPrincipalId(index: number, permission: IApiPermission): string {
    return permission.principalId;
  }

  public changeGrant(changeTo: string, permission: IApiPermission): void {
    const newPermissions = this.access.permissions.map((p) => {
      if (p.principalId !== permission.principalId) return p;

      if (changeTo === "read") return { ...p, grant: { general: ["read"] } } as IApiPermission;

      if (changeTo === "update") return { ...p, grant: { general: ["read", "update"] } } as IApiPermission;

      return p;
    });

    this.access = { ...this.access, permissions: newPermissions };

    this.isPermissionDropdownVisible = false;

    this.cdRef.detectChanges();

    this.analyticsService.track("Access Changed", {
      id: permission.principalId,
      property_type: permission.principalKind,
      from: permission.grant.general.join(","),
      to: changeTo === "read" ? "read" : "read,update",
      role: this.getRoleToTrack(permission),
    });

    this.accessChange.emit({ access: this.access });
  }

  public changeAccessType(): void {
    const type = this.selectedAccessOption.value;
    const userPermissions: string[] = ["read", "update", "delete", "create", "modifyPermissions"];

    if (type === SimplePermissionsAccessType.privateAccess) {
      this.access = this.createAccessObject();
    } else if (type === SimplePermissionsAccessType.restricted) {
      this.access = this.createAccessObject(userPermissions);
    } else if (type === SimplePermissionsAccessType.publicRead) {
      const accountPermissions: string[] = ["read"];

      this.access = this.createAccessObject(userPermissions, accountPermissions);
    } else {
      const accountPermissions: string[] = ["read", "update"];

      this.access = this.createAccessObject(userPermissions, accountPermissions);
    }

    this.determineAccessType();

    this.accessChange.emit({ access: this.access, accessType: type });
  }

  public addPrincipal(value: Search<SearchCollection>): void {
    if (this.access.permissions.some((p) => p.principalId === value.id)) {
      return;
    }

    const newPermission: IApiPermission = {
      principalKind: principalKindByCollectionMap.get(value.collectionName),
      grant: {
        general: ["read"],
      },
      principalId: value.id,
    };
    this.access = { ...this.access, permissions: [...this.access.permissions, newPermission] };
    this.markUsedPrincipals();

    this.cdRef.detectChanges();

    this.analyticsService.track("Access Given", {
      id: newPermission.principalId,
      property_type: newPermission.principalKind,
      permissions: newPermission.grant.general.join(","),
      role: this.getRoleToTrack(newPermission),
    });

    this.accessChange.emit({ access: this.access });
  }

  public removePermission(permission: IApiPermission): void {
    this.access = { ...this.access, permissions: [...this.access.permissions.filter((p) => p.principalId !== permission.principalId)] };
    this.markUsedPrincipals();

    this.isPermissionDropdownVisible = false;

    this.cdRef.detectChanges();

    this.analyticsService.track("Access Removed", {
      id: permission.principalId,
      property_type: permission.principalKind,
      permissions: permission.grant.general.join(","),
      role: this.getRoleToTrack(permission),
    });

    this.accessChange.emit({ access: this.access });
  }

  public checkOverflow(id: string): boolean {
    return checkOverflow(id);
  }

  public changePermissionsVisibility(): void {
    this.showPermissions = !this.showPermissions;

    if (this.toggle) {
      this.toggle.emit({ isVisible: this.showPermissions });
    }
  }

  private createAccessObject(userPermissions: string[] = [], accountPermissions: string[] = []): IAccess {
    const permissions = [];

    if (userPermissions.length > 0) {
      permissions.push({
        principalKind: "user",
        grant: {
          general: userPermissions,
        },
        principalId: getCurrentUserId(),
      });
    }

    if (accountPermissions.length > 0) {
      permissions.push({
        principalKind: "account",
        grant: {
          general: accountPermissions,
        },
        principalId: this.accountResolverService.getAccountId(),
      });
    }

    return {
      inherits: false,
      permissions,
    };
  }

  private getRoleToTrack(permission: IApiPermission): string {
    if (permission.principalKind === "role") {
      const role = this.principals.find((principal) => principal.id === permission.principalId);
      return (role as IRole)?.accountId ? "custom_role" : role?.name;
    }
  }
}
