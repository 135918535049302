import { Directionality } from "@angular/cdk/bidi";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, Optional } from "@angular/core";
import { NzCollapseComponent } from "ng-zorro-antd/collapse";
import { NzConfigService, WithConfig } from "ng-zorro-antd/core/config";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { InputBoolean } from "ng-zorro-antd/core/util";

@Component({
  selector: "ui-collapse",
  exportAs: "uiCollapse",
  templateUrl: "./collapse.component.html",
  styleUrls: ["./collapse.component.less"],
  host: {
    class: "ui-collapse",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzDestroyService],
  standalone: true,
})
export class UiCollapseComponent extends NzCollapseComponent {
  @Input("collapseAccordion") @WithConfig() @InputBoolean() public nzAccordion = false;
  @Input("collapseBordered") @WithConfig() @InputBoolean() public nzBordered = true;
  @Input("collapseGhost") @WithConfig() @InputBoolean() public nzGhost = true;
  @Input("collapseExpandIconPosition") public nzExpandIconPosition: "start" | "end" = "start";

  constructor(nzConfigService: NzConfigService, cdr: ChangeDetectorRef, @Optional() directionality: Directionality, destroy$: NzDestroyService) {
    super(nzConfigService, cdr, directionality, destroy$);
  }
}
