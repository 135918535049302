import { IAugmentedJQuery, IComponentOptions, IOnChangesObjectOf, IPromise, IQService, IScope, ITimeoutService } from "angular";
import { AssigneeActions, IAssigneesStoreState } from "@gtmhub/assignees";
import { getFilteredAssigneesRedux } from "@gtmhub/assignees/redux/assignee-selectors";
import { GtmhubController } from "@gtmhub/core";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { IUIError, UIErrorHandlingService } from "@gtmhub/error-handling";
import { IGoalsPerOwnerSettingUI } from "@gtmhub/goals/components/facades/okr-methodology.facade";
import { PlanningSessionService } from "@gtmhub/sessions/services/planning-session.service";
import { blurFocusedElement } from "@gtmhub/shared/dom";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { teamToAssignee, userToAssignee } from "@gtmhub/shared/utils";
import { INgRedux } from "@gtmhub/state-management";
import { ITeam, TeamService } from "@gtmhub/teams";
import { IUser, UserService } from "@gtmhub/users";
import { getRandomColor } from "@gtmhub/util/color";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { Assignee, AssigneeSelectorType, UnknownAssignee } from "@webapp/assignees/models/assignee.models";
import { deletedAssignee } from "@webapp/assignees/utils/assignee.utils";
import { IPlanningSessionStatsAssignee } from "@webapp/sessions/models/sessions.model";
import { assignIsRestrictedPropToAssignee } from "../../utils/user-selector-utils";
import { UserSelectorEvents } from "./user-selector.events";

const DEFAULT_POPUP_OPEN_DELAY = 500;
const DEFAULT_LIST_LIMIT = 5;

export type IAssigneeExtended = Assignee & {
  objectivesLimitReached?: boolean;
  error?: string;
  isRestricted?: boolean;
};

interface IUserSelectorComponentRedux {
  assignees: Record<string, Assignee>;
  assigneesFetched: boolean;
  assigneesError: IUIError;
}
export interface IUserSelectorBindings {
  requiredPermission?: string;
  assignPermissions: AssigneeSelectorType;
  ids: string[];
  disabled: boolean;
  atLeastOneId: boolean;
  onlyOneId: boolean;
  goalsPerOwnerSetting?: IGoalsPerOwnerSettingUI;
  disallowRestrictedAssignees: boolean;
  translationKey?: string;
  isUserInvitationDisabled?: boolean;
  restoreSelectionOnBlur?: boolean;
  initialLimit?: number;
  queryPresentLimit?: number;
  showSelectedAsigneesInList?: boolean;
  popupOpenDelay?: number;
  hidePlaceholderOnBlur?: boolean;
  hideDefaultPlaceholder?: boolean;
  hiddenAssignees?: string[];
  showDetailsPopover?: boolean;
  withEllipsis?: boolean;
  hideRestrictedAssignees?: boolean;

  onUpdate(ids: { ids: string[] }): void;
  tagClick?(tag: { tag: Assignee }): void;
  emitFocus?(): void;
}

const bindStateToCtrl = (state: IAssigneesStoreState): IUserSelectorComponentRedux => {
  return {
    assignees: state.assignees.map,
    assigneesFetched: state.assignees.isFetched,
    assigneesError: state.assignees.error,
  };
};

/**
 * @example <user-selector ids="$ctrl.goal.ownerIds" assign-permissions="both" on-update="$ctrl.onOwnersChanged(ids)"></user-selector>
 * Creates an input for choosing multiple assignees and emiting it back to the component from which it's been called.
 *
 * @param requiredPermission specifies what type of permission will be used to determine whether a user is restricted or not : string
 * @param assignPermissions specifies what type of assignees will be shown : "user" | "team" | "both"
 * @param translationKey the text inside the input field when the input is empty
 * @param ids of the already listed users : string[]
 * @param onUpdate emits the ids to the parent controller on certain events : (ids: { ids: string[] }) => void;
 * @param atLeastOneId indicates whether to hide the remove button if only one user is selected : boolean
 * @param disabled indicates if the input should be disabled : boolean
 * @param disallowRestrictedAssignees if set to true, it marks restricted assignees and makes them unable to be selected
 * @param restoreSelectionOnBlur if set to true, the removed tag on input click in single selection mode is restored on blur, when no new item is selected : boolean;
 * @param initialLimit the maximal number of items that will be shown on popup open when there's no filter query (defaults to 5) : number;
 * @param queryPresentLimit the maximal number of items that will be shown in the popup when there is a filter query (when not provided, fall-backs to initialLimit) : number;
 * @param showSelectedAsigneesInList specifies whether the already selected items will be shown in the list as well : boolean;
 * @param popupOpenDelay specifies the delay after which the popup will open after tags input focus : number;
 * @param hidePlaceholderOnBlur if set to true, the provided placeholder text will be hidden in favor of the default empy text (-) when the component is not focused : boolean;
 * @param hideDefaultPlaceholder if set to true, the default empy text (-) will be hidden in favor of the provided placeholder text : boolean;
 * @param hiddenAssignees specifies an array of assignee IDs which will not be displayed in the user-selector : string[];
 * @param showDetailsPopover specifies whether the assignee details popover will be shown on tag click : boolean;
 * @param focusInput focuses the input : boolean;
 * @param withEllipsis adds ellipsis to the placeholder text : boolean;
 * @param tagClick emits the clicked tag Assignee object to the parent controller : (tag: { tag: Assignee }) => void;
 * @param emitFocus emits that the input has been focused
 * @param hideRestrictedAssignees if set to true, the restricted assignees will not be shown in the list
 */

export interface UserSelectorCtrl extends IUserSelectorBindings, IUserSelectorComponentRedux {}
export class UserSelectorCtrl extends GtmhubController {
  placeholder: string;
  ellipsis = "";
  scopeIds: string[];
  selectedAssignees: (IAssigneeExtended | UnknownAssignee)[];
  assignees: Record<string, Assignee>;
  restoreSelectionOnBlur: boolean;
  initialLimit: number;
  queryPresentLimit: number;
  showSelectedAsigneesInList: boolean;
  popupOpenDelay: number;
  hidePlaceholderOnBlur: boolean;
  hideDefaultPlaceholder: boolean;
  hiddenAssignees: string[];
  showDetailsPopover: boolean;
  focusInput: boolean;
  withEllipsis: boolean;

  get currentResultsListLimit(): number {
    const useDifferentLimitWhenSearching = this.queryPresentLimit && this.searchQueryPresent;
    const targetLimit = useDifferentLimitWhenSearching ? this.queryPresentLimit : this.initialLimit;

    return targetLimit || DEFAULT_LIST_LIMIT;
  }

  // a map that keeps track of all open assignee details popovers (updated via two-way data binding)
  // used to force close targeted popovers
  assigneeDetailsOpenPopovers = {};

  isFocused = false;
  indicators: {
    invitation: boolean;
  };
  isOneIdSelected: boolean;
  private userSelectorEvents: UserSelectorEvents;
  static $inject = [
    "$scope",
    "$ngRedux",
    "AssigneeActions",
    "TeamService",
    "AnalyticsService",
    "UIErrorHandlingService",
    "PlanningSessionService",
    "$q",
    "$timeout",
    "$rootScope",
    "UserService",
    "$element",
  ];

  private lastSelection: (IAssigneeExtended | UnknownAssignee)[];
  private searchQueryPresent: boolean;

  // this is for the case where we start with no ids, but after the first time we choose one, if we click on the input to reset it
  // and not choose another one, just return the first choice
  private firstInputChoice: (IAssigneeExtended | UnknownAssignee)[];

  constructor(
    private $scope: IScope & IExtendedRootScopeService,
    $ngRedux: INgRedux,
    assigneeActions: AssigneeActions,
    private teamService: TeamService,
    private analyticsService: AnalyticsService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private planningSessionService: PlanningSessionService,
    private $q: IQService,
    private $timeout: ITimeoutService,
    private $rootScope: ITraceRootScopeService,
    private userService: UserService,
    private element: IAugmentedJQuery
  ) {
    super();
    this.onDestroy($ngRedux.connect(bindStateToCtrl)(this));
    $ngRedux.dispatch(assigneeActions.getAssignees());
    this.userSelectorEvents = new UserSelectorEvents($scope);
    this.userSelectorEvents.onSelectedUsersChange(this.changeSelectedUsers);
  }

  $onInit(): void {
    this.scopeIds = angular.copy(this.ids || []);
    this.indicators = {
      invitation: false,
    };

    this.$scope
      .watchUntil(() => this.assigneesFetched || this.assigneesError)
      .then(() => (this.assigneesError ? this.uiErrorHandlingService.handleModal(this.assigneesError) : this.init()));
  }

  $onChanges(onChangesObj: IOnChangesObjectOf<IUserSelectorBindings>): void {
    if (
      onChangesObj.ids &&
      Array.isArray(onChangesObj.ids.currentValue) &&
      Array.isArray(onChangesObj.ids.previousValue) &&
      onChangesObj.ids.currentValue.toString() !== onChangesObj.ids.previousValue.toString()
    ) {
      this.scopeIds = onChangesObj.ids.currentValue;
      this.processAssignees();
    }

    if (onChangesObj.popupOpenDelay && angular.isUndefined(onChangesObj.popupOpenDelay.currentValue)) {
      this.popupOpenDelay = DEFAULT_POPUP_OPEN_DELAY;
    }
  }

  init = (): void => {
    this.processAssignees();
  };

  emitIds(): void {
    if (this.atLeastOneId && !this.selectedAssignees.length) {
      this.processAssignees();
      return;
    }

    const ids = this.selectedAssignees.map((assignee) => assignee.id);
    this.onUpdate({ ids });
  }

  processAction(type: "create_team" | "invite_user", query: string): void {
    if (type === "create_team") {
      this.createTeam(query);
    } else {
      this.toggleInvitation();
    }
  }

  handleTagElementClick(event: PointerEvent, popoverId: string): void {
    if (this.showDetailsPopover && !this.isFocused) {
      // prevents the autocomplete popup list from opening
      event.stopPropagation();

      // keeps only the newly clicked popover open and force closes all others
      // closes the clicked tag popover if it was already open
      this.assigneeDetailsOpenPopovers = this.assigneeDetailsOpenPopovers[popoverId] ? {} : { [popoverId]: true };
    }
  }

  handleFocus(): void {
    this.isFocused = true;
    this.element.addClass("user-selector-focused");

    if (this.indicators.invitation) {
      this.toggleInvitation();
    }

    if (this.emitFocus) {
      this.emitFocus();
    }

    this.resetSelected();
  }

  handleBlur(): void {
    this.isFocused = false;
    this.element.removeClass("user-selector-focused");

    if (this.restoreSelectionOnBlur) {
      this.restoreLastSelection();
    }

    if (this.firstInputChoice && this.firstInputChoice.length && !this.selectedAssignees.length) {
      this.selectedAssignees = this.firstInputChoice;
    }

    this.emitIds();
    this.setPlaceholder({ showInitially: this.selectedAssignees.length === 0, focused: false });
  }

  toggleInvitation(): void {
    this.indicators.invitation = !this.indicators.invitation;
  }

  resetSelected(): void {
    if (this.restoreSelectionOnBlur) {
      this.lastSelection = angular.copy(this.selectedAssignees);
    }

    if (this.onlyOneId) {
      this.firstInputChoice = this.atLeastOneId ? this.selectedAssignees : [];
      this.selectedAssignees = [];
      this.isOneIdSelected = false;
    }

    this.setPlaceholder({ showInitially: this.selectedAssignees.length === 0, focused: true });
  }

  validateOneId(): boolean {
    if (this.onlyOneId) {
      // since the ng-tags-input library triggers a focus with a timeout on tag-added event,
      // there is a need of delay so the force blur below to be executed after the focus
      this.$timeout(() => {
        blurFocusedElement();
        this.isOneIdSelected = true;
      }, 50);
    }

    this.emitIds();

    return true;
  }

  onUserInvited(user: IUser): void {
    this.indicators.invitation = false;
    this.selectedAssignees.push(userToAssignee(user));
    this.scopeIds.push(user.id);
    this.onUpdate({ ids: this.scopeIds });

    this.analyticsService.track("Created User From Assignee Selector");
  }

  searchAssignees(searchTerm: string): IPromise<IAssigneeExtended[]> {
    // use a flag to allow using different list result limits when there's a search query, and when there's not
    this.searchQueryPresent = !!searchTerm;

    const assignees = this.getAssigneesSearchSource();

    let assigneesArr: IAssigneeExtended[] = getFilteredAssigneesRedux({
      assignees: Object.values(assignees),
      assigneeName: searchTerm,
      assigneeType: this.assignPermissions || "both",
      limit: this.currentResultsListLimit,
    });

    const assigneeIds = assigneesArr.map((assignee) => assignee.id).toString();
    const assigneeUserIds = assigneesArr.filter((assignee) => assignee.type === "user").map((assignee) => assignee.id);
    const sessionStatsFilteringNeeded: boolean =
      this.goalsPerOwnerSetting &&
      this.goalsPerOwnerSetting.sessionId &&
      typeof this.goalsPerOwnerSetting.limit === "number" &&
      isFinite(this.goalsPerOwnerSetting.limit);
    const usersFilteringNeeded: boolean = this.disallowRestrictedAssignees && assigneeUserIds.length > 0;

    if (!sessionStatsFilteringNeeded && !usersFilteringNeeded) {
      return this.$q.resolve(assigneesArr);
    }

    return this.$rootScope.traceAction("search_assignees", () => {
      // Optimization is possible if needed after testing, by creating an id map
      // of each already made request to keep track whether we know the session stats
      // for an assignee or we need to make another request to get them
      // TODO: we need to optimize this, the request takes 10 sec
      const assigneeStatsPromise = sessionStatsFilteringNeeded ? this.planningSessionService.statsAssignees(this.goalsPerOwnerSetting.sessionId, { assigneeIds }) : null;

      const userReqParams = { filter: { _id: { $in: assigneeUserIds } }, page: 1, fields: "id,permissions" };
      const usersPromise = usersFilteringNeeded ? this.userService.getUsersV2(userReqParams) : null;
      return this.$q.all({ users: usersPromise, assigneesStats: assigneeStatsPromise }).then(
        ({ users, assigneesStats }) => {
          if (users) {
            const defaultRequiredPermission = "ManageGoals";
            assignIsRestrictedPropToAssignee({ assignees: assigneesArr, users: users.items, requiredPermission: this.requiredPermission || defaultRequiredPermission });
            if (this.hideRestrictedAssignees) {
              assigneesArr = assigneesArr.filter((assignee) => {
                return !assignee.isRestricted;
              });
            }
          }

          if (assigneesStats) {
            this.processAssigneesStats(assigneesArr, assigneesStats);
          }

          return assigneesArr;
        },
        () => {
          // return an empty array so the ng-tags-input autocomplete list won't populate
          // and the user will trigger a new request after typing in the input
          return [];
        }
      );
    });
  }

  validateAssigneePermissions(assignee: IAssigneeExtended): boolean {
    if (assignee.objectivesLimitReached) {
      return false;
    }
    if (assignee.isRestricted) {
      return false;
    }
  }

  setPlaceholder(params: { showInitially?: boolean; focused: boolean }) {
    //  check tests in 'changing the placeholder in the input boxes with setPlaceholder'
    if (!this.translationKey || (!params.focused && this.hidePlaceholderOnBlur)) {
      this.placeholder = " ";
      this.ellipsis = "";
      return;
    }

    if (this.onlyOneId && this.selectedAssignees.length) {
      this.placeholder = " ";
      this.ellipsis = "";
      return;
    }

    if (!params.showInitially && !params.focused) {
      this.placeholder = " ";
      this.ellipsis = "";
      return;
    }

    this.placeholder = this.translationKey;
    this.ellipsis = this.withEllipsis ? "..." : "";
  }

  private getAssigneesSearchSource(): Record<string, Assignee> {
    const assignees = angular.copy(this.assignees);

    if (!this.showSelectedAsigneesInList) {
      this.selectedAssignees.forEach(({ id }) => delete assignees[id]);
    }

    if (this.hiddenAssignees && this.hiddenAssignees.length > 0) {
      this.hiddenAssignees.forEach((id) => delete assignees[id]);
    }

    return assignees;
  }

  private restoreLastSelection(): void {
    if (this.onlyOneId && this.selectedAssignees.length === 0) {
      this.selectedAssignees = angular.copy(this.lastSelection);
    }
  }

  private processAssigneesStats(assignees: IAssigneeExtended[], stats: IPlanningSessionStatsAssignee[]): void {
    for (const assignee of assignees) {
      const assigneeStats = stats.find((stat) => assignee.id === stat.assigneeId);
      if (assigneeStats && assigneeStats.planningSessionStats.goalCount >= this.goalsPerOwnerSetting.limit) {
        assignee.objectivesLimitReached = true;
        // localize
        assignee.error = `Already owns ${this.goalsPerOwnerSetting.limit} OKR${this.goalsPerOwnerSetting.limit > 1 ? "s" : ""}`;
      }
    }
  }

  private createTeam(query: string): void {
    const color = getRandomColor();
    // to do: remove this TS cheat when refactoring
    // id is mandatory in ITeam so we need to create a new interface for a new team but this involves some refactoring
    const team = <ITeam>{
      name: query,
      description: "",
      color,
      avatar: "users_multiple-11",
      customFields: {},
    };

    this.teamService.createTeam(team).then(
      (newTeam) => {
        const assignee = teamToAssignee(newTeam);
        this.selectedAssignees.push(assignee);
        this.assignees[newTeam.id] = assignee;

        this.scopeIds.push(newTeam.id);
        this.onUpdate({ ids: this.scopeIds });

        this.analyticsService.track("Created Team From Assignee Selector");
      },
      (error) => {
        this.uiErrorHandlingService.handleModal(error);
      }
    );
  }

  private processAssignees(): void {
    this.selectedAssignees = (this.scopeIds || []).map((id) => (this.assignees[id] as IAssigneeExtended) || deletedAssignee(id));
    this.setPlaceholder({ showInitially: this.selectedAssignees.length === 0, focused: false });
  }

  private changeSelectedUsers = (ids: string[]): void => {
    this.scopeIds = ids;
    this.processAssignees();
  };
}

export const UserSelectorComponent: IComponentOptions = {
  controller: UserSelectorCtrl,
  template: require("./user-selector.html"),
  bindings: {
    requiredPermission: "@",
    assignPermissions: "<",
    ids: "<",
    atLeastOneId: "<",
    disabled: "<",
    goalsPerOwnerSetting: "<",
    onlyOneId: "<",
    disallowRestrictedAssignees: "<",
    restoreSelectionOnBlur: "<",
    initialLimit: "<",
    queryPresentLimit: "<",
    popupOpenDelay: "<",
    showSelectedAsigneesInList: "<",
    hidePlaceholderOnBlur: "<",
    hideDefaultPlaceholder: "<",
    hiddenAssignees: "<",
    showDetailsPopover: "<",
    translationKey: "<?",
    isUserInvitationDisabled: "<?",
    focusInput: "<?",
    withEllipsis: "<?",
    hideRestrictedAssignees: "<",

    onUpdate: "&",
    tagClick: "&",
    emitFocus: "&?",
  },
};
