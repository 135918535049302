import { Directionality } from "@angular/cdk/bidi";
import { NgIf } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Optional, Output, Renderer2 } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { NzTagComponent } from "ng-zorro-antd/tag";
import { UiTagColor, UiTagMode } from "./models/tag.models";

@Component({
  selector: "ui-tag",
  exportAs: "uiTag",
  templateUrl: "./tag.component.html",
  styleUrls: ["./tag.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgIf, UiIconModule, UiTooltipModule],
  host: {
    class: "ui-tag",
    role: "button",
    "aria-description": "tag",
    "[attr.tabindex]": "0",
    "[attr.aria-pressed]": "nzChecked",
  },
})
export class UiTagComponent extends NzTagComponent {
  @Input("uiMode") public nzMode: UiTagMode = "default";
  @Input("uiColor") public nzColor?: UiTagColor;
  @Input("uiChecked") @InputBoolean() public nzChecked = false;
  @Input() public uiText: string;
  @Input() public disableTooltip = false;

  @Output("uiOnClose") public readonly nzOnClose = new EventEmitter<MouseEvent>();
  @Output("uiCheckedChange") public readonly nzCheckedChange = new EventEmitter<boolean>();

  constructor(cdr: ChangeDetectorRef, renderer: Renderer2, elementRef: ElementRef, @Optional() directionality: Directionality) {
    super(cdr, renderer, elementRef, directionality);
  }
}
