<button
  class="selected-access-type"
  [tabindex]="tabIndex"
  [uiDropdownMenu]="accessDropdown"
  [uiVisible]="showPermissions"
  [attr.aria-expanded]="showPermissions ? true : null"
  [attr.aria-haspopup]="true"
  (uiVisibleChange)="changePermissionsVisibility()"
  data-test-id="simple-permissions-button"
  ui-dropdown
  uiTrigger="click"
  uiPlacement="bottomRight">
  <i [uiType]="selectedAccessOption.icon" ui-icon uiSize="sm" uiTheme="outline"></i> {{ !compactMode ? (selectedAccessOption?.labelKey | localize) : "" }}
  <i *ngIf="!compactMode" ui-icon uiSize="sm" uiType="chevron-down-small"></i>
</button>
<ui-dropdown-menu #accessDropdown="uiDropdownMenu" tabindex="0">
  <ul class="access-options-menu" data-test-id="simple-permissions-dropdown" ui-menu>
    <ui-radio-group [(ngModel)]="selectedAccessOption" (ngModelChange)="changeAccessType()">
      <span class="menu-title">{{ "who_has_access" | localize }}</span>
      <li class="access-option" *ngFor="let option of accessOptions" ui-menu-item>
        <span [uiValue]="option" data-test-id="simple-permission-label" ui-radio><i [uiType]="option.icon" uiSize="sm" ui-icon uiTheme="outline"></i>{{ option.labelKey | localize }}</span>
      </li>
    </ui-radio-group>
    <div class="restricted-access-section" *ngIf="selectedAccessOption && selectedAccessOption.value === accessTypes.restricted">
      <div class="principals" *ngIf="principalsMap && access && access.permissions && access.permissions.length">
        <div class="principal" *ngFor="let permission of access.permissions; trackBy: trackByPrincipalId">
          <div class="image">
            <ui-assignee-avatar [fromAssigneeId]="permission?.principalId" [uiShowTooltip]="true" uiTooltipPlacement="bottom" uiSize="xs"></ui-assignee-avatar>
          </div>
          <div
            class="name"
            [id]="permission?.principalId"
            [uiTooltipTitle]="principalsMap[permission?.principalId].name"
            [uiTooltipVisible]="checkOverflow(permission?.principalId)"
            ui-tooltip
            uiTooltipPlacement="bottom">
            <span *ngIf="permission.principalKind === 'role'">{{ "everyone_in_named_role2" | localize: { name: principalsMap[permission?.principalId].name } }}</span
            ><span *ngIf="permission.principalKind !== 'role'">{{ principalsMap[permission?.principalId].name }}</span
            ><span *ngIf="!principalsMap[permission?.principalId]">({{ "deleted_user" | localize }})</span>
          </div>
          <span class="grant" *ngIf="ownerId === permission?.principalId">{{ "owner" | localize }}</span>
          <button
            class="grant permission-dropdown-menu-button"
            *ngIf="ownerId !== permission?.principalId"
            [uiVisible]="isPermissionDropdownVisible"
            [uiDropdownMenu]="permissionDropdown"
            [uiClickHide]="true"
            uiPlacement="bottomRight"
            ui-dropdown
            uiTrigger="click">
            {{ (permission.grant.general.includes("update") ? "can_edit" : "can_view") | localize }}
          </button>
          <ui-dropdown-menu #permissionDropdown="uiDropdownMenu">
            <ul class="permissions-menu" ui-menu>
              <li ui-menu-item>
                <button (click)="changeGrant('read', permission)">
                  <span *ngIf="permission.grant.general.indexOf('read') !== -1 && permission.grant.general.indexOf('update') === -1" uiType="check-small" ui-icon uiTheme="outline"></span>
                  {{ "can_view" | localize }}
                </button>
              </li>
              <li ui-menu-item>
                <button (click)="changeGrant('update', permission)">
                  <span *ngIf="permission.grant.general.indexOf('read') !== -1 && permission.grant.general.indexOf('update') !== -1" uiType="check-small" ui-icon uiTheme="outline"></span>
                  {{ "can_edit" | localize }}
                </button>
              </li>
              <li class="delete-permission-btn-holder" ui-menu-item>
                <button class="dropdown-menu-button warn" (click)="removePermission(permission)">{{ "remove" | localize }}</button>
              </li>
            </ul>
          </ui-dropdown-menu>
        </div>
      </div>
      <div class="invite-user-section">
        <span class="invite-user-title">{{ "invite_someone" | localize }}</span>
        <div class="search-holder">
          <simple-search
            [searchCollections]="searchCollections"
            [searchPlaceholder]="'search_for_users_teams_roles' | localize"
            (itemClicked)="addPrincipal($event)"
            activeUsersOnly
            activeTeamsOnly></simple-search>
        </div>
      </div>
    </div>
    <okr-views-copy-link-form class="copy-link-form" *ngIf="shareableLink?.shouldDisplay" [url]="shareableLink.url" (copyLink)="shareableLink.copyLinkHandler()"> </okr-views-copy-link-form>
  </ul>
</ui-dropdown-menu>
