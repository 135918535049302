import { Injector, NgZone } from "@angular/core";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { APP_CONFIG, IAppConfig, IDisabledLaunchDarklyConfig } from "@webapp/core/app-config/models/app-config.models";
import { FeatureTogglesApiService } from "../services/feature-toggles-api.service";
import { FeatureTogglesFacade } from "../services/feature-toggles-facade.service";
import { FeatureTogglesState } from "../services/feature-toggles-state.service";
import { LdFeatureTogglesFacade } from "../services/ld-feature-toggles-facade.service";
import { StaticFeatureTogglesFacade } from "../services/static-feature-toggles-facade.service";

export const FeatureTogglesFacadeProvider = {
  provide: FeatureTogglesFacade,
  useFactory: (appConfig: IAppConfig, injector: Injector): FeatureTogglesFacade =>
    appConfig.launchDarkly.enabled
      ? new LdFeatureTogglesFacade(
          injector.get(FeatureTogglesState),
          injector.get(FeatureTogglesApiService),
          injector.get(AccountService),
          injector.get(AccountResolverService),
          injector.get(NgZone),
          appConfig.launchDarkly
        )
      : new StaticFeatureTogglesFacade(appConfig.launchDarkly as IDisabledLaunchDarklyConfig),
  deps: [APP_CONFIG, Injector],
};
