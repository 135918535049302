import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { BaseApiService } from "@webapp/core/abstracts/services/base-api.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { Goal, GoalDTO } from "../models/goal.models";
import { GoalsState } from "./goals-state.service";

@Injectable({
  providedIn: "root",
})
export class GoalsApiService extends BaseApiService<Goal, GoalDTO, GoalsState> {
  constructor(httpClient: HttpClient, @Inject(APP_CONFIG) appConfig: IAppConfig, state: GoalsState) {
    // TODO: Migrate other methods from goal.service.ts to GoalsApiService when they are going to be used.
    // TODO: Add switchMap() for applyColoringToGoalCollection
    super("goals", { ...new ApiVersionModel("v1"), getAll: "v2", get: "v1" }, httpClient, appConfig, state);
  }

  public getBulkDeleteGoalsEndpoint(): string {
    return `${this.getBasePath(HttpActions.delete)}/bulk`;
  }

  public getApproveEndpoint(goalId): string {
    return `${this.getBasePath(HttpActions.post)}/${goalId}/approve`;
  }

  public getDeclineEndpoint(goalId): string {
    return `${this.getBasePath(HttpActions.post)}/${goalId}/decline`;
  }

  public getWithdrawEndpoint(goalId): string {
    return `${this.getBasePath(HttpActions.post)}/${goalId}/withdraw`;
  }

  public getSendForApprovalEndpoint(goalId): string {
    return `${this.getBasePath(HttpActions.post)}/${goalId}/review`;
  }

  public getChildreorderEndpoint(): string {
    return `${this.getBasePath(HttpActions.patch)}/children/reorder`;
  }

  public getGoalWorkflowActionsEndpoint(goalId: string): string {
    return `${this.getBasePath(HttpActions.patch)}/${goalId}/workflow-actions`;
  }

  public getAddGoalTagsEndpoint(goalId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${goalId}/tags`;
  }

  public getRemoveGoalTagsByTitleEndpoint(goalId: string, tagTitles: string[]): string {
    const tags = tagTitles.map((x) => `"${x}"`).join(",");

    return `${this.getBasePath(HttpActions.delete, { apiVersionOverwrite: "v2" })}/${goalId}/tags?filter={title:{$in:[${encodeURIComponent(tags)}]}}`;
  }

  public getStatusEndpoint(goalId: string): string {
    return `${this.getBasePath(HttpActions.get)}/${goalId}/status`;
  }

  public getGoalsGroupedByOwnersEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/groupBy/owners`;
  }

  public getGoalsGroupedByReviewersEndpoint(): string {
    return `${this.getBasePath(HttpActions.get)}/groupBy/reviewers`;
  }

  public submitToMarketplaceV2Enndpoint(goalId: string): string {
    return `${this.getBasePath(HttpActions.post)}/${goalId}/submit-to-marketplace`;
  }
}
