import { IHttpResponse, ILocationService, IScope, IWindowService } from "angular";
import { StateService } from "@uirouter/angularjs";
import { Observable, map } from "rxjs";
import { AuthenticationResolverService, AuthorizationService, IAuthorizationProcessStatus } from "@gtmhub/auth";
import { AccountType } from "@gtmhub/core";
import { untilScopeDestroyed } from "@gtmhub/core/rxjs";
import { IAppConfig, localhost } from "@gtmhub/env";
import { IUIError } from "@gtmhub/error-handling";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { PlanningSessionsActions } from "@gtmhub/sessions/redux/session-actions";
import { ISessionsStoreState } from "@gtmhub/sessions/redux/session-reducer";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { INgRedux } from "@gtmhub/state-management";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { getCurrentUserLoginsCount } from "@gtmhub/users";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { Session } from "@webapp/sessions/models/sessions.model";
import { LastUrlService } from "@webapp/shared/services/last-url-service";
import { UserProfileService } from "@webapp/user-profile/services/user-profile.service";
import { CurrentUserRepository } from "@webapp/users";

const defaultState = "gtmhub.home";

interface IResolveAccountStateParams {
  domain?: string;
}

interface IResolveAccountRedux {
  sessions: Session[];
  sessionsFetched: boolean;
  sessionsError: IUIError;
}

interface AccountResolveLoopArgs {
  storedLocationPath: string;
  storedLocationSearch: string;
  shouldReload: boolean;
}

const bindStateToScope = (state: ISessionsStoreState): IResolveAccountRedux => {
  return {
    sessions: state.sessions.items,
    sessionsFetched: state.sessions.isFetched,
    sessionsError: state.sessions.error,
  };
};

export class ResolveAccountCtrl {
  public static $inject = [
    "$scope",
    "$rootScope",
    "AuthorizationService",
    "$state",
    "$location",
    "AuthenticationResolverService",
    "AccountResolverService",
    "LastUrlService",
    "AnalyticsService",
    "appConfig",
    "$ngRedux",
    "PlanningSessionsActions",
    "$window",
    "$stateParams",
    "FeatureTogglesFacade",
    "CurrentUserRepository",
    "UserProfileService",
  ];

  constructor(
    private $scope: IScope & IExtendedRootScopeService & IResolveAccountRedux,
    private $rootScope: IGtmhubRootScopeService,
    private authorizationService: AuthorizationService,
    private $state: StateService,
    private $location: ILocationService,
    private authenticationResolverService: AuthenticationResolverService,
    private accountResolverService: AccountResolverService,
    private lastUrlService: LastUrlService,
    private analyticsService: AnalyticsService,
    appConfig: IAppConfig,
    private $ngRedux: INgRedux,
    private planningSessionsActions: PlanningSessionsActions,
    private $window: IWindowService,
    $stateParams: IResolveAccountStateParams,
    private featureTogglesFacade: FeatureTogglesFacade,
    private currentUserRepository: CurrentUserRepository,
    private userProfileService: UserProfileService
  ) {
    // if user has just signed up, he does not have userAccount profile in the system
    // so we must show welcome dialog with prompt for entering account name. After that,
    // we create account for the user and give him access to the system. Otherwise we check
    // if the account is active.

    const userId = this.authenticationResolverService.getAuthUserId();
    const host = appConfig.env.bypassDomainCheck ? $stateParams.domain || localhost : this.$location.host();

    this.authorizationService.process(userId, host).then(
      (status: IAuthorizationProcessStatus) => this.handleAuthorizationStatus(status),
      (err: IHttpResponse<unknown> | Error) => {
        if ("status" in err && err.status === 402) {
          this.$state.go("accountSuspended", { reload: true });
          return;
        }

        this.authenticationResolverService.signout();
        this.lastUrlService.clear();
        this.$state.go("error", {
          error: {
            message: "error_unable_to_log_in_to_account",
            data: err,
          },
        });
      }
    );

    const unsubscribe = this.$ngRedux.connect(bindStateToScope)(this.$scope);
    this.$scope.$on("$destroy", unsubscribe);
  }

  private handleAuthorizationStatus(processStatus: IAuthorizationProcessStatus) {
    this.analyticsService.track("Signed In");

    const { status, shouldReload } = processStatus;
    switch (status) {
      case "default":
        this.handleDefaultAuthorizationStatus();
        break;

      case "new":
        this.handleNewAuthorizationStatus({ shouldReload });
        break;

      case "suspended":
        this.$state.go("accountSuspended", { reload: shouldReload });
        break;

      case "returning":
        this.handleReturnAuthorizationStatus({ shouldReload });
        break;

      case "linked":
        this.handleLinkedAuthorizationStatus();
        break;

      case "switched":
        this.$state.go("accountBootstrapModal.accountSwitched");
        break;
    }
  }

  private handleReturnAuthorizationStatus(args: { shouldReload: boolean }) {
    const profile = this.userProfileService.getProfile();
    // redirect user to singularity application if this is default
    this.authorizationService.getAuthData({ email: profile.email }).then(
      (authData) => {
        if (this.hasSingularitySuffix(authData.data.gtmhubDomain)) {
          this.redirectToStrategyAI(authData.data.gtmhubDomain);
          return;
        }
        this.continueWithReturningUser(args);
      },
      () => {
        this.continueWithReturningUser(args);
      }
    );
  }

  private handleAccountResolutionLoop(args: AccountResolveLoopArgs) {
    if (!args.storedLocationPath || args.storedLocationPath === "/resolve-account/") {
      return;
    }

    if (args.storedLocationSearch) {
      this.$location.search(args.storedLocationSearch);
    }

    this.$location.path(args.storedLocationPath);

    if (args.shouldReload) {
      this.$window.location.reload();
    }
  }

  private handleDefaultAuthorizationStatus() {
    const featureState = this.accountResolverService.getFeatureStateName();
    const account = this.accountResolverService.getAccountData();
    const profile = this.userProfileService.getProfile();
    // this broadcast is used because delighted survey need userId, account, profile and loginsCount from storage
    this.$rootScope.$broadcast("authorizationProcessed");

    this.checkIfUserHasToAcceptPrivacyNotice$().subscribe((showPrivacyNotice) => {
      if (showPrivacyNotice) {
        this.$state.go("gtmhub.privacyNotice", { reload: true });
        return;
      }

      if (featureState) {
        this.$state.go(featureState, null, { reload: true });
        return;
      }

      // redirect user from the demo account to the demo session
      if (account.type === AccountType.DemoAccount) {
        this.$ngRedux.dispatch(this.planningSessionsActions.getSessionsIfMissing());

        this.redirectUserToDemoAccount();

        return;
      }

      // redirect user to singularity application if this is default
      this.authorizationService.getAuthData({ email: profile.email }).then(
        (authData) => {
          if (this.hasSingularitySuffix(authData.data.gtmhubDomain)) {
            this.redirectToStrategyAI(authData.data.gtmhubDomain);
            return;
          }
          this.$state.go(defaultState, null, { reload: true });
        },
        () => {
          this.$state.go(defaultState, null, { reload: true });
        }
      );
    });
  }

  private redirectToStrategyAI(domain: string) {
    this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.StrategyAIEnabled).subscribe((isStrategyAIEnabled) => {
      const redirectUrl = isStrategyAIEnabled ? domain.replace("singularity", "strategyai") : domain;
      this.$window.location.href = redirectUrl;
    });
  }

  private continueWithReturningUser(args: { shouldReload: boolean }) {
    const storedLocationSearch = this.lastUrlService.getStoredLocationSearch();
    const storedLocationPath = this.lastUrlService.getStoredLocationPath();
    this.lastUrlService.clear();

    // this broadcast is used because delighted survey need userId, account, profile and loginsCount from storage
    this.$rootScope.$broadcast("authorizationProcessed");

    this.checkIfUserHasToAcceptPrivacyNotice$().subscribe((showPrivacyNotice) => {
      if (showPrivacyNotice) {
        this.$state.go("gtmhub.privacyNotice", { reload: true });
        return;
      }

      if (!storedLocationPath || storedLocationPath === "/resolve-account/") {
        this.$state.go(defaultState, { reload: args.shouldReload });
      }
    });

    this.handleAccountResolutionLoop({ storedLocationPath, storedLocationSearch, shouldReload: args.shouldReload });
  }

  private hasSingularitySuffix(domain: string): boolean {
    return domain && (domain.endsWith("/singularity") || domain.endsWith("/strategyai"));
  }

  private handleLinkedAuthorizationStatus() {
    const storedLocationSearch = this.lastUrlService.getStoredLocationSearch() || {};
    storedLocationSearch[this.lastUrlService.keepLocationKey] = true;
    this.lastUrlService.storeCurrentLocationSearch(storedLocationSearch);

    this.authenticationResolverService.navigateToLogin(this.userProfileService.getProfile());
  }

  private redirectUserToDemoAccount() {
    this.$scope
      .watchUntilAndRejectOnError(
        () => this.$scope.sessionsFetched,
        () => this.$scope.sessionsError
      )
      .then(
        () => {
          const session = this.$scope.sessions[0];

          // this is a temporary solution
          // when resolving demo account all sessions should be in the filter
          // https://gtmhub.atlassian.net/browse/GVS-3555
          const sessionIds = this.$scope.sessions.map((session) => session.id);
          if (session) {
            this.$state.go("gtmhub.goals.alignmentSidebar.tree", { planningSessionId: session.id, sessions: sessionIds.join(",") }, { reload: true });
          } else {
            this.$state.go(defaultState, null, { reload: true });
          }
        },
        () => {
          this.$state.go(defaultState, null, { reload: true });
        }
      );
  }

  private checkIfUserHasToAcceptPrivacyNotice$(): Observable<boolean> {
    return this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.PrivacyNotice).pipe(
      untilScopeDestroyed(this.$scope),
      map((isFeatureAvailable) => {
        const hasAcceptedPrivacyNotice = this.currentUserRepository.getUserSetting<boolean>("hasAcceptedPrivacyNotice");
        const isFirstLogin = getCurrentUserLoginsCount() === 1;
        return isFeatureAvailable && (isFirstLogin || hasAcceptedPrivacyNotice === false);
      })
    );
  }

  private handleNewAuthorizationStatus(param: { shouldReload: boolean }) {
    const profile = this.userProfileService.getProfile();
    this.authorizationService.getAuthData({ email: profile.email }).then(
      (authData) => {
        if (this.hasSingularitySuffix(authData.data.gtmhubDomain)) {
          this.redirectToStrategyAI(authData.data.gtmhubDomain);
          return;
        }
        this.$state.go("accountBootstrap.createAccount", { reload: param.shouldReload });
      },
      () => {
        this.$state.go("accountBootstrap.createAccount", { reload: param.shouldReload });
      }
    );
  }
}
