import { IAngularEvent, IPromise, ITimeoutService } from "angular";
import { IModalScope } from "angular-ui-bootstrap";
import { AccountType, IAccount } from "@gtmhub/core";
import { IStateInit } from "@gtmhub/core/routing";
import { UIErrorHandlingService } from "@gtmhub/error-handling";
import { ISubscriptionConverted } from "@gtmhub/login/models";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { CurrentUserRepository } from "@webapp/users";
import { BillingPeriod, EmailVerificationComponent, IEditionPlanChangeResolveParams, IPlan, PlanName } from "../models";
import { PricingEditionService } from "../services/pricing-edition.service";

export interface IEditionPlanChangeScope extends IModalScope {
  indicators: {
    animationTriggered: boolean;
    loading: boolean;
  };
  activeScreen: BillingPeriod;
  selectedPlan: IPlan;
  plans: IPlan[];
  planName: PlanName;
  resolveParams: IEditionPlanChangeResolveParams;
  account: IAccount;
  isAccountTrial: boolean;
  showUpdatedPricingInfo: boolean;
  typeEmailVerificationComponent: EmailVerificationComponent;
  accountEditionPermissions: boolean;
  alignButtonsToTop: boolean;

  triggerAnimation(): void;
  contactSales(): void;
  switchActiveScreen(): void;
  closeUpdatedPricingInfo(): void;
}

export class EditionPlanChangeCtrl implements IStateInit {
  public static $inject = [
    "$scope",
    "$timeout",
    "AccountResolverService",
    "AnalyticsService",
    "PricingEditionService",
    "CurrentUserRepository",
    "UIErrorHandlingService",
    "resolveParams",
  ];

  constructor(
    private $scope: IEditionPlanChangeScope,
    private $timeout: ITimeoutService,
    private accountResolverService: AccountResolverService,
    private analyticsService: AnalyticsService,
    private pricingEditionService: PricingEditionService,
    private currentUserRepository: CurrentUserRepository,
    private uiErrorHandlingService: UIErrorHandlingService,
    private resolveParams: IEditionPlanChangeResolveParams
  ) {}

  stateInit(): IPromise<unknown> {
    return this.init();
  }

  private init = (): IPromise<unknown> => {
    this.$scope.indicators = {
      animationTriggered: false,
      loading: true,
    };
    this.$scope.resolveParams = this.resolveParams;
    this.$scope.typeEmailVerificationComponent = "paragraph";

    this.$scope.account = this.accountResolverService.getAccountData();
    let isAccountConverted: boolean;
    if (this.$scope.account.subscriptions.length) {
      isAccountConverted = this.$scope.account.subscriptions.find((license) => license.type === "regular").isConverted;
    }
    this.$scope.isAccountTrial = AccountType.TrialAccount === this.$scope.account.type && !isAccountConverted;

    this.$scope.triggerAnimation = this.triggerAnimation;
    this.$scope.switchActiveScreen = this.switchActiveScreen;
    this.$scope.contactSales = this.contactSales;
    this.$scope.closeUpdatedPricingInfo = this.closeUpdatedPricingInfo;

    this.$scope.$on("subscriptionConverted", this.onSubscriptionConverted);

    return this.pricingEditionService.getAllowedTransitions(this.$scope.account.type).then(
      (plans) => {
        this.$scope.accountEditionPermissions = !!plans.length;
        if (!this.$scope.accountEditionPermissions) {
          this.$scope.indicators.loading = false;
          return;
        }

        this.$scope.plans = plans.sort((a, b) => a.weight - b.weight);

        if (this.$scope.resolveParams.currentlySelectedPlanId) {
          this.$scope.plans = this.$scope.plans.map((plan) => ({ ...plan, isSelected: plan.id === this.$scope.resolveParams.currentlySelectedPlanId }));
        }
        this.$scope.selectedPlan = this.$scope.plans.find((plan) => plan.isSelected) || <IPlan>{ id: "" };
        this.setUIVariables();

        this.$scope.indicators.loading = false;
      },
      (error) => this.uiErrorHandlingService.handleModal(error)
    );
  };

  private setUIVariables = (): void => {
    this.setShowUpdatedPricingInfo();
    this.setActiveScreen();
    this.$scope.planName = this.getPlanName(this.$scope.selectedPlan.id);
    this.$scope.alignButtonsToTop = this.$scope.plans.some((plan) => plan.canBuy && plan.canTry);
  };

  private setShowUpdatedPricingInfo = (): void => {
    const isPricingChangeBannerDismissed = this.currentUserRepository.getUserSetting<boolean>("updatedPricingInfoDismissed");
    this.$scope.showUpdatedPricingInfo =
      !isPricingChangeBannerDismissed && !this.$scope.isAccountTrial && this.checkIsSelectedPlanIsOld(this.$scope.plans, this.$scope.selectedPlan.planGroupName);
  };

  private checkIsSelectedPlanIsOld = (plans: IPlan[], selectedPlanGroupName: string): boolean => {
    // When you are a client we will return all the plans from one group (the newest one). If the selected plan is from a different group this means the selected plan is OLD
    return !plans.every((plan) => plan.planGroupName === selectedPlanGroupName);
  };

  private switchActiveScreen = (): void => {
    this.$scope.activeScreen = this.$scope.activeScreen === "annual" ? "monthly" : "annual";
    this.triggerAnimation();
  };

  private triggerAnimation = (): void => {
    this.$scope.indicators.animationTriggered = true;

    this.$timeout(() => {
      this.$scope.indicators.animationTriggered = false;
    }, 1200);
  };

  private contactSales = (): void => {
    this.analyticsService.contactSales("contactSales");
  };

  private onSubscriptionConverted = (event: IAngularEvent, subscriptionConverted: ISubscriptionConverted): void => {
    if (this.$scope.account.id === subscriptionConverted.accountId) {
      this.$scope.isAccountTrial = !subscriptionConverted.subscriptions.find((license) => license.type === "regular").isConverted;

      this.$scope.plans = this.$scope.plans.map((plan) => ({ ...plan, isSelected: plan.id === subscriptionConverted.accountEdition.planId }));
      this.$scope.selectedPlan = this.$scope.plans.find((plan) => plan.id === subscriptionConverted.accountEdition.planId);
      this.setUIVariables();

      this.$scope.$apply();
    }
  };

  private setActiveScreen = (): void => {
    this.$scope.activeScreen = this.$scope.selectedPlan.billingPeriod;
  };

  private getPlanName = (plan: string): PlanName => {
    if (plan.includes("start")) {
      return "Start";
    } else if (plan.includes("scale")) {
      return "Scale";
    } else if (plan.includes("summit")) {
      return "Summit";
    } else {
      return "Enterprise";
    }
  };

  private closeUpdatedPricingInfo = (): void => {
    this.$scope.showUpdatedPricingInfo = false;
    this.currentUserRepository.setUserSetting({ updatedPricingInfoDismissed: true });
  };
}
