import { CommonModule, NgOptimizedImage } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { UiButtonModule } from "@quantive/ui-kit/button";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { AccountServiceProvider } from "@webapp/accounts/providers/account.provider";
import { GetDataSourceBlueprintLogoPipe } from "@webapp/data-story/pipes/get-datasource-blueprint-logo.pipe";
import { InsightFilterPipe } from "@webapp/data-story/pipes/insight-filter.pipe";
import { AutoInsightsApiService } from "@webapp/data-story/services/auto-insights-facade/auto-insights-api.service";
import { AutoInsightsFacade } from "@webapp/data-story/services/auto-insights-facade/auto-insights-facade.service";
import { AutoInsightsState } from "@webapp/data-story/services/auto-insights-facade/auto-insights-state.service";
import { MissingDataSourceAccessModalService } from "@webapp/data-story/services/missing-data-source-access-modal.service";
import { UIErrorHandlingServiceProvider } from "@webapp/error-handling/providers/ui-error-handling-service.provider";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { OkrsCoreModule } from "@webapp/okrs/okrs-core.module";
import { BasicAutomationComponent } from "@webapp/shared/components/item-automation/components/basic-automation/basic-automation.component";
import { SelectedAutomationComponent } from "@webapp/shared/components/item-automation/components/selected-automation/selected-automation.component";
import { SuggestedAutomationComponent } from "@webapp/shared/components/item-automation/components/suggestions-automation/suggested-automation.component";
import { ItemAutomationComponent } from "@webapp/shared/components/item-automation/item-automation.component";
import { InsightUsageMessagePipe } from "@webapp/shared/components/item-automation/pipes/insight-usage-message.pipe";
import { IndicatorModule } from "@webapp/shared/indicator/indicator.module";
import { UibModalProvider } from "@webapp/shared/providers/uib-modal.provider";
import { UiAlertModule } from "@webapp/ui/alert/alert.module";
import { UiValidationHintComponent } from "@webapp/ui/input/components/validation-hint/validation-hint.component";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { ConfirmAutomateKRWithKPIComponent } from "./components/confirm-automate-kr-with-kpi/confirm-automate-kr-with-kpi.component";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    LocalizationModule,
    UiIconModule,
    UiButtonModule,
    NgOptimizedImage,
    IndicatorModule,
    GetDataSourceBlueprintLogoPipe,
    UiTooltipModule,
    UiLoadingIndicatorModule,
    UiValidationHintComponent,
    UiAlertModule,
    OkrsCoreModule,
  ],
  declarations: [
    InsightFilterPipe,
    ItemAutomationComponent,
    BasicAutomationComponent,
    SelectedAutomationComponent,
    SuggestedAutomationComponent,
    InsightUsageMessagePipe,
    ConfirmAutomateKRWithKPIComponent,
  ],
  exports: [ItemAutomationComponent, InsightFilterPipe],
  providers: [
    AutoInsightsFacade,
    AutoInsightsState,
    AutoInsightsApiService,
    MissingDataSourceAccessModalService,
    UibModalProvider,
    UIErrorHandlingServiceProvider,
    AccountServiceProvider,
  ],
})
export class ItemAutomationModule {}
