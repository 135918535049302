import { StateService } from "@uirouter/angular";
import { BreakpointObserver, BreakpointState } from "@angular/cdk/layout";
import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { UiBadgeModule } from "@quantive/ui-kit/badge";
import { UiCardModule } from "@quantive/ui-kit/card";
import { SimpleChangesOf } from "@quantive/ui-kit/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { Observable, combineLatest, take } from "rxjs";
import { IConfidenceMapping, IConfidenceSettings } from "@gtmhub/core";
import { ApmService } from "@gtmhub/core/tracing/apm.service";
import { formatAttainmentProgressValue } from "@gtmhub/okrs/metrics/utils";
import { getCurrentUserId } from "@gtmhub/users";
import { AnalyticsModule } from "@webapp/analytics/analytics.module";
import { TrackingMetadata } from "@webapp/analytics/models/analytics.model";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AssigneesModule } from "@webapp/assignees/assignees.module";
import { Assignee, AssigneeType } from "@webapp/assignees/models/assignee.models";
import { AssigneesRepository } from "@webapp/assignees/services/assignees-repository.service";
import { assigneeFromMap } from "@webapp/assignees/utils/assignee.utils";
import { ConfidenceScale, ConfidenceType } from "@webapp/configuration/models/configuration.model";
import { BroadcastService } from "@webapp/core/broadcast/services/broadcast.service";
import { ICollection } from "@webapp/core/core.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { WidgetSkeletonComponent } from "@webapp/home/components/widgets/widget-skeleton/widget-skeleton.component";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { IsNavigationExpandedCache } from "@webapp/navigation/services/is-navigation-expanded.cache";
import { SessionSummaryReportComponent } from "@webapp/okrs/components/assignee-active-okrs-list/session-summary-report/session-summary-report.component";
import { MetricProgressBarComponent } from "@webapp/okrs/metrics/components/metric-progress-bar/metric-progress-bar.component";
import { ConfidenceSettingsService } from "@webapp/okrs/metrics/services/confidence-settings.service";
import { OkrsCoreModule } from "@webapp/okrs/okrs-core.module";
import { getConfidenceName, getFloatedMappings } from "@webapp/okrs/utils/confidence-level.utils";
import { HasAllPermissionsDirective } from "@webapp/permissions/directives/has-all-permissions.directive";
import { ConfidenceLabelComponent } from "@webapp/shared/components/confidence-label/confidence-label.component";
import { ContainerEmptyStateComponent } from "@webapp/shared/components/container-empty-state/container-empty-state.component";
import { ConfidenceExtractorService } from "@webapp/shared/services/confidence-extractor.service";
import { UiCollapseModule } from "@webapp/ui/collapse/collapse.module";
import { UiLoadingIndicatorModule } from "@webapp/ui/loading-indicator/loading-indicator.module";
import { UiProgressModule } from "@webapp/ui/progress/progress.module";
import { WidgetActionsComponent } from "../../../home/components/widgets/widget-actions/widget-actions.component";
import { CreateOkrSplitButtonComponent } from "../create-okr-split-button/create-okr-split-button.component";
import { AssigneeActiveOkrsListStateDirective } from "./assignee-active-okrs-list-state.directive";
import {
  ActiveOkrsListGroup,
  ActiveOkrsListSort,
  DEFAULT_ACTIVE_OKRS_LIST_GROUP,
  DEFAULT_ACTIVE_OKRS_LIST_SORT,
  GoalOverviewItem,
  MetricOverviewItem,
  OkrGridViewMode,
  OkrsSessionGroup,
  SectionExpandState,
} from "./assignee-active-okrs-list.models";
import { AssigneeActiveOkrsRepository } from "./repository/assignee-active-okrs-repository.service";

const customBreakpoints: Record<string, string> = {
  Small: "(max-width: 1023.98px)",
  Medium: "(min-width: 1024px) and (max-width: 1279.98px)",
  Large: "(min-width: 1280px) and (max-width: 1439.98px)",
  XLarge: "(min-width: 1440px)",
};

@UntilDestroy()
@Component({
  selector: "assignee-active-okrs-list",
  templateUrl: "./assignee-active-okrs-list.component.html",
  styleUrls: ["./assignee-active-okrs-list.component.less"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    UiCardModule,
    UiCollapseModule,
    UiBadgeModule,
    UiIconModule,
    LocalizationModule,
    CommonModule,
    OkrsCoreModule,
    AnalyticsModule,
    UiLoadingIndicatorModule,
    ConfidenceLabelComponent,
    MetricProgressBarComponent,
    AssigneesModule,
    WidgetActionsComponent,
    SessionSummaryReportComponent,
    UiProgressModule,
    ContainerEmptyStateComponent,
    CreateOkrSplitButtonComponent,
    WidgetSkeletonComponent,
    HasAllPermissionsDirective,
    AssigneeActiveOkrsListStateDirective,
    UiTooltipModule,
  ],
  providers: [AssigneeActiveOkrsRepository, ConfidenceSettingsService, ConfidenceExtractorService],
})
export class AssigneeActiveOkrsListComponent implements OnInit, OnChanges {
  @Input({ required: true }) public assigneeId: string;
  @Input({ required: true }) public assigneeType: AssigneeType;
  @Input() public trackMeta: TrackingMetadata;

  @Input() public groupBy: ActiveOkrsListGroup = DEFAULT_ACTIVE_OKRS_LIST_GROUP;
  @Input() public sortBy: ActiveOkrsListSort = DEFAULT_ACTIVE_OKRS_LIST_SORT;

  public viewMode: OkrGridViewMode;
  public sectionsState: Record<string, SectionExpandState> = {};
  public progressBarState: Record<string, { hideBar?: boolean; hideDelta?: boolean }> = {};
  public data: OkrsSessionGroup[] = [];
  public loading = true;

  protected readonly restrictedObjectiveTitle = "Restricted Objective";
  protected readonly restrictedMetricTitle = "Restricted Key Result";
  protected readonly restrictedSessionTitle = "Restricted Session";

  private confidenceType: ConfidenceType;
  private confidenceSettings: IConfidenceSettings;
  private confidenceScale: ConfidenceScale;
  private mappings: IConfidenceMapping[];
  private assignees: Map<string, Assignee>;

  constructor(
    private breakpointObserver: BreakpointObserver,
    private isNavigationExpandedCache: IsNavigationExpandedCache,
    private assigneeActiveOkrsRepository: AssigneeActiveOkrsRepository,
    private state: StateService,
    private confidenceSettingsService: ConfidenceSettingsService,
    private assigneesRepository: AssigneesRepository,
    private cdr: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
    private broadcastService: BroadcastService,
    private apmService: ApmService,
    private featureTogglesFacade: FeatureTogglesFacade
  ) {
    this.apmService.startDataLoadSpan("my-okrs-widget-init");
    if (!this.viewMode) {
      this.setBreakpointObserver();
    }
  }

  public ngOnChanges(changes: SimpleChangesOf<this>): void {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.MyOkrsGroupSortDropdown)
      .pipe(take(1), untilDestroyed(this))
      .subscribe((isGroupSortEnabled) => {
        const reloadData = isGroupSortEnabled ? changes.sortBy || changes.groupBy || changes.assigneeId : changes.assigneeId;

        if (reloadData) {
          this.loadData();
        }
      });
  }

  public ngOnInit(): void {
    this.getConfidenceSettings();
    this.getAssignees();

    this.broadcastService
      .on("goalsChanged")
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.loadData();
      });
  }

  public stateChanged(sessionId: string, state: SectionExpandState): void {
    this.sectionsState[sessionId] = state;
    this.setProgressState();
    this.cdr.detectChanges();
  }

  public isMetricRestricted(metric: MetricOverviewItem): boolean {
    return !metric.ownerIds.includes(getCurrentUserId());
  }

  public openGoal(id: string): void {
    this.state.go("._goals.goal", { id: id });

    this.analyticsService.track("Link Clicked", {
      ...(this.trackMeta || {}),
      link: "objective_details",
      id: id,
      user_id: getCurrentUserId(),
    });
  }

  public openMetric(id: string): void {
    this.state.go(".metric", { metricId: id });
    this.analyticsService.track("Link Clicked", {
      ...(this.trackMeta || {}),
      link: "key_result_details",
      id: id,
      user_id: getCurrentUserId(),
    });
  }

  public getTrackingMetadata(sessionId: string): TrackingMetadata {
    const group = this.data.find((item) => item.id === sessionId);

    return {
      button_name: "session_accordion",
      expanded: !this.sectionsState[sessionId]?.panel,
      session_id: sessionId,
      assignee_id: this.assigneeId,
      assignee_type: this.assigneeType,
      user_id: getCurrentUserId(),
      okrs_count: group.items.length,
      ...this.trackMeta,
    };
  }

  public getSummaryReportExtraTrackingMetadata(sessionId: string): TrackingMetadata {
    const group = this.data.find((item) => item.id === sessionId);

    return {
      assignee_id: this.assigneeId,
      assignee_type: this.assigneeType,
      okrs_count: group.items.length,
      ...this.trackMeta,
    };
  }

  public getConfidenceName(confidenceValue: number): string {
    return getConfidenceName(confidenceValue, this.confidenceType, this.confidenceScale, this.mappings);
  }

  public getMetricNameLabel(item: MetricOverviewItem): string {
    let label = "";

    if (item.confidence) {
      label = ` Confidence: ${this.getConfidenceName(item.confidence.value)}.`;
    }

    label += this.getItemWithIconExtraLabel(item);
    return label;
  }

  public getItemWithIconExtraLabel(item: MetricOverviewItem | GoalOverviewItem): string {
    let label = "";

    if (this.groupBy === "ownership" && "sessionName" in item && item.sessionName) {
      label += ` Session: ${item.sessionName}.`;
    }

    if (item.ownerIds?.length) {
      const ownerNames = item.ownerIds.map((ownerId) => assigneeFromMap(this.assignees, ownerId).name).join(", ");
      label += ` Owners: ${ownerNames}.`;
    }

    const myProgressAttainment = formatAttainmentProgressValue(item.attainment);
    label += ` Progress: ${myProgressAttainment}%.`;
    return label;
  }

  public getSectionHeaderAriaLabel(group: OkrsSessionGroup): string {
    if (this.groupBy === "session") {
      return `Session: ${group.isRestricted ? this.restrictedSessionTitle : group.title}.`;
    } else {
      return `Owner: ${group.title}.`;
    }
  }

  private getAssigneeActiveOkrs(): Observable<ICollection<OkrsSessionGroup>> {
    return this.assigneeType === "team" ? this.getTeamOkrs() : this.getMyOkrs();
  }

  private getMyOkrs(): Observable<ICollection<OkrsSessionGroup>> {
    return this.assigneeActiveOkrsRepository
      .getMyOkrs$({
        options: {
          group: this.groupBy,
          sort: this.sortBy,
        },
      })
      .pipe(untilDestroyed(this));
  }

  private getTeamOkrs(): Observable<ICollection<OkrsSessionGroup>> {
    return this.assigneeActiveOkrsRepository
      .getTeamOkrs$({
        teamId: this.assigneeId,
        group: this.groupBy,
        sort: this.sortBy,
      })
      .pipe(untilDestroyed(this));
  }

  private setDefaultSectionsState(): void {
    if (!this.data.length) {
      return;
    }

    this.sectionsState[this.data[0].id] = {
      panel: true,
      summary: false,
    };
  }

  private setProgressState(): void {
    Object.keys(this.sectionsState).forEach((sessionId) => {
      switch (this.viewMode) {
        case "x-small":
        case "small":
          this.progressBarState[sessionId] = {
            hideBar: true,
            hideDelta: true,
          };
          break;
        case "medium":
          this.progressBarState[sessionId] = {
            hideBar: true,
            hideDelta: this.sectionsState[sessionId].summary,
          };
          break;
        case "large":
          this.progressBarState[sessionId] = {
            hideBar: this.sectionsState[sessionId].summary,
            hideDelta: this.sectionsState[sessionId].summary,
          };
          break;
        case "x-large":
          this.progressBarState[sessionId] = {
            hideBar: false,
            hideDelta: false,
          };
          break;
      }
    });
  }

  private getAssignees(): void {
    this.assigneesRepository
      .getMap$()
      .pipe(untilDestroyed(this))
      .subscribe((assignees) => {
        this.assignees = assignees;
      });
  }

  private getConfidenceSettings(): void {
    this.confidenceSettings = this.confidenceSettingsService.getConfidenceSettings();
    this.confidenceScale = this.confidenceSettingsService.getConfidenceScale();
    this.confidenceType = this.confidenceSettings?.confidenceType ?? "numeric";
    const confidenceMappingCopy = this.confidenceSettings.confidenceMapping.map((it) => Object.assign({}, it));
    this.mappings = getFloatedMappings(
      confidenceMappingCopy.sort((a: IConfidenceMapping, b: IConfidenceMapping) => a.range.to - b.range.to),
      10
    );
  }

  private setBreakpointObserver(): void {
    combineLatest([
      this.isNavigationExpandedCache.get$(),
      this.breakpointObserver.observe([customBreakpoints.Small, customBreakpoints.Medium, customBreakpoints.Large, customBreakpoints.XLarge]),
    ])
      .pipe(untilDestroyed(this))
      .subscribe(([isNavExpanded, state]: [boolean, BreakpointState]) => {
        if (!state.matches) {
          return;
        }

        if (state.breakpoints[customBreakpoints.Small]) {
          this.viewMode = isNavExpanded ? "x-small" : "small";
        }
        if (state.breakpoints[customBreakpoints.Medium]) {
          this.viewMode = isNavExpanded ? "medium" : "large";
        }
        if (state.breakpoints[customBreakpoints.Large]) {
          this.viewMode = isNavExpanded ? "large" : "x-large";
        }
        if (state.breakpoints[customBreakpoints.XLarge]) {
          this.viewMode = "x-large";
        }

        this.setProgressState();
        this.cdr.markForCheck();
      });
  }

  private loadData(): void {
    this.loading = true;
    this.cdr.markForCheck();

    this.getAssigneeActiveOkrs()
      .pipe(untilDestroyed(this))
      .subscribe((data) => {
        this.data = data.items.filter((session) => session.items?.length);
        this.loading = false;

        this.setDefaultSectionsState();
        this.cdr.markForCheck();
        this.apmService.endDataLoadSpan("my-okrs-widget-init");
      });
  }
}
