import { IDirective, IDirectiveFactory, IQService, IScope, noop } from "angular";
import { IModalService } from "angular-ui-bootstrap";
import { IAssigneesStoreState } from "@gtmhub/assignees";
import { IIndicator, IUIError } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { IRole, RoleService } from "@gtmhub/roles";
import { INgRedux } from "@gtmhub/state-management";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { IReduxTeam, ITeam, ITeamsIdMap, ITeamsStoreState } from "@gtmhub/teams";
import { TeamsActions, addAssigneeDataToTeams } from "@gtmhub/teams/redux/teams-actions";
import { getCurrentUserId } from "@gtmhub/users";
import { IUser, IUsersIdMap } from "@gtmhub/users/models";
import { UserService } from "@gtmhub/users/user-service";
import { IdMap } from "@gtmhub/util";
import { IAccess, IApiPermission, IPrincipalKind, UserAction } from "@webapp/sessions/models/sessions.model";
import { Ng1UiModalService } from "../components/modal/ng1-modal.service";
import {
  AccountPrincipal,
  DEFAULT_ACCOUNT_PRINCIPAL,
  Principal,
  RolePrincipal,
  TeamPrincipal,
  UserPrincipal,
  defaultUserActionSet,
} from "../components/permissions/models";
import { IExtendedRootScopeService } from "../ng-extensions";

export class Permissions implements IDirective {
  public scope = {
    access: "=",
    defaultSet: "=?",
    onUpdate: "&?",
  };
  public restrict = "E";
  public template = require("../views/permissions.html");
  public controller = PermissionsDirectiveCtrl;

  public static factory(): IDirectiveFactory {
    const directive = () => new Permissions();
    directive.$inject = [];
    return directive;
  }
}

interface IPermissionsRedux {
  teams: ITeam[];
  teamsIdMap: IdMap<IReduxTeam>;
  reduxItemsFetched: boolean;
  reduxItemsError: IUIError;
}

const bindStateToScope = (state: ITeamsStoreState & IAssigneesStoreState): IPermissionsRedux => {
  return {
    teams: addAssigneeDataToTeams(state.teams.items, state.assignees.map),
    teamsIdMap: state.teams.map,
    reduxItemsFetched: state.teams.isFetched && state.assignees.isFetched,
    reduxItemsError: state.teams.error || state.assignees.error,
  };
};

export interface IPermissionsDirectiveScope extends IScope, IExtendedRootScopeService, IPermissionsRedux {
  principalsWithModifyAccess: IApiPermission[];
  changeListAccess(): void;
  indicators: {
    loadingPermissions?: IIndicator;
  };
  access: IAccess;
  defaultSet: UserAction[];
  users: IUser[];
  usersMap: IUsersIdMap;
  teams: ITeam[];
  teamsIdMap: ITeamsIdMap;
  roles: IRole[];
  rolesMap: IdMap<IRole>;
  typeahead: {
    filter: string;
  };
  check(p: IApiPermission, option: UserAction): void;
  canUnselectAction(permission: IApiPermission, option: UserAction): boolean;
  canRemovePrincipal(permission: IApiPermission): boolean;
  removePermissions(permission: IApiPermission): void;
  selectFromList(): void;
  getPrincipals(partialName: string): Principal[];
  getPrincipal({ principalId, principalKind }: { principalId: string; principalKind: IPrincipalKind }): IUser | ITeam | IRole | typeof DEFAULT_ACCOUNT_PRINCIPAL;
  lastWithModifyPermissions(principalId: string): boolean;
  addPermission(principal: Principal): void;
  onUpdate(): void;
}

class PermissionsDirectiveCtrl {
  private currentUserId: string = getCurrentUserId();
  private account: AccountPrincipal;
  private principals: Principal[] = [];

  public static $inject = ["$ngRedux", "TeamsActions", "$scope", "$q", "$uibModal", "UserService", "AccountResolverService", "RoleService", "Ng1UiModalService"];

  constructor(
    private $ngRedux: INgRedux,
    private teamsActions: TeamsActions,
    private $scope: IPermissionsDirectiveScope,
    private $q: IQService,
    private $uibModal: IModalService,
    private userService: UserService,
    private accountResolverService: AccountResolverService,
    private roleService: RoleService,
    private modalService: Ng1UiModalService
  ) {
    this.init();

    this.account = {
      id: this.accountResolverService.getAccountId(),
      type: "account",
      name: "Account",
      isActive: undefined,
      used: undefined,
    };
  }

  private init = (): void => {
    const unsubscribe = this.$ngRedux.connect(bindStateToScope)(this.$scope);
    this.$scope.$on("$destroy", unsubscribe);

    this.$ngRedux.dispatch(this.teamsActions.fetchTeamsIfMissing());

    if (!this.$scope.defaultSet) {
      this.$scope.defaultSet = defaultUserActionSet();
    }

    if (!this.$scope.access) {
      this.$scope.access = {
        inherits: false,
        permissions: [],
      };
    }

    if (!this.$scope.access.permissions) {
      this.$scope.access.permissions = [];
    }

    this.$scope.indicators = {};

    this.$scope.typeahead = {
      filter: null,
    };

    this.$scope.getPrincipal = this.getPrincipal;
    this.$scope.lastWithModifyPermissions = this.lastWithModifyPermissions;
    this.$scope.check = this.check;
    this.$scope.removePermissions = this.removePermissions;
    this.$scope.canUnselectAction = this.canUnselectAction;
    this.$scope.canRemovePrincipal = this.canRemovePrincipal;
    this.$scope.selectFromList = this.selectFromList;
    this.$scope.getPrincipals = this.getPrincipals;
    this.$scope.addPermission = this.addPermission;

    this.$scope.indicators.loadingPermissions = { progress: true };
    this.$scope.principalsWithModifyAccess = [];

    const promiseMap = {
      usersMap: this.userService.getUsersIdMap(),
      // to do: use redux roles instead with https://gtmhub.atlassian.net/browse/GVS-13173
      rolesCollection: this.roleService.getRoles(),
      redux: this.$scope.watchUntilAndRejectOnError(
        () => this.$scope.reduxItemsFetched,
        () => this.$scope.reduxItemsError
      ),
    };

    this.$q.all(promiseMap).then(
      ({ usersMap, rolesCollection }) => {
        delete this.$scope.indicators.loadingPermissions;

        this.$scope.usersMap = usersMap;

        this.$scope.users = Object.keys(this.$scope.usersMap).reduce((usersArray: IUser[], currentUserId: string): IUser[] => {
          usersArray.push(this.$scope.usersMap[currentUserId]);

          return usersArray;
        }, []);

        this.$scope.roles = rolesCollection.items;

        this.$scope.rolesMap = {};
        this.$scope.rolesMap = this.$scope.roles.reduce((idMap: IdMap<IRole>, currentRole: IRole): IdMap<IRole> => {
          idMap[currentRole.id] = currentRole;

          return idMap;
        }, this.$scope.rolesMap);

        this.$scope.access.permissions = this.filterInvalidPrincipals(this.$scope.access.permissions);
        this.setViewOnlyRoleAllowedActions(this.$scope.access.permissions);

        this.checkAllDuplicates(this.account, this.$scope.users, this.$scope.roles, this.$scope.teams, this.$scope.access.permissions);

        this.principals = this.generatePrincipals(this.account, this.$scope.users, this.$scope.teams, this.$scope.roles);
        this.updatePrincipalsWithModifyAccess();
      },
      (error) => (this.$scope.indicators.loadingPermissions = { error })
    );
  };

  getPrincipal = ({ principalId, principalKind }: { principalId: string; principalKind: IPrincipalKind }): IUser | ITeam | IRole | typeof DEFAULT_ACCOUNT_PRINCIPAL => {
    if (principalKind === "account") {
      return DEFAULT_ACCOUNT_PRINCIPAL;
    }

    const principalKindMap = {
      user: this.$scope.usersMap,
      team: this.$scope.teamsIdMap,
      team_and_subteams: this.$scope.teamsIdMap,
      role: this.$scope.rolesMap,
    };

    if (principalKindMap[principalKind][principalId]) {
      principalKindMap[principalKind][principalId].type ||= principalKind;
    }

    return principalKindMap[principalKind][principalId];
  };

  lastWithModifyPermissions = (principalId: string) => {
    return this.$scope.principalsWithModifyAccess.length === 1 && this.$scope.principalsWithModifyAccess[0].principalId === principalId;
  };

  private updatePrincipalsWithModifyAccess = () => {
    this.$scope.principalsWithModifyAccess = this.$scope.access.permissions.filter((permission) => permission.grant.general.includes("modifyPermissions"));
  };

  private canUnselectAction = (p: IApiPermission, option: UserAction): boolean => {
    if (option === "read") {
      return false;
    }

    if (option === "modifyPermissions") {
      return this.$scope.principalsWithModifyAccess.length > 1 || !p.grant.general.includes("modifyPermissions");
    }

    return true;
  };

  private canRemovePrincipal = (p: IApiPermission): boolean => {
    return this.$scope.principalsWithModifyAccess.length === 1 && p.grant.general.includes("modifyPermissions");
  };

  private check = (p: IApiPermission, option: UserAction): void => {
    const permissionExists = this.$scope.access.permissions.indexOf(p) >= 0;
    if (!permissionExists) {
      throw new Error(`Unknown permission for principal ${p.principalKind} ${p.principalId}`);
    }

    const checked = p.grant.general.indexOf(option) >= 0;

    if (option === "read" && !checked) {
      throw new Error(`Read is always checked`);
    }

    // if the user select modifyPermissions, all checkboxes are selected
    if (option === "modifyPermissions" && checked) {
      p.grant.general = angular.copy(this.$scope.defaultSet);
    }

    // if something is unchecked, modifyPermissions is also unchecked
    // at least one principal needs to always have modifyPermissions
    const isModifyPermissionsChecked = p.grant.general.indexOf("modifyPermissions") >= 0;

    if (this.$scope.principalsWithModifyAccess.length > 1 && !checked && isModifyPermissionsChecked && option !== "modifyPermissions" && option !== "read") {
      p.grant.general = p.grant.general.filter((action) => action !== "modifyPermissions" && action !== option);
    }

    if (this.$scope.changeListAccess) {
      this.$scope.changeListAccess();
    }

    this.updatePrincipalsWithModifyAccess();

    this.$scope.onUpdate?.();
  };

  private checkDuplicateAccount = (account: AccountPrincipal, permissions: IApiPermission[]): AccountPrincipal => {
    account.used = false;

    if (typeof permissions === "undefined" || !permissions) {
      return account;
    }

    for (const permission of permissions) {
      if (account.id === permission.principalId) {
        account.used = true;

        break;
      }
    }

    return account;
  };

  private checkDuplicateUsers = (users: IUser[], permissions: IApiPermission[]): IUser[] => {
    if (typeof permissions === "undefined" || !permissions) {
      return users.map((user: IUser): IUser => {
        user.used = false;

        return user;
      });
    }

    return users.map((user: IUser): IUser => {
      user.used = false;

      for (const permission of permissions) {
        if (user.id === permission.principalId) {
          user.used = true;

          break;
        }
      }

      return user;
    });
  };

  private checkDuplicateTeams = (teams: ITeam[], permissions: IApiPermission[]): ITeam[] => {
    if (typeof permissions === "undefined" || !permissions) {
      return teams.map((team: ITeam): ITeam => {
        team.used = false;

        return team;
      });
    }

    return teams.map((team: ITeam): ITeam => {
      team.used = false;

      for (const permission of permissions) {
        if (team.id === permission.principalId) {
          team.used = true;

          break;
        }
      }

      return team;
    });
  };

  private checkDuplicateRoles = (roles: IRole[], permissions: IApiPermission[]): IRole[] => {
    if (typeof permissions === "undefined" || !permissions) {
      return roles.map((role: IRole): IRole => {
        role.used = false;

        return role;
      });
    }

    return roles.map((role: IRole): IRole => {
      role.used = false;

      for (const permission of permissions) {
        if (role.id === permission.principalId) {
          role.used = true;

          break;
        }
      }

      return role;
    });
  };

  private checkAllDuplicates = (account: AccountPrincipal, users: IUser[], roles: IRole[], teams: ITeam[], permissions: IApiPermission[]): void => {
    this.account = this.checkDuplicateAccount(account, permissions);
    this.$scope.users = this.checkDuplicateUsers(users, permissions);
    this.$scope.roles = this.checkDuplicateRoles(roles, permissions);
    this.$scope.teams = this.checkDuplicateTeams(teams, permissions);
  };

  private checkDuplicateCollection = (type: IPrincipalKind): void => {
    switch (type) {
      case "user":
        this.checkDuplicateUsers(this.$scope.users, this.$scope.access.permissions);
        break;
      case "team":
        this.checkDuplicateTeams(this.$scope.teams, this.$scope.access.permissions);
        break;
      case "role":
        this.checkDuplicateRoles(this.$scope.roles, this.$scope.access.permissions);
        break;
      case "account":
        this.checkDuplicateAccount(this.account, this.$scope.access.permissions);
        break;

      default:
        break;
    }
  };

  private removePermissions = (permission: IApiPermission): void => {
    const newPermissions: IApiPermission[] = this.$scope.access.permissions.reduce(
      (newCollection: IApiPermission[], currentPermission: IApiPermission): IApiPermission[] => {
        if (permission.principalId !== currentPermission.principalId) {
          newCollection.push(currentPermission);
        }

        return newCollection;
      },
      []
    );

    if (permission.principalId === this.currentUserId) {
      this.modalService.confirm({
        uiTitle: localize("r_u_sure_u_want_remove_ur_permissions"),
        uiContent: localize("permissions_removal_description"),
        uiIconType: null,
        uiOkText: localize("remove"),
        uiOkDanger: true,
        uiOnOk: () => {
          this.$scope.access.permissions = newPermissions;
          this.checkDuplicateCollection(permission.principalKind);
          this.updatePrincipalsWithModifyAccess();

          this.$scope.onUpdate?.();
        },
        uiCancelText: localize("cancel"),
      });
    } else {
      this.$scope.access.permissions = newPermissions;
      this.checkDuplicateCollection(permission.principalKind);
      this.updatePrincipalsWithModifyAccess();

      this.$scope.onUpdate?.();
    }

    if (this.$scope.changeListAccess) {
      this.$scope.changeListAccess();
    }
  };

  private generateNewPermission = (principalId: string, principalKind: IPrincipalKind, defaultSet: UserAction[]): IApiPermission => {
    const allowedActions: UserAction[] = this.$scope.rolesMap[principalId]?.name === "view-only" ? ["read"] : angular.copy(defaultSet);

    return {
      grant: {
        general: allowedActions,
      },
      principalId: principalId,
      principalKind: principalKind,
      includeSubteams: principalKind === "team",
    };
  };

  private generatePrincipals = (account: AccountPrincipal, users: IUser[], teams: ITeam[], roles: IRole[]): Principal[] => {
    const principals: Principal[] = [];

    users.forEach((user: UserPrincipal): void => {
      user.type = "user";
      user.name = `${user.firstName} ${user.lastName}`;

      principals.push(user);
    });

    teams.forEach((team: TeamPrincipal): void => {
      team.type = "team";

      principals.push(team);
    });

    roles.forEach((role: RolePrincipal): void => {
      role.type = "role";

      principals.push(role);
    });

    principals.push(account);

    return principals;
  };

  private getPrincipals = (partialName: string): Principal[] => {
    const limitResultsTo = 20;

    return this.principals
      .filter((principal: UserPrincipal) => {
        return principal.name.toLowerCase().indexOf(partialName.toLowerCase()) > -1 && principal.isActive !== false;
      })
      .slice(0, limitResultsTo);
  };

  private addPermission = (principal: Principal): void => {
    this.$scope.typeahead.filter = null;

    if (principal.used === true) {
      return;
    }

    const newPermission: IApiPermission = this.generateNewPermission(principal.id, <IPrincipalKind>principal.type, this.$scope.defaultSet);

    if (!this.$scope.access) {
      this.$scope.access = {
        inherits: false,
      };
    }

    if (!this.$scope.access.permissions || this.$scope.access.permissions === null || typeof this.$scope.access.permissions === "undefined") {
      this.$scope.access.permissions = [];
    }

    this.$scope.access.permissions.push(newPermission);

    this.checkDuplicateCollection(<IPrincipalKind>principal.type);

    if (this.$scope.changeListAccess) {
      this.$scope.changeListAccess();
    }

    this.updatePrincipalsWithModifyAccess();

    this.$scope.onUpdate?.();
  };

  private selectFromList = (): void => {
    let selectedIds: string[] = [];

    if (this.$scope.access && this.$scope.access.permissions) {
      selectedIds = this.$scope.access.permissions.reduce((ids: string[], currentPermission) => {
        ids.push(currentPermission.principalId);

        return ids;
      }, []);
    }

    const modalInstance = this.$uibModal.open({
      controller: "OwnerSelectorCtrl",
      template: require("../../assignees/views/owner-selector.html"),
      windowClass: "sidebar-modal overflow-hidden",
      resolve: {
        principalIds: () => selectedIds,
      },
    });
    modalInstance.result.then((selectedPrincipalIds: string[]) => {
      this.onPermissionsChosen(selectedPrincipalIds);
    }, noop);
  };

  private permissionExists = (principalId: string, permissions: IApiPermission[]): { permission: IApiPermission; used: boolean } => {
    for (const permission of permissions) {
      if (permission.principalId === principalId) {
        return {
          permission: permission,
          used: true,
        };
      }
    }

    return {
      permission: null,
      used: false,
    };
  };

  private findPermissionKind = (principalId: string, usersMap: IUsersIdMap, rolesMap: IdMap<IRole>, teamsMap: ITeamsIdMap, accountId: string): IPrincipalKind => {
    if (accountId === principalId) {
      return "account";
    }

    if (usersMap[principalId]) {
      return "user";
    }

    if (rolesMap[principalId]) {
      return "role";
    }

    if (teamsMap[principalId]) {
      return "team";
    }
  };

  private onPermissionsChosen = (permissions: string[]): void => {
    const oldPermissions: IApiPermission[] = this.$scope.access && this.$scope.access.permissions ? angular.copy(this.$scope.access.permissions) : [];

    if (!this.$scope.access) {
      this.$scope.access = {
        inherits: false,
      };
    }

    this.$scope.access.permissions = permissions.reduce((newCollection: IApiPermission[], currentPrincipalId: string): IApiPermission[] => {
      const permissionCheck: { permission: IApiPermission; used: boolean } = this.permissionExists(currentPrincipalId, oldPermissions);

      if (permissionCheck.used) {
        newCollection.push(permissionCheck.permission);
      } else {
        const permissionKind: IPrincipalKind = this.findPermissionKind(
          currentPrincipalId,
          this.$scope.usersMap,
          this.$scope.rolesMap,
          this.$scope.teamsIdMap,
          this.account.id
        );

        const newPermission: IApiPermission = this.generateNewPermission(currentPrincipalId, permissionKind, this.$scope.defaultSet);

        newCollection.push(newPermission);
      }

      return newCollection;
    }, []);

    this.checkAllDuplicates(this.account, this.$scope.users, this.$scope.roles, this.$scope.teams, this.$scope.access.permissions);
    this.updatePrincipalsWithModifyAccess();

    this.$scope.onUpdate?.();
  };

  private filterInvalidPrincipals = (permissions: IApiPermission[]): IApiPermission[] => {
    return permissions.filter((p: IApiPermission): boolean => {
      switch (p.principalKind) {
        case "role":
          return typeof this.$scope.rolesMap[p.principalId] !== "undefined";
        case "team":
        case "team_and_subteams":
          return typeof this.$scope.teamsIdMap[p.principalId] !== "undefined";
        case "user":
          return typeof this.$scope.usersMap[p.principalId] !== "undefined";
        default:
          return true;
      }
    });
  };

  private setViewOnlyRoleAllowedActions(permissions: IApiPermission[]): void {
    if (!Array.isArray(permissions) || permissions.length === 0) {
      return;
    }

    const viewOnlyRolePermission = permissions.find((permission) => this.$scope.rolesMap[permission.principalId]?.name === "view-only");
    if (viewOnlyRolePermission) {
      viewOnlyRolePermission.grant.general = ["read"];
    }
  }
}
