import { HttpClient, HttpErrorResponse, HttpParams } from "@angular/common/http";
import { Inject } from "@angular/core";
import { Observable, tap, throwError } from "rxjs";
import { catchError, finalize } from "rxjs/operators";
import { HttpActions } from "@webapp/core/abstracts/enums/http-actions.enum";
import { ApiVersionModel } from "@webapp/core/abstracts/models/api-version.model";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseState } from "@webapp/core/abstracts/services/base-state.service";
import { APP_CONFIG, IAppConfig } from "@webapp/core/app-config/models/app-config.models";
import { BaseListModel } from "../models/base-list.model";
import { RequestPaging } from "../models/request.paging";
import { HttpEncoderService } from "./http-encoder.service";

export abstract class BaseApiService<ItemModel, DTOModel, StateModel extends BaseState> {
  protected constructor(
    @Inject(String) entityPath: string,
    @Inject(ApiVersionModel) apiVersion: ApiVersionModel,
    httpClient: HttpClient,
    @Inject(APP_CONFIG) protected appConfig: IAppConfig,
    protected state: StateModel
  ) {
    this.httpClient = httpClient;
    this.entityPath = entityPath;
    this.apiVersion = apiVersion;
  }
  protected entityPath: string;
  protected apiVersion: ApiVersionModel;
  protected httpClient: HttpClient;

  public getAll$<ResponseType = BaseListModel<ItemModel>>(filters?: RequestPaging, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config && config.url ? config.url : this.getBasePath(HttpActions.getAll);

    this.resetInitialState(HttpActions.getAll);

    return this.httpClient
      .get<ResponseType>(url, {
        params: this.encodeQueryParams(config, filters),
        headers: config.headers,
        context: config.context,
      })
      .pipe(
        catchError((response) => this.handleRequestError(HttpActions.getAll, response)),
        finalize(() => this.handleRequestFinalized(HttpActions.getAll)),
        tap(() => this.handleRequestSuccess(HttpActions.getAll))
      );
  }

  public get$<ResponseType = ItemModel>(id: number | string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    let url = config && config.url ? config.url : `${this.getBasePath(HttpActions.get)}/${id}`;
    const hasUrlTrailingSlash = url.charAt(url.length - 1) === "/";

    // if the url has a trailing slash, this will lead to a redirect
    if (hasUrlTrailingSlash) {
      url = url.slice(0, url.length - 1);
    }

    this.resetInitialState(HttpActions.get);

    return this.httpClient
      .get<ResponseType>(`${url}`, {
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      })
      .pipe(
        catchError((response) => {
          return this.handleRequestError(HttpActions.get, response);
        }),
        finalize(() => this.handleRequestFinalized(HttpActions.get)),
        tap(() => this.handleRequestSuccess(HttpActions.get))
      );
  }

  public post$<ResponseType = ItemModel>(
    model: Partial<ItemModel> | Partial<DTOModel> | string[] | FormData,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    const url = config && config.url ? config.url : this.getBasePath(HttpActions.post);

    this.resetInitialState(HttpActions.post);
    return this.httpClient
      .post<ResponseType>(`${url}`, model, {
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      })
      .pipe(
        catchError((response) => this.handleRequestError(HttpActions.post, response)),
        finalize(() => this.handleRequestFinalized(HttpActions.post)),
        tap(() => this.handleRequestSuccess(HttpActions.post))
      );
  }

  public put$<ResponseType = ItemModel>(
    id: number | string,
    model: Partial<ItemModel> | Partial<DTOModel>,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    const url = config && config.url ? config.url : `${this.getBasePath(HttpActions.put)}/${id}`;
    const body = Array.isArray(model) ? [...model] : { ...model };

    this.resetInitialState(HttpActions.put);

    return this.httpClient
      .put<ResponseType>(`${url}`, body, {
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      })
      .pipe(
        catchError((response) => this.handleRequestError(HttpActions.put, response)),
        finalize(() => this.handleRequestFinalized(HttpActions.put)),
        tap(() => this.handleRequestSuccess(HttpActions.put))
      );
  }

  public patch$<ResponseType = ItemModel>(
    id: number | string,
    model: Partial<ItemModel> | Partial<DTOModel>,
    config: RequestConfig = new RequestConfig()
  ): Observable<ResponseType> {
    const url = config && config.url ? config.url : `${this.getBasePath(HttpActions.patch)}/${id}`;

    this.resetInitialState(HttpActions.patch);

    return this.httpClient
      .patch<ResponseType>(
        `${url}`,
        {
          ...model,
        },
        {
          params: this.encodeQueryParams(config),
          headers: config.headers,
          context: config.context,
        }
      )
      .pipe(
        catchError((response) => this.handleRequestError(HttpActions.patch, response)),
        finalize(() => this.handleRequestFinalized(HttpActions.patch)),
        tap(() => this.handleRequestSuccess(HttpActions.patch))
      );
  }

  public delete$<ResponseType = ItemModel>(id: number | string, config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config && config.url ? config.url : `${this.getBasePath(HttpActions.delete)}/${id}`;

    this.resetInitialState(HttpActions.delete);

    return this.httpClient
      .delete<ResponseType>(`${url}`, {
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      })
      .pipe(
        catchError((response) => this.handleRequestError(HttpActions.delete, response)),
        finalize(() => this.handleRequestFinalized(HttpActions.delete)),
        tap(() => this.handleRequestSuccess(HttpActions.delete))
      );
  }

  public deleteMany$<ResponseType = ItemModel>(ids: string[], config: RequestConfig = new RequestConfig()): Observable<ResponseType> {
    const url = config && config.url ? config.url : this.getBasePath(HttpActions.delete);

    this.resetInitialState(HttpActions.delete);

    return this.httpClient
      .delete<ResponseType>(`${url}`, {
        body: ids,
        params: this.encodeQueryParams(config),
        headers: config.headers,
        context: config.context,
      })
      .pipe(
        catchError((response) => this.handleRequestError(HttpActions.delete, response)),
        finalize(() => this.handleRequestFinalized(HttpActions.delete)),
        tap(() => this.handleRequestSuccess(HttpActions.delete))
      );
  }

  public getBasePath(httpAction: HttpActions, params: { apiVersionOverwrite?: string } = {}): string {
    return `${this.appConfig.endpoints.secure ? "https://" : "http://"}${this.appConfig.endpoints.apiEndpoint}/${
      params.apiVersionOverwrite || this.apiVersion[httpAction]
    }/${this.entityPath}`;
  }

  public getQuantivePlusBasePath(httpAction: HttpActions, params: { apiVersionOverwrite?: string } = {}): string {
    return `${this.appConfig.endpoints.secure ? "https://" : "http://"}${this.appConfig.endpoints.quantivePlusApi}/${
      params.apiVersionOverwrite || this.apiVersion[httpAction]
    }/${this.entityPath}`;
  }

  protected resetInitialState(method: HttpActions): void {
    this.state.setIsLoading(method, true);
    this.state.setIsError(method, null);
    this.state.setIsSuccess(method, false);
  }

  protected handleRequestError(method: HttpActions, response: HttpErrorResponse): Observable<never> {
    this.state.setIsError(method, response);
    return throwError(response);
  }

  protected handleRequestFinalized(method: HttpActions): void {
    this.state.setIsLoading(method, false);
  }

  protected handleRequestSuccess(method: HttpActions): void {
    this.state.setIsSuccess(method, true);
  }

  private encodeQueryParams(config: RequestConfig, filters?: RequestPaging): HttpParams {
    const queryParams = { ...config.queryParams };

    if (filters) {
      for (const [key, value] of Object.entries(filters)) {
        if (key === "filter") {
          queryParams[key] = `${JSON.stringify(value)}`;
          continue;
        }

        if (key === "fields" || key === "sort") {
          queryParams[key] = `${filters[key].join(",")}`;
          continue;
        }

        queryParams[key] = value.toFixed(0);
      }
    }

    return new HttpParams({
      fromObject: { ...queryParams },
      encoder: new HttpEncoderService(),
    });
  }
}
