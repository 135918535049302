<i class="sp-mr-10" [uiType]="icon" ui-icon uiTheme="fill" uiSize="sm"></i>
<div
  #nameElem
  *ngIf="isNameReadonly()"
  [ngClass]="{ name: !isDisabled, disabled: isDisabled, bold: bold }"
  [tabindex]="tabIndex"
  [attr.aria-haspopup]="ariaHasPopup"
  [attr.aria-label]="ariaLabel"
  [attr.aria-describedby]="a11yDescribedby"
  [uiTooltipTitle]="name || placeholder"
  [tooltipIfOverflow]="showTooltipWhenTextHidden ? 'hover' : null"
  ui-tooltip
  role="link">
  {{ name || placeholder }}
</div>

<input class="input" *ngIf="!name && isEditable" [(ngModel)]="newName" [placeholder]="placeholder" (blur)="inputChanged()" (keyup.enter)="inputChanged()" ui-input />
