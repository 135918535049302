<div class="simple-global-search-actions" [uiVisible]="shouldShowPopover" [uiContent]="resultTemplate" [uiTrigger]="null" ui-popover uiOverlayClassName="without-arrow" uiPlacement="bottomLeft">
  <input
    class="search-field"
    id="search-input"
    #searchInput
    [(ngModel)]="searchTerm"
    [placeholder]="searchPlaceholder"
    [modelChangeDebounce]="500"
    (ngModelChange)="onSearchTermChange()"
    (keydown.arrowDown)="focusSearchItems()" />
</div>
<ng-template #resultTemplate>
  <div class="search-results" #resultsPopover>
    <search-results [maxItems]="totalNumberOfItems" [isCustomClickHandlerAvailable]="true" (escKeydown)="onEsc()" searchMode="simple"></search-results>
  </div>
</ng-template>
