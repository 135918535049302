<div
  class="centered-vert hov-cursor-p justify-cont-c"
  [attr.aria-label]="a11yLabel"
  [attr.aria-pressed]="isFavorite"
  (click)="toggleFavorite()"
  (keydown.enter)="toggleFavorite()"
  role="button"
  tabindex="0">
  <i class="fs-16px" *ngIf="!isFavorite" ui-icon uiType="favorite" uiTheme="outline"></i>
  <i class="fs-16px" *ngIf="isFavorite" ui-icon uiType="favorite" uiTheme="fill"></i>
</div>
