import { IPromise, IScope } from "angular";
import { StateService } from "@uirouter/angularjs";
import { IBadgesIdMap } from "@gtmhub/badges";
import { BadgesActions } from "@gtmhub/badges/redux/badges-actions";
import { IBadgesStoreState } from "@gtmhub/badges/redux/badges-reducer";
import { IStateInit } from "@gtmhub/core/routing";
import { ApmSpanService } from "@gtmhub/core/tracing/apm-span.service";
import { IIndicator, IUIError, UIErrorHandlingService } from "@gtmhub/error-handling";
import { IFeedEntry, IFeedEntryComment } from "@gtmhub/feed/models";
import { FeedActions } from "@gtmhub/feed/redux/feed-actions";
import { IFeedStoreState } from "@gtmhub/feed/redux/feed-reducer";
import { getTargetTypeForComments } from "@gtmhub/feed/utils";
import { IExtendedRootScopeService } from "@gtmhub/shared/ng-extensions";
import { USER_ROLES_VIEWONLY } from "@gtmhub/shared/utils/user-roles";
import { INgRedux } from "@gtmhub/state-management";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { AggregatedReaction, Reaction, ReactionsFacade } from "@webapp/comments";
import { ICollection } from "@webapp/core/core.models";
import { SizeOfGif } from "@webapp/shared/components/gifs/gif.models";
import { CurrentUserRepository } from "@webapp/users";

export interface IFeedParams {
  skip: number;
  take: number;
}

interface IFeedsRedux {
  feedItems: ICollection<IFeedEntry>;
  badges: IBadgesIdMap;
  itemsFetched: boolean;
  selectedFeedEntry: IFeedEntry;
  feedCommentUserIdsMap: Record<string, string[]>;
  indicators: {
    initialLoad?: IIndicator;
    gettingMoreData?: IIndicator;
  };
  reduxStateError: IUIError;
}

const getCommentUserIds = (feedEntry: IFeedEntry) => [...new Set(feedEntry.comments.map((it) => it.createdBy))].reverse();

const bindStateToScope = (state: IBadgesStoreState & IFeedStoreState): IFeedsRedux => {
  const { map: badges, isFetched: badgesFetched, error: badgesError } = state.badges;
  const { error: feedError } = state.feed;

  return {
    badges: badges,
    feedItems: {
      items: state.feed.feed,
      totalCount: state.feed.totalCount,
    },
    feedCommentUserIdsMap: state.feed.feed.reduce((result, v) => ({ ...result, [v.id]: getCommentUserIds(v) }), {}),
    selectedFeedEntry: state.feed.selectedFeedEntry,
    itemsFetched: badgesFetched && !state.feed.isFetching,
    indicators: {
      initialLoad: state.feed.isFetched ? undefined : { progress: true },
      gettingMoreData: state.feed.isFetching ? { progress: true } : undefined,
    },
    reduxStateError: badgesError || feedError,
  };
};

export interface IFeedScope extends IScope, IExtendedRootScopeService, IFeedsRedux {
  sizeOfGif: typeof SizeOfGif;
  itemsError: IUIError;
  feedItems: ICollection<IFeedEntry>;
  feedCommentUserIdsMap: Record<string, string[]>;
  isCurrentUserViewOnly: boolean;
  loadFeedItems(): void;
  getAssigneeType(): void;
  getLastComment(IFeedEntry): IFeedEntryComment;
  openCommentThreadForFeedEntry(IFeedEntry): void;
  getTargetTypeForComments(string): string;
}

export class FeedCtrl implements IStateInit {
  public static $inject = [
    "$ngRedux",
    "$state",
    "$scope",
    "UIErrorHandlingService",
    "BadgesActions",
    "FeedActions",
    "ReactionsFacade",
    "AnalyticsService",
    "ApmSpanService",
    "CurrentUserRepository",
  ];

  constructor(
    private $ngRedux: INgRedux,
    private $state: StateService,
    private $scope: IFeedScope,
    private uiErrorHandlingService: UIErrorHandlingService,
    badgesActions: BadgesActions,
    private feedActions: FeedActions,
    private reactionsFacade: ReactionsFacade,
    private analyticsService: AnalyticsService,
    private apmSpanService: ApmSpanService,
    private currentUserRepository: CurrentUserRepository
  ) {
    const unsubscribe = $ngRedux.connect(bindStateToScope)(this.$scope);
    const unsubscribeCreatedReaction = this.$scope.$on("reactionCreated", this.onReactionCreated);
    const unsubscribeDeletedReaction = this.$scope.$on("reactionDeleted", this.onReactionDeleted);
    $ngRedux.dispatch(badgesActions.fetchBadgesIfMissing());
    this.$scope.isCurrentUserViewOnly = this.currentUserRepository.userHasRole(USER_ROLES_VIEWONLY);

    this.$scope.$on("$destroy", () => {
      unsubscribe();
      unsubscribeCreatedReaction();
      unsubscribeDeletedReaction();
    });
  }

  private feedItemsParams: IFeedParams;

  public stateInit(): IPromise<unknown> {
    return this.init();
  }

  public getLastComment(feedEntry: IFeedEntry): IFeedEntryComment {
    const length = feedEntry.comments.length;

    if (length === 0) {
      return null;
    }

    return feedEntry.comments[feedEntry.comments.length - 1];
  }

  private init = (): IPromise<unknown> => {
    this.$scope.sizeOfGif = SizeOfGif;
    this.$scope.loadFeedItems = this.loadFeedItems;
    this.$scope.getAssigneeType = this.getAssigneeType;
    this.$scope.getLastComment = this.getLastComment;
    this.$scope.openCommentThreadForFeedEntry = (item: IFeedEntry) => this.openCommentThread(item);
    this.$scope.getTargetTypeForComments = getTargetTypeForComments;

    this.feedItemsParams = {
      skip: 0,
      take: 10,
    };

    return this.apmSpanService.dataLoadSpan("init", () => {
      return this.$scope
        .watchUntilAndRejectOnError(
          () => this.$scope.itemsFetched,
          () => this.$scope.itemsError
        )
        .then(
          () => {
            this.$ngRedux.dispatch(this.feedActions.clearFeedData());
            this.$scope.$broadcast("focusTextInput");

            this.analyticsService.track("Home Data Loaded", { state: "feed" });

            this.loadFeedItems();
          },
          () => this.uiErrorHandlingService.handleModal(this.$scope.itemsError)
        );
    });
  };

  private onReactionCreated = (event: ng.IAngularEvent, newReaction: Reaction): void => {
    switch (newReaction.targetType) {
      case "feed":
      case "checkin": {
        const targetFeed = this.$scope.feedItems.items.find((feed) => feed.targetId === newReaction.targetId);

        if (targetFeed) {
          targetFeed.reactions = this.reactionsFacade.onReactionCreated(targetFeed.reactions, newReaction);
        }
        break;
      }
      case "comment": {
        let indexFoundComment = -1;
        const targetCommentFeed = this.$scope.feedItems.items.find((feed) => {
          if (feed.comments.length === 0) {
            return false;
          }

          let found = false;
          feed.comments.forEach((comment, index) => {
            if (comment.targetId === newReaction.targetId) {
              indexFoundComment = index;
              found = true;
            }
          });

          return found;
        });

        if (indexFoundComment > -1) {
          targetCommentFeed.comments[indexFoundComment].reactions = this.reactionsFacade.onReactionCreated(
            targetCommentFeed.comments[indexFoundComment].reactions,
            newReaction
          );
        }
        break;
      }
    }
  };

  private onReactionDeleted = (event: ng.IAngularEvent, deletedReaction: AggregatedReaction): void => {
    switch (deletedReaction.targetType) {
      case "feed":
      case "checkin": {
        const targetFeed = this.$scope.feedItems.items.find((feed) => feed.targetId === deletedReaction.targetId);

        if (targetFeed) {
          targetFeed.reactions = this.reactionsFacade.onReactionDeleted(targetFeed.reactions, deletedReaction);
        }
        break;
      }
      case "comment": {
        let indexFoundComment = -1;
        const targetCommentFeed = this.$scope.feedItems.items.find((feed) => {
          if (feed.comments.length === 0) {
            return false;
          }
          let found = false;
          feed.comments.forEach((comment, index) => {
            if (comment.targetId === deletedReaction.targetId) {
              indexFoundComment = index;
              found = true;
            }
          });

          return found;
        });

        if (indexFoundComment > -1) {
          targetCommentFeed.comments[indexFoundComment].reactions = this.reactionsFacade.onReactionDeleted(
            targetCommentFeed.comments[indexFoundComment].reactions,
            deletedReaction
          );
        }

        break;
      }
    }
  };

  private getAssigneeType = () => {
    return "user";
  };

  private loadFeedItems = (): void => {
    if (!this.$scope.indicators.initialLoad) {
      this.analyticsService.track("Feed Scrolled");
    }

    const { items, totalCount } = this.$scope.feedItems;
    if (items.length >= totalCount && !this.$scope.indicators.initialLoad) {
      return;
    }

    this.$ngRedux.dispatch(this.feedActions.fetchFeed(this.feedItemsParams));
  };

  private openCommentThread = (feedEntry: IFeedEntry) => {
    this.$state.go("._comment.thread", { targetId: feedEntry.id });
  };
}
