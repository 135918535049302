import { CommonModule } from "@angular/common";
import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from "@angular/core";
import { UiTooltipModule } from "@quantive/ui-kit/tooltip";
import { LocalizationModule } from "@webapp/localization/localization.module";
import { ConfidenceExtractorService, ExctractedConfidenceValues } from "@webapp/shared/services/confidence-extractor.service";
import { UiTagModule } from "@webapp/ui/tag/tag.module";

@Component({
  selector: "confidence-label",
  templateUrl: "./confidence-label.component.html",
  styleUrls: ["./confidence-label.component.less"],
  host: {
    "[class.small]": `size === 'small'`,
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, UiTagModule, UiTooltipModule, LocalizationModule],
  providers: [ConfidenceExtractorService],
})
export class ConfidenceLabelComponent implements OnInit, OnChanges {
  @Input() public set confidenceValue(value: string | number) {
    this.value = typeof value === "number" ? value.toString() : value;
  }
  @Input() public isPreview = false;

  @Input() public size: "small" | "default" = "default";

  public get confidenceValue(): string {
    return this.value;
  }
  private value: string;

  public maxConfidenceCharactersLength = 6;

  public displayValues: ExctractedConfidenceValues = {
    confidenceName: null,
    color: null,
    emojiClass: null,
    confidenceType: null,
    confidenceLevelTooltip: null,
  };

  constructor(private confidenceExtractorService: ConfidenceExtractorService) {}

  public ngOnInit(): void {
    this.displayValues = this.confidenceExtractorService.extractConfidenceValues(this.confidenceValue);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.confidenceValue) {
      this.displayValues = this.confidenceExtractorService.extractConfidenceValues(this.confidenceValue);
    }
  }
}
