import { AfterViewInit, ContentChild, Directive, EventEmitter, Input, Output } from "@angular/core";
import { UntilDestroy, untilDestroyed } from "@ngneat/until-destroy";
import { StorageService } from "@webapp/core/storage/services/storage.service";
import { UiCollapsePanelComponent } from "@webapp/ui/collapse/components/collapse-panel/collapse-panel.component";
import { SectionExpandState } from "./assignee-active-okrs-list.models";
import { SessionSummaryReportComponent } from "./session-summary-report/session-summary-report.component";

@UntilDestroy()
@Directive({
  selector: "ui-collapse-panel[assignee-active-okrs-list-state]",
  standalone: true,
})
export class AssigneeActiveOkrsListStateDirective implements AfterViewInit {
  @Input() public stateAssigneeId: string;
  @Input() public stateSessionId: string;
  @Input() public defaultState: SectionExpandState;
  @Output() public readonly stateChange = new EventEmitter<SectionExpandState>();

  @ContentChild(SessionSummaryReportComponent) public sectionSummaryReportComponent: SessionSummaryReportComponent;

  constructor(
    private uiCollapsePanelComponent: UiCollapsePanelComponent,
    private storageService: StorageService
  ) {}

  public ngAfterViewInit(): void {
    const expandedState = this.initializeState();

    if (this.uiCollapsePanelComponent) {
      if (expandedState[this.stateAssigneeId][this.stateSessionId]) {
        this.stateChange.emit(expandedState[this.stateAssigneeId][this.stateSessionId]);
      }

      this.watchForPanelChanges();
    }

    if (this.sectionSummaryReportComponent) {
      this.watchForSummaryChanges();
    }
  }

  private initializeState(): Record<string, SectionExpandState> {
    let expandedState = this.storageService.get<Record<string, SectionExpandState>>("okrs-list-state");
    if (!expandedState) {
      expandedState = {};
    }
    if (!expandedState[this.stateAssigneeId]) {
      expandedState[this.stateAssigneeId] = {};
    }
    if (!expandedState[this.stateAssigneeId][this.stateSessionId] && this.defaultState) {
      expandedState[this.stateAssigneeId][this.stateSessionId] = this.defaultState;
    }

    if (Object.keys(expandedState[this.stateAssigneeId]).length) {
      this.storageService.set("okrs-list-state", expandedState);
    }

    return expandedState;
  }

  private watchForPanelChanges(): void {
    this.uiCollapsePanelComponent.nzActiveChange.pipe(untilDestroyed(this)).subscribe((active) => {
      const expandedState = this.storageService.get<Record<string, SectionExpandState>>("okrs-list-state") || {};

      if (!expandedState[this.stateAssigneeId]) {
        expandedState[this.stateAssigneeId] = {};
      }

      if (!expandedState[this.stateAssigneeId][this.stateSessionId] && active) {
        expandedState[this.stateAssigneeId][this.stateSessionId] = {
          panel: active,
        };
      }
      expandedState[this.stateAssigneeId][this.stateSessionId].panel = active;
      this.storageService.set("okrs-list-state", expandedState);
      this.stateChange.emit(expandedState[this.stateAssigneeId][this.stateSessionId]);
    });
  }

  private watchForSummaryChanges(): void {
    this.sectionSummaryReportComponent.expandedChange.pipe(untilDestroyed(this)).subscribe((expanded) => {
      const expandedState = this.storageService.get<Record<string, SectionExpandState>>("okrs-list-state") || {};

      if (!expandedState[this.stateAssigneeId]) {
        expandedState[this.stateAssigneeId] = {};
      }

      if (!expandedState[this.stateAssigneeId][this.stateSessionId] && expanded) {
        expandedState[this.stateAssigneeId][this.stateSessionId] = {
          summary: expanded,
        };
      }
      expandedState[this.stateAssigneeId][this.stateSessionId].summary = expanded;
      this.storageService.set("okrs-list-state", expandedState);
      this.stateChange.emit(expandedState[this.stateAssigneeId][this.stateSessionId]);
    });
  }
}
