import { IComponentOptions, IPromise, IQService, IScope, ITimeoutService, IWindowService } from "angular";
import { StateService } from "@uirouter/angular";
import { combineLatest, take } from "rxjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { SCIMService } from "@gtmhub/configuration/services/scim.service";
import { AccountType, GtmhubController, IAccount, ILicense } from "@gtmhub/core";
import { IModalComponent } from "@gtmhub/core/routing";
import { untilScopeDestroyed } from "@gtmhub/core/rxjs";
import { storage } from "@gtmhub/core/storage";
import { ITraceRootScopeService } from "@gtmhub/core/tracing";
import { IIndicator, UIErrorHandlingService } from "@gtmhub/error-handling";
import { localize } from "@gtmhub/localization";
import { SnackbarEvents } from "@gtmhub/shared/components/ui/snackbar/snackbar.events";
import { INgRedux } from "@gtmhub/state-management";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { IInvitationRequestWithTeamsAndRoles, IInvitationRequestWithTeamsAndRolesMap, IUser, UserService } from "@gtmhub/users";
import { IPermissionsStoreState, hasPermissions } from "@gtmhub/users/redux";
import { toKeyMap } from "@gtmhub/util";
import { validEmail } from "@gtmhub/util/email-regex";
import { EditionFeatureService } from "@webapp/accounts/services/edition-feature.service";
import { AnalyticsService } from "@webapp/analytics/services/analytics.service";
import { IAccountSubscription } from "@webapp/configuration/models/configuration.model";
import { ICollection } from "@webapp/core/core.models";
import { CfMap } from "@webapp/custom-fields/models/custom-fields.models";
import { FeatureFlag } from "@webapp/feature-toggles/models/feature-toggles.models";
import { FeatureTogglesFacade } from "@webapp/feature-toggles/services/feature-toggles-facade.service";
import { PermissionsFacade } from "@webapp/permissions/services/permissions-facade.service";
import { ErrorNotificationMessage } from "@webapp/shared/error-notification/error-notification.models";
import { IInvitationRequest, IUserAccountSubscription } from "../../models";

const UNCHARGEABLE = "unchargeable";
const REGULAR = "regular";
const RESTRICTED = "restricted";

interface IUserInvitationComponentBindings extends IModalComponent {
  close(): void;
}

interface IEmailTag {
  id: string;
  text: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface UserInvitationCtrl extends IUserInvitationComponentBindings {}
export class UserInvitationCtrl extends GtmhubController {
  accountLicenses: ILicense[];
  accountSubscription: IUserAccountSubscription;
  allowUserInvitation: boolean;
  allowChangeLicense: boolean;
  shouldFillAllForms: boolean;
  invitations: IInvitationRequestWithTeamsAndRolesMap;
  unifiedInvitation: IInvitationRequestWithTeamsAndRoles;
  emailsList: IEmailTag[];
  sendEmailInvitation: boolean;
  requiredCfNotEmpty: boolean;
  currentEmailCheckups: {
    [email: string]: {
      valid?: boolean;
      error?: string;
    };
  };
  incorrectEmails: {
    lockMultiAccount: string[];
    alreadyExists: string[];
    invalidAddresess: string[];
    hasIncorrectEmail: boolean;
  };
  indicators: {
    inviting?: IIndicator;
  };
  isSkipEmailInvitationAllowed: boolean;
  shouldAllowInvitationLinkGeneration: boolean;
  hasAccountAzureSubscription: boolean;
  public isInvitationLinkGenerated: boolean;
  public isScimProvisioningEnabled: boolean;
  public errorNotification: ErrorNotificationMessage;
  public canAssignTeams: boolean;

  private defaultInvitation: IInvitationRequestWithTeamsAndRoles;
  private snackbarEvents: SnackbarEvents;

  static $inject = [
    "$timeout",
    "$rootScope",
    "$q",
    "$state",
    "UserService",
    "AccountService",
    "AccountResolverService",
    "AnalyticsService",
    "UIErrorHandlingService",
    "SCIMService",
    "$scope",
    "$window",
    "$ngRedux",
    "FeatureTogglesFacade",
    "PermissionsFacade",
    "EditionFeatureService",
  ];

  constructor(
    private $timeout: ITimeoutService,
    private $rootScope: ITraceRootScopeService,
    private $q: IQService,
    private $state: StateService,
    private userService: UserService,
    private accountService: AccountService,
    private accountResolverService: AccountResolverService,
    private analyticsService: AnalyticsService,
    private uiErrorHandlingService: UIErrorHandlingService,
    private scimService: SCIMService,
    private $scope: IScope,
    private $window: IWindowService,
    private $ngRedux: INgRedux,
    private featureTogglesFacade: FeatureTogglesFacade,
    private permissionsFacade: PermissionsFacade,
    private editionFeatureService: EditionFeatureService
  ) {
    super();

    this.getAccountLicenses();
    combineLatest([
      this.featureTogglesFacade.isFeatureAvailable$(FeatureFlag.UsersGranularAccess),
      this.permissionsFacade.hasPermission$("users:invite"),
      this.permissionsFacade.hasPermission$("ManageUsers"),
      this.permissionsFacade.hasPermission$("users:manage_license"),
      this.editionFeatureService.hasFeature$("people.invite"),
    ])
      .pipe(take(1), untilScopeDestroyed(this.$scope))
      .subscribe({
        next: ([isUsersGranularAccessEnabled, hasUsersInvitePermissions, hasManageUsersPermission, hasUsersManageLicensePermissions, hasPeopleInviteFeature]) => {
          if (isUsersGranularAccessEnabled) {
            this.allowUserInvitation = hasUsersInvitePermissions && hasPeopleInviteFeature;
            this.allowChangeLicense = hasUsersManageLicensePermissions;
          } else {
            this.allowUserInvitation = hasManageUsersPermission && hasPeopleInviteFeature;
            this.allowChangeLicense = true;
          }
        },
      });

    this.snackbarEvents = new SnackbarEvents(this.$rootScope);
    this.scimService.checkIfScimProvisioningIsOn().then(
      (isScimProvisioningEnabled) => {
        this.isScimProvisioningEnabled = isScimProvisioningEnabled;
      },
      (error) => {
        this.uiErrorHandlingService.handleModal(error);
      }
    );

    this.$scope.$on("modal.closing", function (event) {
      if (storage.get("preventAccidentalOutisdeClick") === true) {
        storage.set("preventAccidentalOutisdeClick", false);
        event.preventDefault();
      }
    });
  }

  $onInit(): void {
    this.emailsList = [];
    this.invitations = {};
    this.accountSubscription = {};
    this.shouldFillAllForms = false;
    this.sendEmailInvitation = true;
    this.currentEmailCheckups = {};
    this.indicators = {};
    this.setShouldAllowInvitationLinkGeneration();
    this.checkForInputErrors();

    const account = this.accountResolverService.getAccountData();
    this.hasAccountAzureSubscription = account.saasSubscriptionId && account.subscriptionProvider === "azure";

    this.setIsSkipEmailInvitationAllowed(account);
    this.setCanAssignTeams();
  }

  mousedown(): void {
    storage.set("preventAccidentalOutisdeClick", true);
  }
  mouseup(): void {
    storage.set("preventAccidentalOutisdeClick", false);
  }

  removeInvitationForm(email: string): void {
    this.emailsList = this.emailsList.filter(({ text: e }) => e !== email);
    delete this.invitations[email];

    this.checkForInputErrors();
    this.setSubscriptionLimit();
  }

  toggleFillAllForms(): void {
    this.shouldFillAllForms = !this.shouldFillAllForms;
    this.setDataToForm();
    this.$scope.$evalAsync();
  }

  showFillAllToggle(): boolean {
    return Object.entries(this.invitations).length > 1;
  }

  shouldDisableInviteButton(): boolean {
    const requiredCfNotEmpty = this.shouldFillAllForms ? this.unifiedInvitation.requiredCfNotEmpty : this.requiredCfNotEmpty;

    return !this.invitationsLength() || this.incorrectEmails.hasIncorrectEmail || !requiredCfNotEmpty;
  }

  updateCustomFields(invitation: IInvitationRequest, customFields: CfMap): void {
    invitation.customFields = customFields;
  }

  setRequiredCf(invitation: IInvitationRequestWithTeamsAndRoles, requiredCf: { areFilled: boolean }): void {
    invitation.requiredCfNotEmpty = requiredCf.areFilled;
    this.requiredCfNotEmpty = Object.values(this.invitations).every((invitation) => invitation.requiredCfNotEmpty);

    this.$scope.$evalAsync();
  }

  invitationsLength(): number {
    return Object.keys(this.invitations).length;
  }

  private addInvitationForm(email: string): void {
    this.invitations[email] = { ...this.defaultInvitation, email };
  }

  private setIsSkipEmailInvitationAllowed(account: IAccount): void {
    const accountType = account.type;
    this.isSkipEmailInvitationAllowed = accountType !== AccountType.TrialAccount;
  }

  invite(): void {
    this.$rootScope.traceAction("send_invitation", () => {
      this.indicators = {
        inviting: { progress: true },
      };

      if (this.shouldFillAllForms) {
        this.fillInvitationsWithUnifiedInvitationData();
      }

      this.userService
        .createUsers(this.invitations, { sendEmailInvitaion: this.sendEmailInvitation })
        .then((invitedUsers: ICollection<IUser>) => {
          this.$rootScope.$broadcast("usersChanged", true);
          this.$rootScope.$broadcast("employeesChanged", true);
          this.$rootScope.$broadcast("invitedUsers", invitedUsers);
          this.snackbarEvents.broadcastShowSnackbar("create_user_success");
          this.analyticsService.track("Users Invited", { invitees_count: invitedUsers.items.length });

          const isHomeState = this.$state.current.name.includes("home");
          if (isHomeState) {
            this.$state.go("gtmhub.usersList");
          } else {
            this.close();
          }
        })
        .catch((err) => {
          if (err.data === "The user already exists.") {
            this.createErrorObject("the_user_already_exists");
          } else {
            this.createErrorObject("there_was_a_server_issue_on_our_end");
          }

          delete this.indicators.inviting;
        });
    });
  }

  private createErrorObject(message: string): void {
    this.errorNotification = {
      message: localize(message),
    };
  }

  private generateTagsPromiseMap = (uniqueTags: string[]): IPromise<void>[] => {
    return uniqueTags.map((t) =>
      this.checkIfEmailIsValid(t)
        .catch((error) => {
          this.uiErrorHandlingService.handleModal(error);
        })
        .finally(() => {
          this.emailsList.push({ text: t, id: Math.random().toString() });
        })
    );
  };

  private splitMultiPasteEmails(tag: string, pattern: string): IPromise<void> {
    const uniqueTags = tag.split(pattern);
    this.setNewInvitation();

    if (this.accountSubscription.maxUserLimit && this.accountSubscription.usersCount + uniqueTags.length > this.accountSubscription.maxUserLimit) {
      this.defaultInvitation.subscriptionType = RESTRICTED;
    }

    const tagsMap = this.generateTagsPromiseMap(uniqueTags);

    return this.$q.all(tagsMap).then(() => {
      this.removeInvitationForm(tag);
    });
  }

  checkTagText(tag: string): IPromise<void> {
    // split email by comma - by design
    if (tag.includes(", ")) {
      return this.splitMultiPasteEmails(tag, ", ");
    }

    // split email by space - by design
    if (tag.includes(" ")) {
      return this.splitMultiPasteEmails(tag, " ");
    }

    this.setNewInvitation();

    return this.checkIfEmailIsValid(tag);
  }

  checkIfEmailIsValid(currentTag: string): IPromise<void> {
    if (this.currentEmailCheckups[currentTag] && !this.currentEmailCheckups[currentTag].valid) {
      return this.$q.resolve();
    }

    this.currentEmailCheckups[currentTag] = {};

    if (this.invitations[currentTag]) {
      this.currentEmailCheckups[currentTag] = {
        valid: true,
        error: "already in list",
      };
      return this.$q.resolve();
    }

    const regExMatched = this.matchesEmailRegEx(currentTag);

    if (!regExMatched) {
      this.currentEmailCheckups[currentTag] = {
        valid: false,
        error: "invalid email syntax",
      };
      return this.$q.resolve();
    }

    return this.userService
      .validateUserEmail(currentTag)
      .then(() => {
        this.currentEmailCheckups[currentTag] = {
          valid: true,
          error: "",
        };
        this.addInvitationForm(currentTag);
      })
      .catch((error) => {
        if (error.status === 409) {
          this.currentEmailCheckups[currentTag] = {
            valid: false,
            error: error.data,
          };

          return;
        }

        this.uiErrorHandlingService.handleModal(error);
      });
  }

  setLicenseType(licenseType: string, invitation: IInvitationRequest): void {
    invitation.subscriptionType = licenseType;
    this.setSubscriptionLimit();
  }

  removeAllInvalidEmails(): void {
    this.emailsList.forEach(({ text: email }) => !this.currentEmailCheckups[email].valid && this.removeInvitationForm(email));
  }

  normalizeInput(): void {
    /*
      the buffer array insures if we have same email in the input more than once
      it will make it as part of the email list only once
      f.e. input: example@mail.com, example@mail.com -> buffer: 'example@mail.com': { 'text': 'example@mail.com'} ->
        -> emailList: [{'id': 'random id', 'text': 'example@mail.com'}]
    */
    const lastEmailInList = this.emailsList[this.emailsList.length - 1];
    const bufferEmaiArr = toKeyMap("text", this.emailsList);
    this.emailsList = Object.values(bufferEmaiArr);
    lastEmailInList.id = Math.random().toString();

    if (this.currentEmailCheckups[lastEmailInList.text].error === "already in list") {
      this.$timeout(() => {
        this.currentEmailCheckups[lastEmailInList.text].error = "";
      }, 300);
    }

    this.checkForInputErrors();
    this.setSubscriptionLimit();
  }

  contactSales(): void {
    this.analyticsService.contactSales("contactSales");
  }

  private checkForInputErrors(): void {
    this.incorrectEmails = {
      lockMultiAccount: [],
      alreadyExists: [],
      invalidAddresess: [],
      hasIncorrectEmail: false,
    };

    this.emailsList.forEach(({ text: email }) => {
      if (this.currentEmailCheckups[email] && !this.currentEmailCheckups[email].valid) {
        switch (this.currentEmailCheckups[email].error) {
          case "there is already a user with this email in your account":
            this.incorrectEmails.alreadyExists.push(email);
            break;
          case "account does not allow adding multi account users":
            this.incorrectEmails.lockMultiAccount.push(email);
            break;
          case "invalid email syntax":
            this.incorrectEmails.invalidAddresess.push(email);
            break;
        }
      }
    });

    this.incorrectEmails.hasIncorrectEmail = !!(
      this.incorrectEmails.alreadyExists.length ||
      this.incorrectEmails.invalidAddresess.length ||
      this.incorrectEmails.lockMultiAccount.length
    );
  }

  selectTeamsAndAddToInvitation(invitation: IInvitationRequestWithTeamsAndRoles) {
    return (teamIds: string[]) => {
      invitation.teamIds = teamIds;
    };
  }

  selectRolesAndAddToInvitation(invitation: IInvitationRequestWithTeamsAndRoles) {
    return (rolesIds: string[], subscriptionType: string) => {
      invitation.subscriptionType = subscriptionType || invitation.subscriptionType;
      invitation.roleIds = rolesIds;
    };
  }

  private matchesEmailRegEx = (email: string): boolean => {
    return validEmail(email);
  };

  private getAccountLicenses(): IPromise<unknown> {
    return this.accountService.getAccountSubscription().then((accountSubscription: IAccountSubscription) => {
      this.accountLicenses = accountSubscription.items;
      this.setSubscriptionLimit().finally(() => {
        this.setNewInvitation();
      });
    });
  }

  private setNewInvitation(): void {
    const copyAccountLicenses = angular.copy(this.accountLicenses);
    const defaultAccountLicense = copyAccountLicenses.find((license) => license.type === REGULAR || license.type === RESTRICTED) || { type: UNCHARGEABLE };

    if (defaultAccountLicense.type === REGULAR && this.isAccountLicenseLimitReached()) {
      defaultAccountLicense.type = RESTRICTED;
    }

    this.defaultInvitation = {
      firstName: "",
      lastName: "",
      email: "",
      subscriptionType: defaultAccountLicense.type,
      teamIds: [],
      roleIds: [],
    };
  }

  private fillUnifiedInvitationWithCurrentData(): void {
    if (this.isAccountLicenseLimitReached()) {
      Object.values(this.invitations).forEach((invitation) => {
        invitation.subscriptionType = RESTRICTED;
      });
    }

    this.unifiedInvitation = {
      ...angular.copy(this.invitations[this.emailsList[0].text]),
      email: "",
    };
    this.setSubscriptionLimit();
  }

  private fillInvitationsWithUnifiedInvitationData(): void {
    this.invitations = Object.keys(this.invitations).reduce((newInvites, email) => {
      newInvites[email] = {
        ...angular.copy(this.unifiedInvitation),
        email,
      };

      return newInvites;
    }, {});
  }

  private setDataToForm(): void {
    if (this.shouldFillAllForms) {
      this.fillUnifiedInvitationWithCurrentData();
    } else {
      this.fillInvitationsWithUnifiedInvitationData();
    }
  }

  private isAccountLicenseLimitReached(): boolean {
    return (
      this.accountSubscription &&
      this.accountSubscription.maxUserLimit &&
      this.accountSubscription.usersCount + this.invitationsLength() > this.accountSubscription.maxUserLimit
    );
  }

  private setShouldAllowInvitationLinkGeneration(): void {
    const accountType = this.accountResolverService.getAccountData().type;
    this.shouldAllowInvitationLinkGeneration = accountType === AccountType.TrialAccount || accountType === AccountType.FreeAccount;
  }

  private setSubscriptionLimit(): IPromise<void> {
    const regularItems = this.accountLicenses?.filter((item) => item.type === REGULAR) || [];

    const [regular] = regularItems;
    if (regular?.maxUserLimit === -1) {
      return this.$q.resolve();
    }

    this.accountSubscription.usersCount = regularItems.reduce((count, item) => count + item.usersCount, 0);
    this.accountSubscription.maxUserLimit = regular?.maxUserLimit;
    return this.$q.resolve();
  }

  public goToAzure = (): void => {
    this.$window.open("https://portal.azure.com/#view/HubsExtension/BrowseResourceBlade/resourceType/Microsoft.SaaS%2Fresources", "_blank");
  };

  private setCanAssignTeams = (): void => {
    this.featureTogglesFacade
      .isFeatureAvailable$(FeatureFlag.TeamGranularAccess)
      .pipe(take(1), untilScopeDestroyed(this.$scope))
      .subscribe({
        next: (isTeamGranularAccessEnabled) => {
          this.canAssignTeams = isTeamGranularAccessEnabled ? hasPermissions(this.$ngRedux.getState<IPermissionsStoreState>(), "team:manage_members") : true;
        },
      });
  };
}

export const UserInvitationComponent: IComponentOptions = {
  controller: UserInvitationCtrl,
  template: require("./user-invitation.html"),
  bindings: {
    close: "&",
  },
};
