import { getCurrentUserId } from "@gtmhub/users";
import { Assignee, AssigneeSelectorType } from "@webapp/assignees/models/assignee.models";
import { searchAssignees } from "../utils/assignee-collection-utils";
import { IAssigneesStoreState } from "./assignee-reducer";

export function toTeamAssigneeIdMap(state: IAssigneesStoreState): Record<string, Assignee> {
  const teamMap: Record<string, Assignee> = {};
  for (const [id, assignee] of Object.entries(state.assignees.map)) {
    if (assignee.type === "team") {
      teamMap[id] = assignee;
    }
  }
  return teamMap;
}

export function getFilteredAssigneesRedux(options: { assignees: Assignee[]; assigneeName?: string; limit: number; assigneeType: AssigneeSelectorType }): Assignee[] {
  const shouldSkip = (assignee: Assignee): boolean => {
    return assignee.isActive === false || (options.assigneeType !== "both" && assignee.type !== options.assigneeType);
  };

  return searchAssignees(options.assigneeName, options.assignees, { limit: options.limit, shouldSkip });
}

export function filterActiveAssigneeIdsOrCurrentUserId(state: IAssigneesStoreState, assigneeIds: string[] = []): string[] {
  const { map } = state.assignees;
  const activeAssigneeIds = assigneeIds.filter((id) => id in map && map[id].isActive !== false);
  if (!activeAssigneeIds.length) {
    activeAssigneeIds.push(getCurrentUserId());
  }
  return activeAssigneeIds;
}
