import { IPromise } from "angular";
import { IRestLayerRequest } from "@gtmhub/core";
import { GoalService, IResponseProcessing } from "@gtmhub/goals/services/goal.service";

export class UserOwnershipService {
  public static $inject = ["GoalService"];

  constructor(private goalService: GoalService) {}

  public isGoalOwner = (userId: string): IPromise<boolean> => {
    const ownedGoalsParams: IRestLayerRequest = {
      fields: "id",
      limit: 1,
      skip: 0,
      filter: {
        ownerIds: {
          $in: [userId],
        },
      },
    };
    const ownedGoalsResponseProcessing: IResponseProcessing = { applyColoring: false };

    return this.goalService.getGoalsV2(ownedGoalsParams, null, ownedGoalsResponseProcessing).then((response) => response.items.length >= 1);
  };
}
