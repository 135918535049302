import { NgIf, NgStyle } from "@angular/common";
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Host, Input, NgZone, Optional, Output, TemplateRef } from "@angular/core";
import { UiIconModule } from "@quantive/ui-kit/icon";
import { UiLabelModule } from "@quantive/ui-kit/label";
import { NzCollapsePanelComponent } from "ng-zorro-antd/collapse";
import { collapseMotion } from "ng-zorro-antd/core/animation";
import { NzConfigService } from "ng-zorro-antd/core/config";
import { NzNoAnimationDirective } from "ng-zorro-antd/core/no-animation";
import { NzOutletModule } from "ng-zorro-antd/core/outlet";
import { NzDestroyService } from "ng-zorro-antd/core/services";
import { InputBoolean } from "ng-zorro-antd/core/util";
import { UiCollapseComponent } from "@webapp/ui/collapse/components/collapse/collapse.component";
import { UiCollapseLabel } from "../models/collapse.models";

const defaultIcons = {
  default: "chevron-right-big",
  small: "chevron-right-small",
};

@Component({
  selector: "ui-collapse-panel",
  exportAs: "uiCollapsePanel",
  animations: [collapseMotion],
  templateUrl: "./collapse-panel.component.html",
  styleUrls: ["./collapse-panel.component.less"],
  host: {
    class: "ui-collapse-panel",
    "[class.only-content-border]": "onlyContentBorder",
    "[attr.aria-disabled]": "nzDisabled",
    "[class.borderless]": "borderless",
    "[class.collapse-panel-small]": "size === 'small'",
    "[class.collapse-panel-fit-content]": "size === 'fitContent'",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [NzDestroyService],
  standalone: true,
  imports: [NgIf, NgStyle, UiIconModule, NzOutletModule, UiLabelModule],
})
export class UiCollapsePanelComponent extends NzCollapsePanelComponent {
  @Input("collapsePanelActive") @InputBoolean() public nzActive = false;
  @Input("collapsePanelDisabled") @InputBoolean() public nzDisabled = false;
  @Input("collapsePanelExtra") public nzExtra?: string | TemplateRef<void>;
  @Input("collapsePanelHeader") public nzHeader?: string | TemplateRef<void>;
  @Input("collapsePanelExpandedIcon") public nzExpandedIcon?: string | TemplateRef<void>;

  @Input("uiIcon") public icon?: string;
  @Input("uiLabels") public labels?: UiCollapseLabel[];
  @Input("uiIsCustomHeader") @InputBoolean() public isCustomHeader = false;
  @Input("uiIsExpandable") @InputBoolean() public isExpandable = true;
  @Input("uiSize") public size: "default" | "small" | "fitContent" = "default";
  @Input("uiBorderless") public borderless = false;
  @Input("uiOnlyContentBorder") @InputBoolean() public onlyContentBorder = false;
  @Input("a11yLabel") public a11yLabel: string;
  // used for focus on non-interactive body content in order to announce the text
  @Input("a11yTextContentOnly") public a11yTextContentOnly: boolean = false;

  @Output("collapsePanelActiveChange")
  public readonly nzActiveChange = new EventEmitter<boolean>();
  public defaultIcon = defaultIcons[this.size];

  constructor(
    nzConfigService: NzConfigService,
    ngZone: NgZone,
    cdr: ChangeDetectorRef,
    destroy$: NzDestroyService,
    @Host() private uiCollapseComponent: UiCollapseComponent,
    @Optional() noAnimation?: NzNoAnimationDirective
  ) {
    super(nzConfigService, ngZone, cdr, destroy$, uiCollapseComponent, noAnimation);
  }

  public clickHeader(): void {
    if (!this.nzDisabled && this.isExpandable) {
      this.uiCollapseComponent.click(this);
    }
  }

  public getDefaultIcon(size: string): string {
    return defaultIcons[size];
  }
}
