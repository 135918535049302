import { NgZone } from "@angular/core";
import Cookies from "js-cookie";
import { LDClient, LDOptions, LDUser, initialize as initializeLDClient } from "launchdarkly-js-client-sdk";
import { Observable, ReplaySubject, catchError, firstValueFrom, of } from "rxjs";
import { AccountService } from "@gtmhub/accounts/accounts.service";
import { IAccount } from "@gtmhub/core";
import { AccountResolverService } from "@gtmhub/state/account-resolver-service";
import { getCurrentUserId } from "@gtmhub/users";
import { getAccountTypeDisplayValue } from "@webapp/accounts/accounts.utils";
import { RequestConfig } from "@webapp/core/abstracts/models/request-config.model";
import { BaseFacade } from "@webapp/core/abstracts/services/base-facade.service";
import { IEnabledLaunchDarklyConfig } from "@webapp/core/app-config/models/app-config.models";
import { FeatureTogglesDtoModel, FeatureTogglesModel, UserSignatureResponse } from "../models/feature-toggles.models";
import { FeatureTogglesApiService } from "./feature-toggles-api.service";
import { FeatureTogglesState } from "./feature-toggles-state.service";

/**
 * This is the default implementation of the feature toggles facade using LaunchDarkly used in our SaaS version.
 * The on-premise version uses the StaticFeatureTogglesFacade.
 */
export class LdFeatureTogglesFacade extends BaseFacade<FeatureTogglesModel, FeatureTogglesDtoModel, FeatureTogglesState, FeatureTogglesApiService> {
  private ldClient: LDClient;
  private ldClientReady: boolean;
  private ldClientInitializing: boolean;
  private featureSubjects = new Map<string, ReplaySubject<boolean>>();
  private emittedValues = new Map<string, boolean>();

  constructor(
    state: FeatureTogglesState,
    api: FeatureTogglesApiService,
    private accountService: AccountService,
    private accountResolverService: AccountResolverService,
    private ngZone: NgZone,
    private config: IEnabledLaunchDarklyConfig
  ) {
    super(state, api);
  }

  public isInitialized(): boolean {
    return this.ldClientReady;
  }

  public initialize(): void {
    if (this.ldClientInitializing) {
      return;
    }

    this.ldClientInitializing = true;

    const accountData: IAccount = this.accountResolverService.getAccountData();
    const user = {
      key: getCurrentUserId(),
      custom: {
        account_id: accountData.id,
        account_type: getAccountTypeDisplayValue(accountData.type),
        account_created_date: accountData.dateCreated,
        edition: accountData.edition.name,
      },
    };

    const accountSettingsFeatures = this.accountService.getAccountSetting<{ key: string }>("features");
    this.setUserModulesFromAccountSettings(accountSettingsFeatures, user);
    const cookieName = `secure-id-${user.key}-${user.custom.account_id}-${user.custom.account_type}-${user.custom.edition}`;

    this.getUserSignature(cookieName).subscribe(({ secureId }) => {
      if (!Cookies.get(cookieName)) {
        const expireDate = new Date();
        expireDate.setDate(expireDate.getDate() + 7);
        Cookies.set(cookieName, secureId, { expires: expireDate, sameSite: "Strict" });
      }

      const ldSettings: LDOptions = {
        hash: secureId,
        streaming: false,
        sendEvents: true,
      };

      ldSettings.baseUrl = this.config.proxyBaseUrl;
      ldSettings.eventsUrl = this.config.eventBaseUrl;

      this.ldClientReady = false;

      this.ldClient = this.ngZone.runOutsideAngular(() => initializeLDClient(this.config.clientKey, user, ldSettings));
      this.ldClient
        .waitForInitialization()
        .then(() => this.ldClient.waitUntilReady())
        .then(() => {
          this.ldClientReady = true;
          this.ldClientInitializing = false;

          for (const featureKey of this.featureSubjects.keys()) {
            this.emitValueIfNecessary(featureKey);
          }
        });
    });
  }

  private setUserModulesFromAccountSettings(accountSettingsFeatures: { key: string }, user: LDUser): void {
    if (!accountSettingsFeatures) return;

    Object.keys(accountSettingsFeatures).forEach((k) => {
      user.custom[`module-${k}`] = accountSettingsFeatures[k];
    });
  }

  public isFeatureAvailable(featureKey: string): Promise<boolean> {
    return firstValueFrom(this.isFeatureAvailable$(featureKey));
  }

  public isFeatureAvailable$(featureKey: string): Observable<boolean> {
    if (!this.featureSubjects.has(featureKey)) {
      this.featureSubjects.set(featureKey, new ReplaySubject<boolean>(1));
      this.emitValueIfNecessary(featureKey);
    }

    return this.featureSubjects.get(featureKey);
  }

  private emitValueIfNecessary(featureKey: string): void {
    if (!this.ldClientReady) {
      return;
    }

    const enabled = this.ldClient.variation(featureKey, false);
    if (this.emittedValues.has(featureKey) && enabled === this.emittedValues.get(featureKey)) {
      return;
    }

    this.emittedValues.set(featureKey, enabled);

    const subject = this.featureSubjects.get(featureKey);
    subject.next(enabled);
  }

  private getUserSignature(cookieName: string): Observable<UserSignatureResponse> {
    if (Cookies.get(cookieName)) {
      return of({ secureId: Cookies.get(cookieName) } as UserSignatureResponse);
    }

    return this.get$(null, {
      ...new RequestConfig(),
      url: this.apiService.getUserSignatureEndpoint(),
    }).pipe(
      catchError(() => {
        // we still want the LaunchDarkly client to be initialized even in case of error
        return of({ secureId: "" } as UserSignatureResponse);
      })
    );
  }
}
