import { IPromise } from "angular";
import { IModalScope, IModalServiceInstance } from "angular-ui-bootstrap";
import { StateParams, StateService } from "@uirouter/angularjs";
import { IStateInit } from "@gtmhub/core/routing";
import { IIndicator } from "@gtmhub/error-handling";
import { IGtmhubRootScopeService } from "@gtmhub/models";
import { FilterEvents } from "../events";
import { IHubFilter } from "../models/hub";
import { HubService } from "../services/hub";

interface IFilterFormScope extends IModalScope {
  filter: IHubFilter;
  indicators: {
    get?: IIndicator;
    save?: IIndicator;
  };
  selectAssigneesModal: IModalServiceInstance;
  filterIds: string[];
  save(): void;
  selectMultipleOwners(ids: string[]): void;
}

export class FilterFormCtrl implements IStateInit {
  public static $inject = ["$rootScope", "$scope", "$stateParams", "$state", "HubService"];
  private filterEvents: FilterEvents;

  constructor(
    private $rootScope: IGtmhubRootScopeService,
    private $scope: IFilterFormScope,
    private $stateParams: StateParams,
    private $state: StateService,
    private hubService: HubService
  ) {
    this.$scope.indicators = { get: { progress: true } };

    this.$scope.save = this.save;
    this.$scope.selectMultipleOwners = this.selectMultipleOwners;
    this.filterEvents = new FilterEvents($rootScope);
  }

  public stateInit(): IPromise<unknown> {
    return this.init();
  }

  private getFilter(): IPromise<unknown> {
    return this.hubService.getFilter(this.$stateParams.formFilterId).then(
      (filter) => {
        if (this.$stateParams.formFilterId) {
          if (!filter.employeeIds) {
            filter.employeeIds = [];
          }

          this.$scope.filter = filter;

          let ids = [];
          if (filter.teamIds) {
            ids = ids.concat(filter.teamIds);
          }
          if (filter.employeeIds) {
            ids = ids.concat(filter.employeeIds);
          }

          this.$scope.filterIds = ids;
        }

        delete this.$scope.indicators.get;
      },
      (error) => (this.$scope.indicators.get = { error })
    );
  }

  private init = (): IPromise<unknown> => {
    if (!this.$stateParams.formFilterId) {
      this.$scope.filter = {
        name: "",
        employeeIds: [],
        teamIds: [],
        filterType: "hub",
      };

      delete this.$scope.indicators.get;
    } else {
      return this.getFilter();
    }
  };

  private selectMultipleOwners = (ids: string[]): void => {
    this.$scope.filter.employeeIds = ids;
  };

  private save = () => {
    if (this.$stateParams.formFilterId) {
      this.patch();
    } else {
      this.create();
    }
  };

  private create = () => {
    this.$scope.indicators.save = { progress: true };
    this.hubService.createFilter(this.$scope.filter).then((newFilter) => {
      delete this.$scope.indicators.save;
      this.filterEvents.broadcastFiltersChanged({ filter: { id: newFilter.id, name: newFilter.name }, action: "create" });

      if (this.$rootScope.featureAvailable("hub.tasks")) {
        const goToStateName = this.$rootScope.isMsTeamsState ? "gtmhub.msteams.dashboard.tasks" : "gtmhub.tasks";
        this.$state.go(goToStateName, { filterId: newFilter.id });
      } else {
        this.$scope.$close();
      }
    });
  };

  private patch = () => {
    this.$scope.indicators.save = { progress: true };
    this.hubService.patchFilter(this.$scope.filter.id, this.$scope.filter).then(
      () => {
        delete this.$scope.indicators.save;
        this.$rootScope.$broadcast("filterChanged", this.$scope.filter);
        this.filterEvents.broadcastFiltersChanged({ filter: { id: this.$scope.filter.id, name: this.$scope.filter.name }, action: "update" });
        this.$scope.$close();
      },
      (error) => (this.$scope.indicators.save = { error })
    );
  };
}
