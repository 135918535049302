import { Direction } from "@angular/cdk/bidi";
import { StaticProvider, TemplateRef, Type } from "@angular/core";
import { NzSafeAny } from "ng-zorro-antd/core/types";
import { NzDrawerPlacement, NzDrawerSize } from "ng-zorro-antd/drawer";
import { UiDrawerRef } from "./abstracts/drawer-ref";

export type UiDrawerPlacement = NzDrawerPlacement;

export type UiDrawerSize = NzDrawerSize;

export const DRAWER_DEFAULT_SIZE = 550;

export const DRAWER_LARGE_SIZE = 779;

export interface UiDrawerOptionsOfComponent<T = NzSafeAny, D = NzSafeAny> {
  uiClosable?: boolean;
  uiMaskClosable?: boolean;
  uiCloseOnNavigation?: boolean;
  uiDirection?: Direction;
  uiMask?: boolean;
  uiKeyboard?: boolean;
  uiTitle?: string | TemplateRef<unknown>;
  uiExtra?: string | TemplateRef<unknown>;
  uiFooter?: string | TemplateRef<unknown>;
  uiContent?: TemplateRef<{ $implicit: D; drawerRef: UiDrawerRef }> | Type<T>;
  uiContentParams?: Partial<T & D>;
  uiMaskStyle?: object;
  uiBodyStyle?: object;
  uiWrapClassName?: string;
  uiSize?: UiDrawerSize;
  uiWidth?: number | string;
  uiHeight?: number | string;
  uiPlacement?: NzDrawerPlacement;
  uiZIndex?: number;
  uiOffsetX?: number;
  uiOffsetY?: number;

  // Custom
  uiProviders?: StaticProvider[];
}

export interface UiDrawerOptions<T = NzSafeAny, D = NzSafeAny> extends UiDrawerOptionsOfComponent<T, D> {
  uiOnCancel?(): Promise<NzSafeAny>;
}
